<template>
    <div 
        class="base-divider"
        :style="{
            '--divider-color': color ? color : null
        }"
        :class="{
            'sty-dotted': dotted,
            'sty-bold': bold,
            'sty-medium': medium,
        }"
    >
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        color: [String],
        bold: Boolean,
        medium: Boolean,
        dotted: Boolean
    },
    setup(props, {emit}){

        return {
            
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/design";

.base-divider{
    --divider-color: #ADADAD;

    height: 1px;
    background: #EAECED;
    user-select: none;
    &.sty-dotted{
        background: linear-gradient(to right, var(--divider-color) 0 50%, #fff 50.01% );
        background-size: 4px 100%;
        background-repeat: repeat-x;
    }

    &.sty-bold{
        height: 10px;
        background: #D8D8D8;
    }

    &.sty-medium{
        height: 2px;
    }
}

</style>


