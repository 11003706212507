<template>
    <f7-link class="base-nav-back" :disabled="disabled" >
        <img src="@/assets/images/icon/round-back.svg" alt="" srcset="">
    </f7-link>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        disabled: Boolean
    },
    setup(props, context){

        return {
            console
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.base-nav-back{
    &[disabled="true"]{
        opacity: 0.7;
        pointer-events: none;
    }
}

</style>
