// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 , {Dom7} from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

import store from '@/store/store.js';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Stylesnpm
import '@/css/icons.css';
import '@/css/app.scss';

import { upperFirst, camelCase } from "lodash";
import { CONST as mukiConst } from "@/js/const.js";

// Import App Component
import App from "@/App.vue";

// 表單驗證
import "@/js/validations.js";

// i18n
import { i18n } from "@/js/i18n.js";

// base JS
import { MukiStorage } from "@/js/base.js";

import { isDev, setNoBack } from '@functions';

import localForage from "localforage";

import { checkAppVersion } from "@/js/appversion";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// App 設定相關資料
window.mukiStore = localForage.createInstance({
    driver      : localForage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
    name        : 'mukistore',
    version     : 1.0,
    storeName   : 'mukistore', // Should be alphanumeric, with underscores.
    description : 'app 設定儲存空間'
});


checkAppVersion().then(res=>{
    
    // 檢查app版本是否是快取的版本
    var tempReloadFlag = sessionStorage.getItem('tempReloadFlag')

    window.mukiAppVersion = res
    window.mukiAppoutdated = res !== mukiConst.APP_VERSION

    if ( res ) {

        var resVersion = parseInt(res.split('.').join('')) || 0;
        var constVersion = parseInt(mukiConst.APP_VERSION.split('.').join('')) || 0;

        // api回傳的版本號較舊
        if ( resVersion <= constVersion ){
            // 不更新
        } else {
            if ( !tempReloadFlag ) {
                // 強制重抓新版
                sessionStorage.setItem('tempReloadFlag', true)
                window.location.reload(true);
                return;
            }
        }
        
    }

    sessionStorage.removeItem('tempReloadFlag')

    // Init App
    const app = createApp(App);
    
    window.app = app;
    // App 常數
    window.mukiConst = mukiConst
    window.isDev = isDev;
    window.setNoBack = setNoBack;
    window.MukiStorage = MukiStorage
    window.store = store;
    
    // store
    app.use(store)
    
    // 定義全域可取用的components
    function importAll(requireComponent) {
        requireComponent.keys().forEach((fileName) => {
            const componentConfig = requireComponent(fileName);
    
            const componentName = upperFirst(
                camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
            );
    
            app.component(
                componentName,
                componentConfig.default || componentConfig
            );
        });
    }
    
    importAll(require.context("@/components", false, /Base[A-Z]\w+\.(vue|js)$/));
    
    importAll(
        require.context("@/components/form", false, /Base[A-Z]\w+\.(vue|js)$/)
    );
    
    // Register Framework7 Vue components
    registerComponents(app);
    
    // F7 可使用時
    f7ready(() => {
        // Framework7's initialized instance
        window.f7 = f7;
        window.$$ = Dom7;
    })
    
    app.use(i18n);
    
    // Mount the app
    app.mount('#app');
    
    console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    console.log('process.env.APP_ENV', process.env.APP_ENV)
})

