// 引入lodash中的camelCase，稍後會使用到它來過濾所有的檔案名稱，並轉為駝峰命名
import camelCase from 'lodash/camelCase'

// 檢查載進來的modules檔案是否都是any.js
const requireModule = require.context('.', false, /\.js$/)
const modules = {}

// 遍歷requireModule中所有Modules Name
requireModule.keys().forEach(fileName => {

    // 如果是index.js就跳出遍歷
    if (fileName === './index.js') return

    // 使不同命名方式的檔案載進來後，最後定義的變數都能變成駝峰式命名
    // 簡單的說就是把 import { abc_def } from './path' 變成 import { abcDef }
    const moduleName = camelCase(
        fileName.replace(/(\.\/|\.js)/g, '')
    )

    modules[moduleName] = {
        ...requireModule(fileName).default
    }
})

export default modules