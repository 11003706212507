import { createI18n } from 'vue-i18n';
import tw from '@/js/i18n/tw.js';
import en from '@/js/i18n/en.js';

export const i18n = createI18n({
    legacy: false,
    locale: 'tw',
    fallbackLocale: 'en',
    globalInjection: true,
    useScope: 'global',
    messages: {
        en,
        tw
    }
})