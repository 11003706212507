<template>
    <div
        ref="refRoot"
        class="toolbar muki-tabbar"
        :class="{
            'tab-sty-elegant': elegant,
            'tab-sty-elegant-light': elegantLight,
            'tab-sty-flat': flat,
            'tab-sty-flat-light': flatLight,
            'tab-sty-pills': pills,
            'sty-scrollable':scrollable,
            'tabbar-scrollable':scrollable,
            'sty-not-scrollable': !scrollable,
            'sty-tiny-space': tinySpace,
            'sty-overflow-visible': overflowVisible,
        }"
    >
        <div
            class="toolbar-inner"
            :class="{
                'segmented': segmented,
                'segmented-strong': segmented
            }"
        >
            <a
                v-for="tab in tabs"
                :key="tab.id"
                :class="{'tab-link-active': String(activeId) == String(tab.id)}"
                class="button tab-link"
                :href="href ? `#tab-${tab.id}` : null"
                @click="onClick(tab)"
            >
                <slot name="num-badge" :tab="tab" />
                <div class="tab-link-content">
                    {{tab.name}}
                </div>
            </a>
            <!-- <span class="segmented-highlight"></span> -->
            <span class="tab-link-bg-hilighter"></span>
        </div>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive, watch } from "vue";
import { f7ready } from 'framework7-vue';
export default {
    props:{
        tabs: [Array],
        activeId: [Number, String],
        scrollable:{
            type:Boolean,
            default:false,
        },
        segmented:{
            type:Boolean,
            default:false,
        },
        tabContentSelector: String,
        // 使用href。內建切換功能
        href: Boolean,

        // 樣式
        elegant: Boolean,
        elegantLight: Boolean,
        pills: Boolean,
        flat: Boolean,
        flatLight: Boolean,
        tinySpace: Boolean,
        overflowVisible: Boolean
    },
    setup(props, {emit}){

        const refRoot = ref(null)

        function checkActive(){
            var base = location.hash.split('?');
            var result = ''

            if ( base.length > 1){
                var split = base[1].split('=');
                if ( split.length > 1 ) {
                    result = split[1];
                }
            }   

            return result;
        }

        function setActive(id){
            $(refRoot.value).find('[href="#tab-'+id+'"]').click()
        }

        watch(()=> props.activeId, ()=>{
            setActive(props.activeId)
        })

        onMounted(()=>{
            f7ready(()=>{
                setTimeout(()=>{
                    // 使用 手滑 swipe 時，也要更新 tab 的 tab-link-bg-hilighter
                    if ( props.tabContentSelector ) {
                        var tab = $(props.tabContentSelector);
                        if ( tab.length ){
                            tab.on('tab:show', (e)=>{
                                var el = $(e.target);
                                var id = el.attr('id').replace('tab-', '');
                                setActive(id)
        
                                var targetTabLink = $('[href="#tab-'+id+'"]');
                                var targetTabLinkIdx = targetTabLink.index();
        
                                emitSwitch(props.tabs[targetTabLinkIdx])
                            });
                        }
                    }

                    emit('init', {
                        setActive(id){
                            setActive(id)
                        }
                    })
                }, 300)
            })
        })

        function emitSwitch(tab){
            emit('tabSwitch', tab)
            emit('update:activeId', tab.id)
        }

        function onClick(tab){
            emit('tabClick', tab)
            emitSwitch(tab)
        }

        return {
            String,
            refRoot,
            onClick
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.ios, .md{
    .tab-link-bg-hilighter{
        background: var(--f7-segmented-strong-button-active-bg-color);
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: .3s;
        // transform: scalex(1.1);
    }

    .muki-tabbar{
        &.sty-tiny-space{
            padding: 2px;
        }

        &.sty-overflow-visible{
            overflow: visible;
            .toolbar-inner{
                overflow: visible;
            }
            .tab-link{
                overflow: visible;
            }
        }

        // 其他 
        .muki-num-badge{
            right: 0;
        }
        // 其他 /
    }

    .tab-sty-anchor{
        --f7-toolbar-height: 50px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 8px;
        --f7-toolbar-inner-padding-right: 8px;
        background:#EEF5CC;
        margin-bottom: 15px;

        &.sty-scrollable{
            .tab-link{
                &.button{
                    width: auto;
                }
                &:first-child{
                    margin-left: 15px;
                    border: 1px solid $color-primary;
                }
                &:last-child{
                    margin-right: 15px;
                }
            }
        }

        &.sty-not-scrollable{
            .tab-link{
                margin: 0 5px;
                flex-grow: 1;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }

        .toolbar-inner{
            border-radius: 0;
            background: none;
            overflow-x: auto;
            &::-webkit-scrollbar{
                display:none; /*經測試有效:有simplebar bar時不要顯示原生 scrollbar */
                width: 0;  /* Remove scrollbar space */
                background: transparent;  /* Optional: just make scrollbar invisible */
            }
        }

        .tab-link{
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 15px;
            font-size: 1rem;
            overflow: visible;
            flex-shrink: 0;
            padding: 0 15px;
            font-weight: bold;
            &.tab-link-active{
                background: $color-primary;
                color:#fff;
                position:relative;
                &:after{
                    content: '';
                    display: block;
                    width: 14px;
                    height: 13px;
                    border-radius: 3px;
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%) translateY(-7px) rotate(45deg);
                    background: $color-primary;
                    transform-origin: center;
                }
            }


        }
    }

    .tab-sty-pill{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        border-radius: var(--border-radius);
        overflow: hidden;

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-elegant{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        background: #666666;
        border-radius: var(--border-radius);
        overflow: hidden;

        .toolbar-inner{
            padding-right: 0;
            padding-left: 0;
        }

        .tab-link{
            color:#fff;
            font-size: 1rem;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-elegant-light{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;
        border: 1px solid #D8D8D8;
        .tab-link{
            color:#000000;
            font-weight: normal;
            &.tab-link-active{
                color: #fff;
            }
        }

        .toolbar-inner{
            background: #F8F8F8;
            padding-left: 0;
            padding-right: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-flat{
        --f7-toolbar-height: 36px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;

        .toolbar-inner{
            background: #EAECED;
            padding-left:0;
            padding-right: 0;
        }

        .tab-link{
            color: #666666; 
            margin-left: 0;
            &.tab-link-active{
                font-weight: normal;
                color:#fff; 
                &:first-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        left: 0;
                    }
                }
                &:last-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        right: 0;
                    }
                }
            }
        }

        .tollbar-inner{
            padding: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: 0;
            height: var(--f7-toolbar-height);
            top: 0;
            bottom: 0;
        }


    }

    .tab-sty-flat-light{
        --f7-toolbar-height: 36px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;
        border: 1px solid#D8D8D8; 

        .toolbar-inner{
            background: #EAECED;
            padding-left: 0;
            padding-right: 0;
        }

        .tab-link{
            color: #666666; 
            &.tab-link-active{
                font-weight: normal;
                color:#fff; 
                &:first-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        left: 0;
                    }
                }
                &:last-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        right: 0;
                    }
                }
            }
        }

        .tollbar-inner{
            padding: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: 0;
            height: var(--f7-toolbar-height);
            top: 0;
            bottom: 0;
        }
    }

}

</style>
