import { Account } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import { Base64 } from 'js-base64';
import { CONST as mukiConst } from "@/js/const.js";

window.bbs = Base64
export default {
    namespaced: true,

    state: () => ({
        // 登入token
        Expires: '',
        ExpressIn: '',
        RefreshToken: '',
        Token: '',
        UserId: '',
        UserName: '',
        password: '',
        // 顯示通過身障資格資訊提醒燈箱
        showHandicappedPopup: true,
        // 是否需要輪椅，預設為 Null，假如是 false 或是 true，不跳出燈箱
        needWheelChair: '',
        // Userinfo api
        userInfo: {
            "PhoneNumber": "",
            "RealName": "",
            "Birth": "",
            "Password": "",
            "LocalCalls": "",
            "PhoneCalls": "",
            "Address1": "",
            "Address2": "",
            "Email": "",
            "NewsLetter": "",
            "HasHandicapped": "",
            "SmsLetter": "",
            "Recommend": "",
            "PhoneNumberConfirmed": "",
            "IdentityNumberHash": "",
            "HandicappedVertifyStatus": "",
            "HasInvited": "",
            "Gender": ""
        }
    }),
    getters: {
        // 能否預約
        canBooking(state, getters) {
            if (!state.userInfo.HasHandicapped) {
                if (state.needWheelChair !== true) {
                    return false;
                }
            }
            return true;
        },
        // 預約權限不同的狀況下的文字
        bookCabText(state, getters) {
            return getters.canBooking ? '預約' : '候補'
        },
        // 已登入
        isLogined(state, getters) {
            var logined = false;
            var expires = state.Expires;
            if (state.Token && expires) {
                var expiresTime = moment(expires)._d.getTime();
                var now = moment()._d.getTime();
                if (now < expiresTime) {
                    logined = true
                }
            }
            return logined
        },
        // 詳細
        authInfo(state, getters) {
            const result = reactive({})
            for (let key in state) {
                result[key] = computed(() => state[key])
            }
            return result;
        },
    },
    actions: {
        login({ commit, state }, data) {

            var toSend = $.extend(true, {
                // KeyVersion 客戶 : 固定寫死帶值2
                KeyVersion: 2,
                MobileType: m_deviceType() === 1 ? 'A' : 'I',
            }, data);

            return Account.login(toSend).then(res => {
                ajax200Res(
                    res,
                    (res, data, msg) => {
                        // 成功
                        const toUpdate = {
                            Expires: data.Expires,
                            ExpressIn: data.ExpressIn,
                            RefreshToken: data.RefreshToken,
                            Token: data.Token,
                            UserId: data.UserId,
                            UserName: data.UserName,
                            password: toSend.Mima,
                        }

                        commit('updateMemberData', toUpdate);

                        _direct_url(mukiConst.INDEX_URL)
                    },
                    (res, data, msg) => {

                    }
                )
                return res;
            })
        },
        logout({ commit, dispatch, state }) {

            return Account.logout().then(res => {

                dispatch('clear')

                // 不管有沒有成功，重整頁面
                _direct_url(mukiConst.INDEX_URL)

            }).catch(err => {

                dispatch('logoutForce')
            })
        },
        logoutForce({ commit, dispatch, state }) {

            dispatch('clear')

            _direct_url(mukiConst.INDEX_URL);

        },
        logoutSilent({ commit, dispatch, state }) {

            return Account.logout().then(res => {

                dispatch('clear')

                // 不管有沒有成功，重整頁面
                _direct_url(mukiConst.INDEX_URL)
            })
        },
        clear({ commit, state }) {
            var emptyObj = {
            }

            for (let key in state) {
                emptyObj[key] = '';
            }

            commit('updateMemberData', emptyObj)
        },
        init({ commit, state }) {
            var dataObj = {
            }

            for (let key in state) {
                let data = localStorage.getItem(key)
                if (data) {
                    try {
                        if (key === 'Token' || key === 'RefreshToken') {
                            data = data;
                        } else {
                            data = JSON.parse(Base64.decode(data));
                        }
                        dataObj[key] = data
                    } catch (err) {
                        console.log('auth init err', err)
                    }
                }
            }

            commit('updateMemberData', dataObj)
        },
        getLatest({ commit, state }) {
            return Account.UserInfo().then(res => {
                ajax200Res(
                    res,
                    (res, data, msg) => {
                        // 成功
                        commit('updateMemberData', {
                            userInfo: data
                        })
                    },
                    (res, data, msg) => {
                        // 例外失敗
                        if (msg) {
                            f7.dialog.alert(msg, "訊息提示", () => {
                            });
                        }
                    }
                )
            })
        }
    },
    mutations: {
        // 更新會員資料
        updateMemberData(state, data) {
            // 不要持久化的 key，用 object 保證複雜度
            let ignoreKey = { needWheelChair: true };

            for (let key in state) {
                if (data[key] !== undefined) {
                    state[key] = data[key];
                    // 如果不在 key 內，則可以持久化
                    if (ignoreKey[key] === undefined) {
                        localStorage.setItem(key, Base64.encode(JSON.stringify(data[key])))
                    }
                }
            }

            if (data['Token'] !== undefined) {
                localStorage.setItem("Token", data['Token']);
            }

            if (data['RefreshToken'] !== undefined) {
                localStorage.setItem("RefreshToken", data['RefreshToken']);
            }
        },
    },
}

