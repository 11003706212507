<template>
    <f7-badge 
        class="muki-num-badge" 
        :class="{'is-over-99': thisNum > 99}" 
        :style="{
            '--size': size ? size : null
        }"
        v-if="thisNum > 0"
    >
        <span :class="{'fz-trans-10px': parseInt(thisNum) > 10}" >
            {{ thisNum > 99 ? 99 : thisNum }} 
        </span>
    </f7-badge>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        num: {
            type: [Number, String],
            default: 0
        },

        size: [String]
    },
    setup(props, context){

        const thisNum = computed(()=> parseFloat(props.num))

        return {
            thisNum
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.muki-num-badge {
    --size: 18px;

    min-width: var(--size);
    height: var(--size);
    padding: 0;
    border-radius: var(--size);
    font-family: $ff-nunito;
    font-size: 12px;
    font-weight: 100;
    position: absolute;
    top: -10px;
    right: -10px;
    box-sizing: border-box;
    background-color: $color-danger;

    &.is-over-99 {
        // font-size: 12px;
        // width: auto;
        // right: -0.8125rem;
        // padding: 0 1px;
        // border-radius: var(--f7-badge-size);
    }

    &.sty-bold{
        font-weight: bold;
        span{
            font-weight: bold;
        }
    }
}

</style>
