<template>
    <div class="user-media">
        <div class="media-inner">
            <slot>
                <img :src="picSrc" alt="" srcset="">
            </slot>
        </div>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        picSrc: [String]
    },
    setup(props, {emit}){
        
        return {

        }
    }
};
</script>

<style lang="scss">
@import "@design";

.user-media{
    background:#fff;
    border: 2px solid #D8D8D8;
    border-radius: 50%;
    position:relative;
    width: 85px;
    box-sizing: border-box;
    &:before{
        content:'';
        display:block;
        padding-top: 100%;
    }
    .media-inner{
        @include absolute(6px, 6px, 6px, 6px);
        border-radius: inherit;
        overflow: hidden;
    }
    img {
        @include absolute(0,0,0,0);
        margin:auto;
        width: 100%;
    }
}


</style>



