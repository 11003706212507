<template>
    <div
        class="content-scroller"
        :class="{
            'sty-body-scroll': bodyScroll,
            'sty-fill-height': fillHeight,
            'has-tab': hasTab,
        }"
    >
        <div class="scroller-static">
            <slot name="top"></slot>
        </div>
        <div class="scroller-body" :class="bodyClass">
            <slot name="body"></slot>
        </div>
        <div class="scroller-static">
            <slot name="bottom"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        num: {
            type: Number,
            default: 0
        },
        // body 可否scroll
        bodyScroll:{
            type: Boolean,
            default: false,
        },
        // 自動撐高
        fillHeight:{
            type: Boolean,
            default: false,
        },
        hasTab: Boolean,
        bodyClass: [String]
    },
    setup(props, context){
        return {

        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.content-scroller{
    display: flex;
    flex-direction: column;

    &.has-tab{
        .tab{
            overflow-y: auto;
        }
    }

    &.sty-fill-height{
        flex-grow: 1;
        min-height: 100%;
    }

    &.sty-body-scroll{
        .scroller-body{
            overflow-y: auto
        }
    }

    .scroller-body{
        flex-grow: 1;
        min-height: 0;
    }
    .scroller-static{
        flex-shrink: 0;
    }
};

</style>
