<template>
    <f7-badge
        class="muki-num-badge"
        :class="{
            'is-over-99': thisNum > 99,
            'sty-bold': bold ? true : null,
        }"
        v-if="thisNum > 0"
    >
        <span :class="{'fz-trans-10px': parseInt(thisNum) > 10}" >
            {{ thisNum > 99 ? 99 : thisNum }}
            <!-- <span v-if="thisNum > 99" >+</span> -->
        </span>
    </f7-badge>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    props:{
        num: {
            type: [Number, String],
            default: 0
        },
        // 粗體
        bold:Boolean
    },
    setup(props, context){

        const thisNum = computed(()=> parseFloat(props.num))

        return {
            thisNum
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.muki-num-badge {
    min-width: auto;
    min-width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 18px;
    font-family: $ff-nunito;
    font-size: 12px;
    font-weight: 100;
    position: absolute;
    top: -10px;
    right: -10px;
    box-sizing: border-box;
    background-color: $color-danger;
    color: #fff;
    &.is-over-99 {
        // font-size: 12px;
        // width: auto;
        // right: -0.8125rem;
        // padding: 0 1px;
        // border-radius: var(--f7-badge-size);
    }

    &.sty-bold{
        font-weight: bold;
        span{
            font-weight: bold;
        }
    }
}

</style>



