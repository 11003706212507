<template>
  <DialogPopup2 class="handicapped-status-popup" id="handicapped-status-popup">
    <div class="dialog-popup-inner">
      <div class="dialog-popup-title">
        提醒您
      </div>
      <div class="dialog-popup-desc">
        <ul class="handicapped-status-popup__des" style="list-style-position:square;color: #000000;">
          <li style="padding-bottom: 20px">
            乘客的身心障礙證明 :
            <span :class="`handicapped-status-popup__status-code-${cData.handiCappedStatusCode}`">{{
              cData.handiCappedStatusText
            }}</span>
            <br>
            請使用「候補訂車」進行預約。
          </li>
          <li>
            若乘客為搭乘輪椅者<br>
            請勾選「搭乘輪椅上車」後使用「預約訂車」。
          </li>
          <br>
          <div class="handicapped-status-popup__checkbox-area">
            <UCheckbox :checked="cData.needWheelChair">
              <template #default>
                <input type="checkbox" name="" id="" v-model="cData.needWheelChair">
              </template>
              <template #label>
                <span style="font-size: 1.5rem;">
                  <i class="icon-wheelchair" style="color: var(--color-theme);"></i>
                  <span style="color: #000000;font-weight:bold;">
                    搭乘輪椅上車
                  </span>
                </span>
              </template>
            </UCheckbox>
          </div>
        </ul>

      </div>
      <div class="handicapped-status-popup__btn-group">
        <BaseButton pill class="dialog-popup-btn btn-setting" @click="goSetting()" style="visibility:hidden">
          更新設定
        </BaseButton>
        <BaseButton pill class="btn-theme dialog-popup-btn btn-close" @click="confirm()">
          確認
        </BaseButton>
      </div>
    </div>
  </DialogPopup2>
</template>

<script>
import { ref, reactive, computed, watch, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Account } from "@baseService";
import { f7ready } from 'framework7-vue';
import DialogPopup2 from "@projectComponents/DialogPopup2";
import UCheckbox from "@components/UCheckbox";
export default {
  components: {
    DialogPopup2,
    UCheckbox,
  },
  props: {
  },
  setup(props, context) {

    const store = useStore();
    const isLogined = store.getters['auth/isLogined'];

    const cData = reactive({
      needWheelChair: false,
      dontShowHandicappedPopup: false,
      handiCappedStatusText: '',
      handiCappedStatusCode: 0
    })

    function goSetting() {
      store.commit('auth/updateMemberData', {
        showHandicappedPopup: !cData.dontShowHandicappedPopup
      })

      f7.popup.close('#handicapped-status-popup')
      f7.view.main.router.navigate('/member_profile')
    }

    function confirm() {
      // 將是否需要輪椅寫入
      store.commit('auth/updateMemberData', {
        needWheelChair: cData.needWheelChair
      });

      f7.popup.close('#handicapped-status-popup')
    }

    function checkShow() {
      if (isLogined) {
        store.dispatch('auth/getLatest').then(() => {
          // 是否跳視窗
          if (store.state.auth.userInfo.HasHandicapped == false && store.state.auth.needWheelChair === '') {
            Account.HandicappedVerifyStatus().then(res => {
              ajax200Res(
                res,
                (res, data, msg) => {
                  // 成功
                  if (data && data.Status) {
                    cData.handiCappedStatusText = data.Status;
                    cData.handiCappedStatusCode = data.StatusCode;
                  }

                  f7.popup.open('#handicapped-status-popup')
                },
                (res, data, msg) => {
                  // 例外失敗
                  if (msg) {
                    f7.dialog.alert(msg, "訊息提示", () => {
                    });
                  }
                }
              )
            })
          }
        })
      }
    }

    onMounted(() => {
      f7ready(() => {
        // 偵測使用者資料改變 進行後續動作
        watchEffect(() => {
          checkShow();

          // 客戶，每次到首頁都要再次檢查是否顯示
          $$(document).on('page:beforein', function (e, page) {
            switch (page.route.path) {
              case '/':
              case '/home':
                // 首頁
                checkShow();
                break;
              default:
                break;
            }
          })
        })
      })
    })

    return {
      cData,
      store,
      mukiConst,
      goSetting,
      confirm
    };
  },
};
</script>

<style lang="scss">
@import "@design";



// status-popup
.handicapped-status-popup {
  .dialog-popup-inner {
    padding: 10px !important;
  }

  &__status-name {
    color: #000000;
    font-size: 1.3rem;
  }

  &__status-text {
    font-size: calc(24 / 14 * 1rem);
    font-weight: 700;
    margin-bottom: 13px;
  }

  &__status-code-1 {
    // 驗證中
    color: #FD8908;
  }

  &__status-code-2 {
    // 未通過
    color: #FD3408;
  }

  &__des {
    margin-bottom: 20px;
    color: #000000;
    line-height: calc(20 / 14 * 1rem);
  }

  &__checkbox-area {
    margin-bottom: 10px;
    color: #A4A4A4;
    font-size: 1rem;
    font-family: $ff-msjh;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;

    .button {}

    .btn-setting {
      color: #666666;
      text-align: left;
      width: auto;
      padding-left: 0;
      font-weight: 700;
    }

    .btn-close {
      width: 94px;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.16);
    }
  }
}

// status-popup /
</style>
