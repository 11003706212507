<template>
    <!-- <div class="simple-notify-use-popup-backdrop"></div> -->

    <ModalStyPopup 
        ref="refRoot" 
        class="sty-notify-use simple-notify-use-popup" 
        data-animate="false" 
        width="320px" 
        data-backdrop-el=".simple-notify-use-popup-backdrop"
        :navbar="false"
    >
        <template #head-left-col >
            {{cData.Title}}
        </template>
        <template #default >
            <div class="dialog-popup-inner">
                <BaseSpacer h="10px" />
                <div class="dialog-popup-des">
                    <div>
                    {{cData.Message}}
                    </div>
                </div>
                <BaseSpacer h="10px" />
            </div>
        </template>
    </ModalStyPopup>

</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
import { setNoBack } from "@functions";
import ModalStyPopup from "@projectComponents/ModalStyPopup";
export default {
    components:{
        ModalStyPopup
    },
    props: {
    },
    setup(props, {emit}){

        const refRoot = ref(null);
        const opened = ref(false);

        const cDataDefault = {
            closePopupOnBtnClick: false,

            Title: '',
            Message: '',
            onClose: ()=>{}
        };
        const cData = reactive({
            closePopupOnBtnClick: false,

            Title: '',
            Message: '',
            onClose: ()=>{}
        })

        function open(params){
            
            return new Promise((resolve, reject) => {

                $(refRoot.value.$el).one('popup:opened', ()=> {
                    resolve()
                })

                // 當前有打開
                if ( opened.value ) {
                    close().then(()=> {

                        if ( params ) {
                            for(var key in cData ) {
                                if ( params[key] !== undefined ) {
                                    cData[key] = params[key]
                                }
                            }
                        }

                        f7.popup.open(refRoot.value.$el)

                    })
                } else {
                    // 當前沒有打開


                    if ( params ) {
                        for(var key in cData ) {
                            if ( params[key] !== undefined ) {
                                console.log('key', key, params)
                                cData[key] = params[key]
                            }
                        }
                    }
                    
                    f7.popup.open(refRoot.value.$el)
    
                }

            })
        }

        function close(){
            return new Promise((resolve, reject) => {

                $(refRoot.value.$el).one('popup:closed', ()=> {
                    resolve()
                })
                
                f7.popup.close(refRoot.value.$el)

            })
        }

        function confirm(){
            if ( 'function' === typeof cData.onConfirm ) {
                cData.onConfirm()
            }
        }

        function cancel(){
            if ( 'function' === typeof cData.onCancel ) {
                cData.onCancel()
            }
        }

        onMounted(()=>{

            $(refRoot.value.$el).on('popup:open', ()=> {
                opened.value = true;

            })

            $(refRoot.value.$el).on('popup:close', ()=> {
                opened.value = false;

                if ( 'function' === typeof cData.onClose ) {
                    cData.onClose()
                }

                // 還原預設值 (最後執行)
                for(var key in cDataDefault){
                    cData[key] = cDataDefault[key]
                }
            })

            emit('init', {
                open: open,
                close: close,
                get opened(){
                    return opened.value
                }
            })
        })

        return {
            cData,
            refRoot,
            confirm,
            cancel
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.popup.sty-modal.sty-notify-use.simple-notify-use-popup{
    background: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(15px);
    &.modal-in{
        .page-content{
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1);
        }
    }

    .popup-dialog-head{
        align-items: flex-start;
        .left{
            flex-grow: 1;
            font-size: calc(20 / 14 * 1rem);
            font-weight: bold;
            @include letter-spacing-center(calc(5 / 20 * 1em));
            color: var(--color-theme);
            padding-top: 0.1em;
        }
        .title{
            display: none;
        }
        .right{
            a {
                display: block;
                line-height: 1;
            }
            padding-top: 0.1em;
        }
    }

    .dialog-popup-inner{
        padding-top: 7px;
    }

    .page-content{
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(1.185);
    }

    .popup-time-display{
        color: #adadad;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        i {
            margin-right: 0.3em;
            margin-top: 0.1em;
        }
    }

    .dialog-popup-des{
        font-size: 18px;
    }

    .dialog-popup-btn{
        display: inline-flex;
        width: auto;
        min-width: 190px;
        max-width: 100%;
        .btn-inner{
            width: 100%;
            margin: 0 7px;
        }
    }

    .dialog-popup-btn-2side{
        .btn-inner{
            justify-content: space-between;
        }
    }
}

.simple-notify-use-popup-backdrop{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 13000;
    visibility: hidden;
    opacity: 0;
    transition-duration: 400ms;
    z-index: 10500;

    &.backdrop-in{
        backdrop-filter: blur(15px);
        background: rgba(0, 0, 0, 0.61);
        visibility: visible;
        opacity: 1;
    }
}

</style>



