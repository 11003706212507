const MukiRegex = {
    json: function (data) {
        console.log('data', data)
        if ( !data ) return;
        return /^[\],:{}\s]*$/.test(String(data).replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))
    },
    // 全形字
    fullWidthText: function(data){
        return new RegExp(/[\uff00-\uffff]/g).test(data) || /*全形空白*/new RegExp(/[\u3000]/g).test(data)
    },
    // 半形字
    halfWidthText: function (data) {
        return new RegExp(/[\u0000-\u00ff]/g).test(data)
    },
    // 中文字
    chinese: function(data){
        return /[\u4E00-\u9FA5]/.test(data);
    },
    // ibon 禁用字元
    ibonForbiddenedChars: function(data){
        return new RegExp(/[\^|'|`|!|@|#|%|&|*|+|\|"|<|>|\|_|\[|\]]/g).test(data)
    }
}

const MukiUtil = {
    toNumber: function(data){
        if ( 'number' === typeof data ) {
            return data
        } 
        if ( 'string' === typeof data ) {
            var n = parseInt(data.trim().replaceAll(',', ''));
            return n;
        }
    },
    preventClickOnDrag: function(target /* Class or Element*/) {
        function onMouseDown(event){
            $(event.currentTarget).data({top: e.pageX, left: e.pageY});
        }
        function onMouseUp(event){
            var top   = e.pageX,
            left  = e.pageY,
            ptop  = $(event.currentTarget).data('top'),
            pleft = $(event.currentTarget).data('left');

            $(event.currentTarget).data('dragged', Math.abs(top - ptop) > 15 || Math.abs(left - pleft) > 15);
        }
        function onClick(event){
            if ( $(event.currentTarget).data('dragged') ) {
                return false;
            }
        }

        if ( 'string' === typeof target ) {
            $('body').on('mousedown', target, onMouseDown)
            $('body').on('mouseup', target, onMouseUp)
            $('body').on('click', target, onClick)
        } else {
            $(target).on('mousedown', onMouseDown)
            $(target).on('mouseup', onMouseUp)
            $(target).on('click', onClick)
        }
    }
}


// storage
const MukiStorage = {
    // localStorage
    ls: {
        set: function (name, data) {
            var isJsonStr = MukiRegex.json(data);
            var afterData = isJsonStr ? data : JSON.stringify(data);

            localStorage.setItem(name, afterData)
        },
        get: function (name) {
            var item = localStorage.getItem(name) || null;
            var result = null;

            try {
                result = JSON.parse(item);
            } catch (err) {
                console.og('parse json 錯誤')
            }

            return result;
        },
        take: function (name) {
            var item = localStorage.getItem(name) || null;
            var result = null;

            // Take , so we remove it after we take
            if (item) localStorage.removeItem(name)

            try {
                result = JSON.parse(item);
            } catch (err) {
                console.og('parse json 錯誤')
            }

            return result;
        }
    },
    // sessionStorage
    ss: {
        set: function (name, data) {
            var isJsonStr = MukiRegex.json(data);
            var afterData = isJsonStr ? data : JSON.stringify(data);

            sessionStorage.setItem(name, afterData)
        },
        take: function (name) {
            var item = sessionStorage.getItem(name) || null;
            var result = null;

            // Take , so we remove it after we take
            if (item) sessionStorage.removeItem(name)

            try {
                result = JSON.parse(item);
            } catch (err) {
                console.og('parse json 錯誤')
            }

            return result;
        }
    },
}

export { MukiStorage, MukiUtil }