import { Notify } from "@baseService";
import { computed, reactive } from "vue";

export default {
    namespaced: true,
    state: () => ({
        unread: {

        }
    }),
    getters: {
        getData(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }

            return result
        },
        totalUnread(state, getters){
            return computed(()=> {
                return state.DriverDispatchUnreadCount + state.DriverSystemUnreadCount
            });
        }
    },
    actions: {
        getLatestUnread({commit}) {
            Notify.Unread().then(res=>{
                if ( res ) {
                    const { data, Message, Success} = res;
                    const { Data } = data;

                    commit('setData', {
                        unread: {
                            Activity: Data.Activity,
                            Dispatch: Data.Dispatch,
                            System: Data.System,
                            Total: Data.Total,
                        }
                    })
                }
            })
        },
        init({commit, dispatch}, data) {
            dispatch('getLatestUnread');
        },
    },
    mutations: {
        setData(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
    },
}

