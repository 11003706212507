<template>
    <div
        ref="refEl"
        class="base-input form-group"
        :class="[
            isInputFocused ? 'is-input-focused' : null,
            computeStyStr ? computeStyStr : null,
            isHasValue ? 'is-has-value' : null,
            skewLabel ? 'sty-skew-label' : null,
            textCenter ? 'sty-text-center' : null,
            inputPreventActive ? 'sty-input-prevent-active' : null
        ]"
        :style="{
            '--base-input-height': h ? h : null,
            '--base-input-font-size': fz ? fz : null,
        }"
    >
        <slot name="root-start"></slot>

        <label for="" v-if="labelName" >
            <div class="skew-label" v-if="skewLabel">
                <!-- <div class="skew-label-caret"></div> -->
                <!-- <img src="@/assets/images/icon/rect-label.svg" alt="" srcset=""> -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="105639 229239 110000 30380">
                    <path d="m 105693.64 259618.88 l -54.36 -29676.56 l 92075.48 -703.36 l 17881.96 30379.92 l -109903.08 0 z" stroke="#333" stroke-width="1000" fill="#d8d8d8"/>
                </svg>
            </div>
            <span class="label-txt">
            {{ labelName }}
            </span>
            <span v-if="star == 'star-before' " class="star-before">*</span>
            <span v-if="star == 'star-after' " class="star-after">*</span>
        </label>

        <template v-if="!isCustom">
        <div class="input-group">
            <div class="input-group-prepend">
                <slot name="input-group-prepend" />
                <f7-icon
                    v-if="f7"
                    :f7="f7"
                    :material="material"
                    :ios="ios"
                    :md="md"
                    :aurora="aurora"
                ></f7-icon>
                <i v-if="icon" class="icon" :class="icon"></i>
            </div>
            <div class="input-wrap">
                <template v-if="inputTag !== 'div' ">
                    <component
                        class="input-el"
                        ref="refInput"
                        :is="inputTag"
                        :value="(modelValue !== undefined && modelValue !== null && modelValue !== '') ? modelValue : (refInput ? refInput.value : '')"
                        @input=";$emit('update:modelValue', $event.target.value)"
                        @blur="$emit('blur')"
                        @focus="$emit('focus')"
                        :name="name"
                        :type="thisType"
                        :inputmode="inputmode"
                        :id="inputId"
                        :class="inputClass"
                        :readonly="readonly ? true : null"
                        :disabled="disabled ? true : null"
                        :rows="inputTag == 'textarea' ? rows : null"
                        autocomplete="off"
                    />
                </template>
                <template v-if="inputTag == 'div' " >
                    <div>
                        {{(modelValue !== undefined && modelValue !== null && modelValue !== '') ? modelValue : (refInput ? refInput.value : '')}}
                    </div>
                </template>
                <div class="custom-placeholder" v-if="placeholder && !modelValue" >
                    <div class="custom-placeholder-content" v-html="placeholder"></div>
                </div>
            </div>
            <div class="input-group-append">
                <slot name="input-group-append" />
                <!-- select 箭頭 -->
                <i v-if="isSelect" class="select-icon icon-sort-down"></i>

                <!-- password icon-->
                <div v-if="isPassword && !noPasswordToggler" class="password-tools" @click="togglePwd">
                    <i v-if="isPassword && thisType == 'password' " class="fa fa-eye" aria-hidden="true"></i>
                    <i v-if="isPassword && thisType !== 'password' " class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
                <!-- password ion /-->

                <!-- 必填badge -->
                <span class="badge required-badge" v-if="requiredBadge && errors.indexOf('必填') > -1">
                    必填
                </span>
                <!-- 必填badge / -->

                <!-- 正確icon -->
                <i class="valid-icon icon-yes" v-if="successIndicator && meta.valid && (modelValue !== '') " ></i>
                <!-- 正確icon /-->
            </div>
        </div>
        </template>

        <span v-if="(errMsg || thisErrMsg) && !noSubErrMsg" class="error-msg" :name="name" >{{errMsg ? errMsg : thisErrMsg}}</span>
        <slot name="root-end"></slot>
    </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted, nextTick } from 'vue';
import { useField } from "vee-validate";
export default {
    components: {
    },
    emits: ['update:modelValue', 'blur'],
    props: {
        modelValue: {
            default: "",
        },
        labelName: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        sty: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        inputmode:{
            type: String,
            default: 'text'
        },
        star:{
            // 必填星星
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        inputId: {
            type: String,
            default: "",
        },
        inputTag: {
            default: 'input',
        },
        inputClass: String,
        readonly: Boolean,
        disabled: Boolean,
        requiredBadge: Boolean,
        inputPreventActive: Boolean,

        // icon
        icon: {
            type: String,
            default: "",
        },
        f7: {
            type: String,
            default: "",
        },
        // icon /

        // 高度
        h:{
            type:[String],
            default: '',
        },
        // 高度 /

        // 字體大小
        fz: {
            type:[String],
            default: '',
        },
        // 字體大小 /

        // textarea
        rows:{
            type: Number,
            default: 5,
        },
        // textarea /

        // password
        noPasswordToggler: Boolean,
        // password /

        // skewLabel
        skewLabel: Boolean,
        // skewLabel /

        // text center
        textCenter: Boolean,
        // text center /

        // success indicator
        successIndicator: Boolean,
        // success indocator /

        material: {
            type: String,
            default: "",
        },
        ios: {
            type: String,
            default: "",
        },
        md: {
            type: String,
            default: "",
        },
        aurora: {
            type: String,
            default: "",
        },

        // tippyMsg
        tippyMsg: String,
        // tippyMsg /

        // validate
        errMsg: {
            type: String,
            default: "",
        },
        // 不顯示下方 errMsg
        noSubErrMsg: Boolean
    },
    setup(props, {emit}){

        const { sty, name, type } = props;

        const refEl = ref(null);
        const refInput = ref(null);

        let thisType = ref(type);
        const isHasValue = ref(false);
        const isInputFocused = ref(false);

        const  computeStyArr = computed(() => {
            var newArr = [];
            sty.split(' ').forEach((sty, idx) =>{
                if ( sty ) {
                    newArr.push(sty)
                }
            })

            return newArr;
        })

        const  computeStyStr = computed(() => {
            var arr = computeStyArr.value.map((sty, idx) =>{
                return 'sty-' + sty
            })
            return arr.join(' ')
        })

        const  isCustomControls = computed(() => {
            return computeStyArr.value.indexOf('custom-controls') > -1;
        })

        const  isSelect = computed(() => {
            return computeStyArr.value.indexOf('select') > -1;
        })

        const  isCustom = computed(() => {
            return computeStyArr.value.indexOf('custom') > -1;
        })

        const  isPassword = computed(() => {
            return computeStyArr.value.indexOf('password') > -1;
        })

        const  isOutline = computed(() => {
            return computeStyArr.value.indexOf('outline') > -1;
        })

        const  isAddress = computed(() => {
            return computeStyArr.value.indexOf('address') > -1;
        })

        // password
        const togglePwd = ()=>{
            if ( thisType.value == 'password' ) {
                thisType.value = 'text'
            } else {
                thisType.value = 'password'
            }

            refInput.value.focus()
        }
        // password /

        // 提示訊息
        let tippyInstace = {};
        

        const showTippy = ()=>{
            if ( tippyInstace ) {
                tippyInstace.show()
            }
        }

        const hideTippy = ()=>{
            if ( tippyInstace ) {
                tippyInstace.hide()
            }
        }

        onMounted(()=>{
            watch(()=> props.modelValue, ()=>{

                if ( props.modelValue !== '' && props.modelValue !== null && props.modelValue !== undefined)  {
                    isHasValue.value = true;

                } else {
                    isHasValue.value = false;

                    refInput.value = ''
                    
                    // triggere f7 input-with-value class update
                    nextTick(()=>{
                        $(refInput.value).removeClass('input-with-value')
                    })
                    // triggere f7 input-with-value class update /
                }
            })

            $(refInput.value).on('focus', ()=> isInputFocused.value = true);
            $(refInput.value).on('blur', ()=> isInputFocused.value = false);
            

            // 注意 : baseInput 若加上 disable 則Tippy 會無法顯示
            tippyInstace = tippy( refEl.value , {
                // default
                placement: 'bottom-end',
                trigger: 'manual',
                appendTo: refEl.value,
                content: props.tippyMsg,
            });

            emit('baseInput', {
                showTippy: showTippy,
                hideTippy: hideTippy,
            })
        })
        // 提示訊息 /

        // validate
        const {
            errorMessage: thisErrMsg,
            name: thisName,
            value,
            meta,
            errors,
            resetField,
            validate,
            handleChange,
            handleBlur,
            setValidationState,
            checked,
        } = useField(name);

        onMounted(()=>{
            
            $(refEl.value).data('v-i', async ()=>{
                let v = await validate();
            })
        })

        return {
            console,
            refEl,
            refInput,
            thisType,
            computeStyArr,
            computeStyStr,
            isHasValue,
            isInputFocused,

            isCustomControls,
            isSelect,
            isCustom,
            isPassword,
            isOutline,
            isAddress,

            meta,
            errors,
            checked,
            thisErrMsg,

            // password
            togglePwd,
        }
    },
};
</script>

<style lang="scss" >
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

@mixin base-input__custom-place-holder{

    .input-with-value ~ .custom-placeholder{
        opacity: 0;
    }

    .input-wrap{
        position: relative;
    }
    .custom-placeholder{
        font-size: var(--base-input-font-size);
        color: var(--base-input-placeholder-color);
        @include absolute(0,0,0,0);
        pointer-events: none;
        display: flex;
        align-items: center;
    }
}

@mixin base-input__sty-round-bar{
    --base-input-border-width: 2px;
    --icon-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) - 7px);
    .input-group{
        background: #fff;
        border: 2px solid #D8D8D8;
        border-radius: 28px;
        padding: 5px 32px;

        textarea, input, select{
            height: calc(var(--base-input-height) - (var(--base-input-border-width) * 2) - 10px);
        }
    }
}

@mixin base-input__sty-underline{
    --base-input-placeholder-color: #ADADAD;
    --base-input-height: 38px;

    &.is-input-focused{
        .input-group{
            @include project-border-bottom-type('solid', $color-primary);
        }
    }
    
    .input-group{
        position:relative;
        @include project-border-bottom(#ADADAD);
        // border-bottom: 2px dotted #ADADAD; 
        .input-group-prepend{
            align-self: center;
        }
    }

    .custom-placeholder-content{
        letter-spacing: 0.1em;
    }

    .required-badge{
        background: rgba($color-primary, 1);
        font-size: calc(12 / 14 * 1rem);
        letter-spacing: 0.1em;
        color: #fff;
        padding: 3px 5px;
        min-width: 45px;
        font-weight: normal;
        line-height: 1;
        margin-top: var(--badge-center-mt);
    }

    .label-txt{
        color: #333;
        font-size: calc(16 / 14 * 1rem);
        letter-spacing: 0.1em;
    }
}

.base-input {
    --base-input-height: 40px;
    --base-input-border-width: 0px;
    --base-input-icon-size: 18px;
    --base-input-badge-size: 20px;
    --base-input-font-size: var(--f7-font-size);
    --base-input-color: #000000;
    --base-input-placeholder-color: --base-input-color;

    --half-base-input-height: calc(var(--base-input-height) / 2);
    --half-base-input-icon-size: calc(var(--base-input-icon-size) / 2);

    // icon 置中用
    --half-icon-size-and-font-size-diff: calc( ( var(--base-input-icon-size) - var(--base-input-font-size) ) / 2 * 1.5);
    // icon 要置中需要的 margin值
    --icon-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) );
    // icon 置中用 /

    // badge 置中用
    --half-badge-size-and-font-size-diff: calc( ( var(--base-input-badge-size) - var(--base-input-font-size) ) / 2 * 1.5);
    // badge 要置中需要的 margin值
    --badge-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) );
    // badge 置中用 /

    margin-bottom: 20px;
    font-size: var(--f7-font-size);

    input, select, textarea{
        box-sizing: border-box;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        border: 0;
        font-size: var(--base-input-font-size);
        color: var(--base-input-color);
    }
    input, select{
        height: calc(var(--base-input-height) - (var(--base-input-border-width) * 2) );
    }
    input, textarea{
        &::placeholder{
            color: var(--base-input-placeholder-color);
        }
    }
    textarea{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    
    .input-group{
        display: flex;
    }

    .input-wrap{
        flex-grow: 1;
    }

    .input-group-prepend, .input-group-append{
        i {
            font-size: var(--base-input-icon-size);
            margin-top: var(--icon-center-mt);
        }
    }
    
    .input-group-append{
        margin-left: 10px;
    }

    @include base-input__custom-place-holder;

    &.sty-input-prevent-active {
        .input-wrap{
            position: relative;
            &:after{
                content:'';
                display: block;
                @include absolute(0,0,0,0);
            }
        }
    }
    
    &.sty-round-bar{
        @include base-input__sty-round-bar;        
    }

    &.sty-underline{
        @include base-input__sty-underline;        
    }

    &.sty-text-center{
        input, textarea, select, .custom-placeholder{
            text-align: center;
            justify-content: center;
        }
    }

    &.sty-skew-label{
        label{
            position:relative;
            padding: 0px 4px;
            padding-right: 20px;
            color: #fff;
            font-size: calc(12 / 14 * 1rem);
            letter-spacing: calc(3 / 12 * 1em);
            min-height: calc( 23 / 14 * 1rem);
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
        }
        .skew-label{
            // font-family: $ff-msjh;
            @include absolute(0,0,-2px,0);
            svg{
                max-width: 100%;
                max-height: 100%;
            }
            // background: rgba(#333333, 0.2);
            // .skew-label-caret{
            //     width: calc( 23 / 14 * 1rem);
            //     height: calc( 23 / 14 * 1rem);
            //     &:before{
            //         content:'';
            //         display: block;
            //         padding-top: 100%;
            //     }
            //     position: absolute;
            //     left: 100%;
            //     top: 0;
            //     bottom: 0;
            //     background-repeat: no-repeat;
            //     background: linear-gradient(45deg, rgba(#333333, 0.2) 0 50%, #fff 50.1%);
            // }
        }
        .label-txt{
            position: relative;
            color: #333;
            line-height: 1;
            font-size: calc(16 / 14 * 1rem);   
            padding: 0 3px;
        }
    }

    // 星星
    .star-before, .star-after{
        position:absolute;
        color: #FF7589;
    }
    .star-before{
        right: 100%;
    }
    .star-after{
        left: 100%;
    }
    // 星星 /


    // 其他
    .tippy-box{
        background: none;
        .tippy-arrow{
            left: 50%!important;
            transform: translateX(-50%)!important;
            color: rgba($color-primary, 0.8)!important;
        }
        [data-placement^=bottom]>.tippy-arrow:before{
            border-width: 0 5px 7px;
        }
        .tippy-content{
            background:rgba($color-primary, 0.8)!important;
            border-radius: 4px;
            letter-spacing: 0.1em;
            padding: 5px 18px;
        }
    }

    .valid-icon{
        --base-input-icon-size: 19px;

        display: inline-block;
        font-size: var(--base-input-icon-size);
        color: #4de3b1;
        margin-top: var(--icon-center-mt)
    }
    // 其他 /
    
}

.error-msg {
    padding-top: 5px;
    display: inline-block;
    color: $color-danger;
    font-size: 1em;
}
</style>
