<template>
    <div
        class="base-spacer"
        :style="`height:${h};`"
    >
    </div>
</template>

<script>
export default {
    props:{
        h:{
            type: [Number, String],
            default: 0,
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";


</style>
