<template>
    <div class="star-rating rating-stars-1">
        <div class="back-stars">
            <i class="fa icon-star no-pointer" aria-hidden="true">
                <div class="half-left" data-percent="20%"></div>
                <div class="half-right" data-percent="20%"></div>
            </i>
            <i class="fa icon-star no-pointer" aria-hidden="true">
                <div class="half-left" data-percent="40%"></div>
                <div class="half-right" data-percent="40%"></div>
            </i>
            <i class="fa icon-star no-pointer" aria-hidden="true">
                <div class="half-left" data-percent="60%"></div>
                <div class="half-right" data-percent="60%"></div>
            </i>
            <i class="fa icon-star no-pointer" aria-hidden="true">
                <div class="half-left" data-percent="80%"></div>
                <div class="half-right" data-percent="80%"></div>
            </i>
            <i class="fa icon-star no-pointer" aria-hidden="true">
                <div class="half-left" data-percent="10%"></div>
                <div class="half-right" data-percent="100%"></div>
            </i>

            <div class="front-stars front-stars-1" :style="`width:${thisScorePrct}%`">
                <i class="fa icon-star" aria-hidden="true">
                    <div class="half-left" data-percent="20%"></div>
                    <div class="half-right" data-percent="20%"></div>
                </i>
                <i class="fa icon-star" aria-hidden="true">
                    <div class="half-left" data-percent="40%"></div>
                    <div class="half-right" data-percent="40%"></div>
                </i>
                <i class="fa icon-star" aria-hidden="true">
                    <div class="half-left" data-percent="60%"></div>
                    <div class="half-right" data-percent="60%"></div>
                </i>
                <i class="fa icon-star" aria-hidden="true">
                    <div class="half-left" data-percent="80%"></div>
                    <div class="half-right" data-percent="80%"></div>
                </i>
                <i class="fa icon-star" aria-hidden="true">
                    <div class="half-left" data-percent="100%"></div>
                    <div class="half-right" data-percent="100%"></div>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        score:[Number, String]
    },
    setup(props, {emit}){

        const thisScore = computed(()=> parseFloat(props.score));
        const thisScorePrct = computed(()=> thisScore.value / 5 * 100)

        return {
            thisScorePrct
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.star-rating{
    --star-active-color: #FFC107;
    --star-inactive-color: #eceff1;

    display: inline-flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    position:relative;

    &.star-rating-readonly, .is-readonly{
        cursor: default;
        .back-stars{
            cursor: default;
        }
    }

    &.no-rating-count{
        .rating-count{
            display: none;
        }
    }

    .back-stars {
        display: flex;
        color:var(--star-inactive-color);
        position: relative;
        cursor:pointer;
        margin-left: -2.5px;
    }
    .back-stars,.fonrt-stars{
        i{
            margin:0 2.5px;
        }

    }
    .front-stars {
        display: flex;
        color: var(--star-active-color);
        overflow: hidden;
        position: absolute;
        top: 0;
    }
    .fa{
        position:relative;
        .half-left,.half-right{
            position:absolute;
            display:block;
            top:0;
            bottom:0;
            width:50%;
        }
        .half-left{
            left:0;
        }
        .half-right{
            right:0;
        }
    }

    .rating-count {
        color:#929295;
        font-size: 12px;
        margin-bottom: -2px;
        margin-left: 3px;
    }

    .rating-txt{
        color:$color-font;
        font-size: 12px;
        margin-bottom: -2px;
        margin-left: 3px;
    }

    .ani-star{
        top: 0;
        opacity: 0;
        transform: rotateY(0deg);
        &.is-ani{
            animation: star-rating-ani .6s 1;
            animation-fill-mode: forwards;
            @include keyframes(star-rating-ani) {
                0% {
                    top: 0;
                    opacity: 0;
                    transform: rotateY(0deg);
                }
                33.33%{
                    top: -20px;
                    opacity: 1;
                    transform: rotateY(0deg);
                }
                33.34% {
                    top: -20px;
                    transform: rotateY(0deg);
                    opacity: 0.7;
                }
                66.66% {
                    top: -20px;
                    transform: rotateY(180deg);
                    opacity: 0.7;
                }
                66.67% {
                    top: -20px;
                    opacity: 0.7;
                    transform: rotateY(180deg);
                }
                100%{
                    top: -20px;
                    opacity: 0;
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

</style>



