<template>
    <!-- <div class="notify-use-popup-backdrop"></div> -->

    <ModalStyPopup 
        ref="refRoot" 
        class="sty-notify-use notify-use-popup" 
        data-animate="false" 
        width="320px" 
        data-backdrop-el=".notify-use-popup-backdrop"
        :navbar="false"
    >
        <template #head-left-col >
            {{cData.Title}}
        </template>
        <template #default >
            <div class="dialog-popup-inner">
                <div class="popup-time-display">
                    <i class="icon-time"></i>
                    <span class="display-time">
                        {{ moment(cData.CreateTime)._isValid ? moment(cData.CreateTime).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm') }}
                    </span>
                </div>
                <BaseSpacer h="24px" />
                <div class="dialog-popup-des">
                    <div>
                    {{cData.Message}}
                    </div>
                </div>
                <BaseSpacer h="27px" />
                <div class="text-align-center">
                    <BaseButton
                        v-if="cData.NotifyActionType === 1 || cData.NotifyActionType === '1'"
                        pill
                        class="btn-theme dialog-popup-btn dialog-popup-btn-2side"
                        @click="toPage()"
                    >
                        前往功能
                        <i class="icon-arrow-lg-right"></i>
                    </BaseButton>
                    <BaseButton
                        pill
                        v-if="cData.NotifyActionType === 3 || cData.NotifyActionType === '3'"
                        class="btn-theme dialog-popup-btn dialog-popup-btn-2side"
                        @click="toBrowser()"
                    >
                        開啟網站
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                    </BaseButton>
                    <BaseButton
                        pill
                        v-if="cData.showConfirmBtn"
                        class="btn-theme dialog-popup-btn"
                        @click="confirm()"
                    >
                        確定
                    </BaseButton>
                    <BaseButton
                        pill
                        v-if="cData.showCancelBtn"
                        class="btn-theme dialog-popup-btn"
                        @click="cancel()"
                    >
                        取消
                    </BaseButton>
                </div>
            </div>
        </template>
    </ModalStyPopup>

</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
import { setNoBack } from "@functions";
import ModalStyPopup from "@projectComponents/ModalStyPopup";
export default {
    components:{
        ModalStyPopup
    },
    props: {
    },
    setup(props, {emit}){

        const refRoot = ref(null);
        const opened = ref(false);

        const cDataDefault = {
            closePopupOnBtnClick: false,

            Title: '',
            Message: '',
            CreateTime: '',
            OrderId: '',
            OrderNo: '',
            // 無動作 - 0、APP導引頁面 - 1、內嵌網頁 - 2、網頁導引 - 3
            NotifyActionType: 0,
            // 訂單頁 - 200、訂單詳細頁 - 201 300、 優惠券票夾 - 202、 預約叫車 - 203
            AppRedirectPage: '',
            WebViewUrl: '',
            WebRedirectUrl: '',

            showConfirmBtn: false,
            showCancelBtn: false,
            onConfirm: ()=>{},
            onCancel: ()=>{},
            onClose: ()=>{}
        };

        const cData = reactive({
            ...cDataDefault
        })

        // 測試資料
        // {
        //     "PopupWindow": "true",
        //     "WebRedirectUrl": "",
        //     "PopupWindowMessage": "預約功能預約功能",
        //     "WebViewUrl": "",
        //     "NotifyType": "401",
        //     "NotifyActionType": "1",
        //     "AppRedirectPage": "203",
        //     "PopupWindowTitle": "預約功能"
        // }

        // {
        //     AppRedirectPage:"",
        //     NotifyActionType: "2",
        //     NotifyType: "401",
        //     PopupWindow: "false",
        //     PopupWindowMessage: "",
        //     PopupWindowTitle: "",
        //     WebRedirectUrl: "",
        //     WebViewUrl: "http://60.199.131.69:8021/terms-of-service",
        // }

        function toPage(){

            switch (parseInt(cData.AppRedirectPage)) {
                case 200:
                    // 定列列表
                    f7.view.main.router.navigate('/booking_record')
                    break;
                case 201:
                case 300:
                    // 訂單詳細頁
                    if ( cData.OrderNo ) {
                        f7.view.main.router.navigate('/ride_detailed', {props: {
                            OrderNo: cData.OrderNo, 
                            OrderId: cData.OrderId, 
                        }})
                    } else {
                        f7.dialog.alert(`訂單已取消，無法檢視`, "訊息提示", () => {
                        });
                    }
                    break;
                case 202:
                    // 優惠券票夾
                    f7.view.main.router.navigate('/coupon_list')
                    break;
                case 203:
                    // 預約叫車
                    f7.view.main.router.navigate('/book_cab')
                    break;
                default:
                    break;
            }

            // close 要在最後，避免清掉清掉cData
            if ( cData.closePopupOnBtnClick ){
                close();
            }
        }

        function toBrowser(){
            
            if ( cData.WebRedirectUrl ) {
                m_open_browser(cData.WebRedirectUrl)
            }

            // close 要在最後，避免清掉清掉cData
            if ( cData.closePopupOnBtnClick ){
                close();
            }
        }

        function open(params){
            
            return new Promise((resolve, reject) => {

                $(refRoot.value.$el).one('popup:opened', ()=> {
                    resolve()
                })

                // 當前有打開
                if ( opened.value ) {
                    close().then(()=> {

                        if ( params ) {
                            for(var key in cData ) {
                                if ( params[key] !== undefined ) {
                                    cData[key] = params[key]
                                }
                            }
                        }

                        f7.popup.open(refRoot.value.$el)

                    })
                } else {
                    // 當前沒有打開
                    if ( params ) {
                        for(var key in cData ) {
                            if ( params[key] !== undefined ) {
                                console.log('key', key, params)
                                cData[key] = params[key]
                            }
                        }
                    }
                    
                    f7.popup.open(refRoot.value.$el)
    
                }

            })
        }

        function close(){
            return new Promise((resolve, reject) => {

                $(refRoot.value.$el).one('popup:closed', ()=> {
                    resolve()
                })
                
                f7.popup.close(refRoot.value.$el)

            })
        }

        function confirm(){
            if ( 'function' === typeof cData.onConfirm ) {
                cData.onConfirm()
            }

            // close 要在最後，避免清掉清掉cData
            if ( cData.closePopupOnBtnClick ){
                close();
            }
        }

        function cancel(){
            if ( 'function' === typeof cData.onCancel ) {
                cData.onCancel()
            }

            // close 要在最後，避免清掉清掉cData
            if ( cData.closePopupOnBtnClick ){
                close();
            }
        }

        onMounted(()=>{

            $(refRoot.value.$el).on('popup:open', ()=> {
                opened.value = true;

            })

            $(refRoot.value.$el).on('popup:close', ()=> {
                if ( 'function' === typeof cData.onClose ) {
                    cData.onClose()
                }

            })

            $(refRoot.value.$el).on('popup:closed', ()=> {
                opened.value = false;

                // 還原預設值 (最後執行)
                for(var key in cDataDefault){
                    cData[key] = cDataDefault[key]
                }
            })

            emit('init', {
                open: open,
                close: close,
                get opened(){
                    return opened.value
                }
            })
        })

        return {
            moment,
            cData,
            toPage,
            toBrowser,
            refRoot,
            confirm,
            cancel
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.popup.sty-modal.sty-notify-use.notify-use-popup{
    background: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(15px);

    &.modal-in{
        .page-content{
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1);
        }
    }

    .popup-dialog-head{
        align-items: flex-start;
        .left{
            flex-grow: 1;
            font-size: calc(20 / 14 * 1rem);
            font-weight: bold;
            @include letter-spacing-center(calc(5 / 20 * 1em));
            color: var(--color-theme);
            padding-top: 0.1em;
        }
        .title{
            display: none;
        }
        .right{
            a {
                display: block;
                line-height: 1;
            }
            padding-top: 0.1em;
        }
    }

    .dialog-popup-inner{
        padding-top: 7px;
    }

    .page-content{
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(1.185);
    }

    .popup-time-display{
        color: #adadad;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        i {
            margin-right: 0.3em;
            margin-top: 0.1em;
        }
    }

    .dialog-popup-des{
        font-size: 18px;
    }

    .dialog-popup-btn{
        display: inline-flex;
        width: auto;
        min-width: 190px;
        max-width: 100%;
        .btn-inner{
            width: 100%;
            margin: 0 7px;
        }
    }

    .dialog-popup-btn-2side{
        .btn-inner{
            justify-content: space-between;
        }
    }
}

.notify-use-popup-backdrop{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 13000;
    visibility: hidden;
    opacity: 0;
    transition-duration: 400ms;
    z-index: 10500;

    &.backdrop-in{
        backdrop-filter: blur(15px);
        background: rgba(0, 0, 0, 0.61);
        visibility: visible;
        opacity: 1;
    }
}

</style>



