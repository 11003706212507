
// import { createStore } from 'framework7/lite';
import { f7 } from "framework7-vue";
import { Member, Notify } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import { createStore, createLogger } from 'vuex'
import { i18n } from "@/js/i18n.js";
import axios from "axios";
import modules from './modules' // 引入modules

const store = createStore({
  modules, // 放到這裡
  state() {
    return {
      // 語系
      locale: 'tw',

      // mobile info
      mobileInfo:{},
      
      DriverDispatchUnreadCount: 0,
      DriverSystemUnreadCount: 0,

      // flag
      isAlertReached: false,

      // 最後一個 mqtt cstatus
      // lastCstatus: ''
    }
  },
  getters: {
    getData(state, getters){
      var result = reactive({})

      for (let key in state){
          result[key] = computed(()=> state[key])
      }

      return result
    },
    getUnread(state, getters) {
      return computed(() => state.DriverDispatchUnreadCount + state.DriverSystemUnreadCount)
    },
    getMemberInfo(state, getters) {
      return reactive({
        points: computed(() => state.points),
        points_exchange_twd_ratio: computed(() => state.points_exchange_twd_ratio),
      })
    },
  },
  actions: {
    // 初始
    init({ commit, dispatch}) {
      var locale = localStorage.getItem("MukiLang");
      // 語系
      if (locale) {
        commit('updateLocale', locale)
      } else {
        commit('updateLocale', mukiConst.locale['TW'])
      }

      dispatch('getMemberInfo');
      dispatch('getUnread');
    },
    getUnread({ commit, dispatch}) {
      Notify.Unread().then((res)=>{
          const { data, status} = res;
          if ( status === 200 ) {
            commit('updateCommon', {
              DriverDispatchUnreadCount: data.DriverDispatchUnreadCount,
              DriverSystemUnreadCount: data.DriverSystemUnreadCount,
            })
          }
      })
    },
    getMemberInfo({commit}){
      Member.info({}, 'dev').then((res)=>{
        const { data, status } = res;
        const resData = data['res_data'];
        if (status === 200 && data["res_code"] === 1) {

          const updateObj = {
            points: resData.points,
            points_exchange_twd_ratio: resData.points_exchange_twd_ratio,
            points_near_expiry: resData.points_near_expiry,
          }

          commit('updateMemberInfo', updateObj)
        }
      })
    }
  },
  mutations: {
    setData(state, data){
        for ( let key in state){
            if ( data[key] !== undefined ) {
                state[key] = data[key]
            }
        }
    },
    // 更新通用資料
    updateCommon(state, data) {

      for( let key in data ) {
        if ( state.hasOwnProperty(key) ) {
          if ( data[key] !== undefined ) {
            state[key] = data[key]
          }
        }
      }

    },
    // 更新語系
    updateLocale(state, langStr) {

      var locale = null;

      switch (langStr) {
        case mukiConst.locale['TW']:
            locale = mukiConst.locale['TW'];
          break;
        case mukiConst.locale['EN']:
            locale = mukiConst.locale['EN'];
          break;
        default:
          break;
      }

      if ( typeof i18n !== "undefined" ) {
        if ( i18n.global.locale.value ) {
          i18n.global.locale.value = locale;
        }
      }

      if ( locale ) {
        state.locale = locale
        localStorage.setItem("MukiLang", locale);
      }

    },
    // 更新語系
    updateMemberInfo(state, data) {

      if (data.points !== undefined) {
        state.points = data.points
      }

      if (data.points_exchange_twd_ratio !== undefined) {
        state.points_exchange_twd_ratio = data.points_exchange_twd_ratio
      }

      if (data.points_near_expiry !== undefined) {
        state.points_near_expiry = data.points_near_expiry
      }

    },
  },
  plugins: process.env.NODE_ENV !== 'production'
    ? [/*createLogger()*/]
    : []
})
export default store;
