<template>
    <f7-popup :opened="popupOpened" @popup:closed="popupOpened = false">
        <f7-button  icon-f7="xmark" popup-close></f7-button>
        <slot />
        <!-- <f7-page-content v-if="popupType === 'ad'" id="popup-ad">
            <f7-button icon-f7="xmark" popup-close></f7-button>
            <div class="ad-content" v-if="popupType === 'ad'">
                <img src="https://fakeimg.pl/375x812/" alt="" />
            </div>
        </f7-page-content> -->
    </f7-popup>
</template>

<script>
export default {
    props: {
        popupType: {
            type: String,
            default: "",
        },
        popupOpened: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/variables";
@import "~@/css/mixins";

.page-content {
    &#popup-ad {
        width: 100%;
        padding: 0;

        .popup-close {
            @include size(44px);
            border-radius: 50%;
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
        }

        .ad-content {
            max-height: 100%;
        }
    }
}
</style>
