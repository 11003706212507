<template>
    <div 
        class="base-inner"
        :style="{
            '--base-inner-space': space ? space : null
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props:{
        space: [String]
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";


.base-inner{
    --base-inner-space: 15px;
    padding: 0 var(--base-inner-space);
}

</style>
