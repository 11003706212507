<template>
    <div class="popup popup-tablet-fullscreen popup-ride-rate" id="popup-ride-rate">
        <div class="view">
            <div name="ride_rate" class="page" >
                <!-- Top Navbar -->
                <f7-navbar noShadow noHairline class="navbar-default-layout">
                    <f7-nav-left >
                    </f7-nav-left>
                    <f7-nav-title>
                        搭乘紀錄
                    </f7-nav-title>
                    <f7-nav-right>
                        <BaseNavLogo />
                    </f7-nav-right>
                </f7-navbar>

                <!-- Page content-->
                <div class="page-content">
                    <ContentScroller fillHeight bodyClass="map-area" >
                        <template #top>
                            <BaseSpacer h="10px" />
                            
                            <BaseInner>
                                <div class="ride-summary-box">
                                    <div class="box-media">
                                        <UserMedia>
                                            <img src="@images/preview/user-headshot.svg" alt="" srcset="">
                                        </UserMedia>
                                    </div>
                                    <div class="box-main">
                                        <div class="box-grid">
                                            <div class="grid-col">
                                                <RouteInfo
                                                    title="里程"
                                                    content="10.2"
                                                    postFix="公里"
                                                />
                                                <RouteInfo
                                                    class="small-show"
                                                    title="車牌號碼"
                                                    content="TTC-006"
                                                />
                                            </div>
                                            <div class="grid-divider"></div>
                                            <div class="grid-col">
                                                <RouteInfo
                                                    title="停等時間"
                                                    content="10.2"
                                                    postFix="秒"
                                                />
                                                <RouteInfo
                                                    class="small-show"
                                                    title="車隊名稱"
                                                    content="台灣測試車隊"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <TitleLineDivider>
                                    車資：照表收費
                                </TitleLineDivider>
                                <RouteCard2 
                                    slip
                                    start="桃園市蘆竹區南崁路一段6之1巷1號"
                                    startTime="17:10"
                                    end="桃園市蘆竹區南崁路一段6之1巷1號"
                                    endTime="17:45"
                                />
                                <TitleLineDivider>
                                    旅程評分
                                </TitleLineDivider>
                                <div class="text-align-center">
                                    <RatingStar2 :score="pageData.rate" fz="28px" @rate="rate($event)"/>
                                </div>
                                <BaseSpacer h="20px" class="after-rating" />
                                <f7-row class="reason-block">
                                    <f7-col width="50">
                                        <BaseRadio type="radio" value="1" v-model="pageData.ratingReason">
                                            推薦好司機
                                        </BaseRadio>
                                    </f7-col>
                                    <f7-col width="50">
                                        <BaseRadio type="radio" value="2" v-model="pageData.ratingReason">
                                            我被繞路超收
                                        </BaseRadio>
                                    </f7-col>
                                    <f7-col width="50">
                                        <BaseRadio type="radio" value="3" v-model="pageData.ratingReason">
                                            不能使用愛心卡
                                        </BaseRadio>
                                    </f7-col>
                                    <f7-col width="50">
                                        <BaseRadio type="radio" value="4" v-model="pageData.ratingReason">
                                            其他
                                        </BaseRadio>
                                    </f7-col>
                                </f7-row>
                                <div class="contact-bar">
                                    <div class="bar-title">
                                        消費者服務專線電話：
                                    </div>
                                    <div class="bar-content">
                                        <f7-link class="">
                                            03-222-5300
                                        </f7-link>
                                    </div>
                                </div>

                                <BaseSpacer h="20px" />
                            </BaseInner>
                        </template>
                        <template #body>
                            <GoogleMap
                                :setting="{
                                    element: '.popup-ride-rate .map',
                                    maxZoom: 14,
                                    minZoom: 14,
                                    zoomControl: false,
                                    gestureHandling: 'none',
                                }"
                                style="flex-grow: 1;"
                                @init="setMap($event)"
                            />
                            <div class="connected-btn-group">
                                <BaseButton class="btn-left" @click="close()">
                                    關閉
                                </BaseButton>
                                <BaseButton class="btn-right" @click="submit()">
                                    送出評價
                                </BaseButton>
                            </div>
                        </template>
                    </ContentScroller>
                </div>
                <!-- Page content / -->
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from 'vuex'
import { MukiMap } from '@functions'
import { basePost } from "@baseService";
import GoogleMap from "@components/GoogleMap";
import RatingStar from "@components/RatingStar";
import RouteCard2 from "@projectComponents/RouteCard2";
import DialogPopup2 from "@projectComponents/DialogPopup2";
import ContentScroller from "@components/ContentScroller";
import UserMedia from "@projectComponents/UserMedia";
import RouteInfo from "@projectComponents/RouteInfo";
import TitleLineDivider from "@projectComponents/TitleLineDivider";
import RatingStar2 from "@components/RatingStar2";

import endLocationImg from "@images/map/end_location.svg";
import startLocationImg from "@images/map/start_location.svg";

export default {
    components: {
        ContentScroller,
        UserMedia,
        GoogleMap,
        RouteInfo,
        TitleLineDivider,
        RouteCard2,
        RatingStar2
    },
    props: {
        f7router: Object,
        pageTitle:{
            type: String,
            default: ''
        },
    },
    setup(props) {
        const { f7router } = props;

        // store
        const store = useStore();

        const pageData = reactive({
            rate:1,
            ratingReason: '1',
        })

        const rate = (event) => {
            pageData.rate = event.rate;
        }

        let map = {};
        let $mapGoogle = {};

        const init = ()=>{
        
            // 自訂map popover
            class mapSimplePopover extends google.maps.OverlayView {
                constructor(setting) {
                    super();
                    this.params = setting
                }
                /**
                 * onAdd is called when the map's panes are ready and the overlay has been
                 * added to the map.
                 */
                onAdd() {
                    let setting = this.params;
                    let content = $(setting.content);

                    content.css({
                        position: 'absolute',
                        transform: "translateX(-50%)",
                        opacity: 1,
                    })
                    content.attr('id', "map-popover-" + String(Date.now()))

                    this.content = content[0];


                    // Add the element to the "overlayLayer" pane.
                    const panes = this.getPanes();

                    panes.overlayLayer.appendChild(this.content);

                    google.maps.event.addDomListener(this, 'click', function () {
                        // alert('click')
                    });

                    if ( setting.onAdded ) {
                        setting.onAdded();
                    }
                }
                draw() {
                    this.setToLatLng(this.params.position)
                }
                /**
                 * The onRemove() method will be called automatically from the API if
                 * we ever set the overlay's map property to 'null'.
                 */
                onRemove() {
                    if (this.content) {
                        this.content.parentNode.removeChild(this.content);
                        delete this.content;
                    }
                }
                /**
                 *  Set the visibility to 'hidden' or 'visible'.
                 */
                hide() {
                    if (this.content) {
                        this.content.style.visibility = "hidden";
                        this.content.style.pointerEvents = "none";
                    }
                }
                show() {
                    if (this.content) {
                        this.content.style.visibility = "visible";
                        this.content.style.pointerEvents = "all";
                    }
                }
                toggle() {
                    if (this.content) {
                        if (this.content.style.visibility === "hidden") {
                            this.show();
                        } else {
                            this.hide();
                        }
                    }
                }
                toggleDOM(map) {
                    if (this.getMap()) {
                        this.setMap(null);
                    } else {
                        this.setMap(map);
                    }
                }
                setContent(content) {
                    this.content.innerHTML = content
                }
                setToLatLng(latLng){
                    // 傳入目標 lat lng，google 回傳 x y 應該要設定多少
                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        latLng
                    )

                    this.setPosition(divPosition.x, divPosition.y)
                }
                setPosition(x, y){
                    this.content.style.left = x + "px";
                    this.content.style.top = y  + "px";
                }
                updateMapPan(){
                    var clientRect = this.content.getBoundingClientRect()
                    var map = this.params.map
                    if (
                        clientRect.x < 0 ||
                        clientRect.y < 0 ||
                        clientRect.x + clientRect.width > $('.map-panel').width() ||
                        clientRect.y + clientRect.height > $('.map-panel').height()
                    ) {

                        if (map) {
                            map.panTo(this.params.position.toJSON())
                        }

                    }
                }
            }

            function markerRotate(marker, deg){
                var src = marker.getIcon();
                var el = $('[src="'+src+'"]');
                if ( el.parent().css('overflow') !== 'visible') {
                    el.parent().css('overflow', 'visible');
                }
                el.css('transform', 'rotate('+deg+'deg)')
            }   

            var markerCustomer = new google.maps.Marker({
                position: new google.maps.LatLng('24.161819184994727', '120.64942333765914'),
                map,
                icon: endLocationImg,
                width: 52,
            });


            var markerDriverStart = new google.maps.Marker({
                position: new google.maps.LatLng('24.146720798259345', '120.65725483761423'),
                map,
                icon: startLocationImg,
                width: 96,
            });

            
            function drawRoute(){
                const flightPlanCoordinates = [
                    { lat: 24.146720798259345, lng: 120.65725483761423 },
                    { lat: 24.151125190520474, lng: 120.65751098803813 },
                    { lat: 24.153534507038856, lng: 120.65746941431799 },
                    { lat: 24.157097853710514, lng: 120.65965809669636 },
                    { lat: 24.161819184994727, lng: 120.64942333765914 },
                ];

                MukiMap.drawRoute(flightPlanCoordinates, map)
            }

            drawRoute()

        }


        function setMap(event){
            map = event.map;
            map.setCenter({lat: 24.155857177505894, lng:120.65405908996581 })
            $mapGoogle = event.$mapGoogle;
            init();
        }


        function close(){
            f7.popup.close('#popup-ride-rate');
        }

        function submit(){
            f7.popup.close('#popup-ride-rate');
        };

        return {
            pageData,
            rate,
            setMap,
            
            close,
            submit
        }
    }
};
</script>


<style lang="scss">
@import "@design";

.p-ride-rate{
    .title-line-diivder{
        margin-bottom: 10px;
    }

    @include mobile-down{
        .border-custom-control{
            .custom-control-icon-default{
                margin-right: 7px;
            }
        }   
        .base-spacer.after-rating{
            height: 10px!important;
        }
    }
}

.ride-summary-box{
    display: flex;
    margin-bottom: 1rem;
    .box-media{
        width: 100px;
        flex-shrink: 0;
        margin-right: calc(11 / 14 * 1rem);
        .user-media{
            width: 100%;
        }
    }

    @include media-up(375){
        .box-media{
            width: rwd-val(375px, 100px, 768px, 140px);
        }
    }

    .box-main{
        flex-grow: 1;
    }
    .box-grid{
        display: flex;
        .grid-col{
            &:first-child{
            }
            &:last-child{
            }
        }
        .grid-divider{
            position: relative;
            @include project-border-left;
            flex-grow: 1;
            min-width: 15px;
            &:after{
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .route-info{
            display: block;
        }
    }

    .route-info{
        margin-bottom: 8px;
        .info-title{
            .info-title-txt{
                font-size: calc(12 / 14 * 1rem);
                letter-spacing: 0;
            }
        }
        .info-content{
            font-size: calc(30 / 14 * 1rem);
        }
        &.small-show{
            .info-content{
                font-size: calc(16 / 14 * 1rem);
                font-weight: bold;
            }
        }

        @include media(375){
            .info-content{
                font-size: rwd-val(320px, 22px, 375px, 30px);
            }
        }
    }

    @include media(375){
        .box-media{
            width: rwd-val(320px, 75px, 375px, 100px);
            margin-right: rwd-val(320px, 5px, 375px, 11px);
        }
    }
}


.reason-block{
    .border-custom-control{
        margin-bottom: 15px;
        color: #666666;
        font-size: calc(16 / 14 * 1rem);
    }
}

.contact-bar{
    display: flex;
    align-items: center;
    font-size: calc(16 / 14 * 1rem);
    flex-wrap: wrap;
    .bar-title{
        color: #666666;
        margin-right: 6px;
    }
    .bar-content{
        color: var(--color-theme);
        a{
            color: inherit;
            font-size: calc(20 / 14 * 1rem);
            font-family: 'Arial';
            letter-spacing: calc(1 / 20 * 1em);
            font-weight: bold;
        }
    }
}

.connected-btn-group{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 2px solid var(--color-theme);
    border-radius: 20px;
    overflow: hidden;
    width: 322px;
    max-width: 86%;
    display: flex;
    z-index: 2;
    .button{
        --f7-button-height: 40px;
        width: 50%;
        font-size: calc(18 / 14 * 1rem);
        @include letter-spacing-center(calc(4.5 / 18 * 1em));
        font-weight: bold;
    }

    .btn-left{
        color:var(--color-theme);
        background:#fff;
        border-radius: 0;
    }

    .btn-right{
        color: #fff;
        background: var(--color-theme);
        border-radius: 0;
    }
}

.map-area{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 239px;
    .map-panel{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .map{
        height:auto!important;
        flex-grow: 1;
    }
}

</style>


