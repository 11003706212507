import { baseGet, basePost } from "@/js/services/baseService";
import { computed } from "vue";

export default {
    namespaced: true,
    state: () => ({
        
    }),
    getters: {
        
    },
    actions: {
        
    },
    mutations: {
        
    },
}

