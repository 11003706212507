<template>
    <div 
        class="base-spinner"
        :class="{
            'sty-block-center': blockCenter
        }"
    >
        <svg class="spinner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50">
            <defs>
                <linearGradient id="linear-gradient" y1="0.5" x2="0.139" y2="0.15" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#fbfcfe"/>
                <stop offset="1" stop-color="#dfdfdf"/>
                </linearGradient>
            </defs>
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke="url(#linear-gradient)" stroke-width="5"></circle>
        </svg>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        blockCenter: Boolean
    },
    setup(props, {emit}){

        return {
            
        }
    }
};
</script>

<style lang="scss" scoped >
@import "@design";

.base-spinner{
    display: inline-block;

    &.sty-block-center{
        display: block;
        text-align: center;
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
}

.spinner .path{
    stroke: #fd8908;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

</style>


