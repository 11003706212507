import store from '../store/store.js'
import Home from "@/pages/Home";
import Login from '@/pages/Login.vue';
import NotFoundPage from "@/pages/404.vue";

var routes = [
    {
        path: "/",
        // keepAlive: true,
        // component: store.state.auth.isLogined ? Home :
        async: function ({ app, to, resolve }) {

            const isLogined = store.getters['auth/isLogined'];
            
            // Requested route
            if ( isLogined ) {
                resolve({
                    component: Home
                })
            } else {
                resolve({
                    component: Login
                })
            }
        }
    },
    // 註冊
    {
        path: "/register",
        asyncComponent: () => import("@/pages/Register"),
    },
    // 註冊驗證
    {
        path: "/register_verify",
        asyncComponent: () => import("@/pages/RegisterVerify"),
    },
    // 註冊完成
    {
        path: "/register_result",
        asyncComponent: () => import("@/pages/RegisterResult"),
    },
    // {
    //     path: "/home",
    //     component: Home,
    // },
    // {
    //     path: "/dm_list",
    //     asyncComponent: () => import("@/pages/DMList"),
    // },
    {
        path: "/login",
        component: Login,
    },
    // 忘記密碼
    {
        path: "/forgot_pwd",
        asyncComponent: () => import("@/pages/ForgotPwd"),
    },
    {
        path: "/forgot_pwd_verify",
        asyncComponent: () => import("@/pages/ForgotPwdVerify"),
    },
    {
        path: "/forgot_pwd_reset",
        asyncComponent: () => import("@/pages/ForgotPwdReset"),
    },
    {
        path: "/forgot_pwd_result",
        asyncComponent: () => import("@/pages/ForgotPwdResult"),
    },
    
    // 常用地址
    {
        path: "/select_common_address",
        asyncComponent: () => import("@/pages/SelectCommonAddress"),
    },
    // 歷史紀錄地址
    {
        path: "/select_history_address",
        asyncComponent: () => import("@/pages/SelectHistoryAddress"),
    },


    // === 一般訂車 ===
    // 預約訂車
    {
        path: "/book_cab",
        asyncComponent: () => import("@/pages/BookCab"),
    },
    // 選擇乘車時段
    {
        path: "/book_select_time_range",
        asyncComponent: () => import("@/pages/BookSelectTimeRange"),
    },
    // 乘車時間與車隊
    {
        path: "/book_select_time_and_fleet",
        asyncComponent: () => import("@/pages/BookSelectTimeAndFleet"),
    },
    // 使用優惠券
    {
        path: "/select_coupon",
        asyncComponent: () => import("@/pages/SelectCoupon"),
    },
    // 預約結果
    {
        path: "/book_cab_result",
        asyncComponent: () => import("@/pages/BookCabResult"),
    },
    // 候補結果
    {
        path: "/wait_cab_result",
        asyncComponent: () => import("@/pages/WaitCabResult"),
    },

    // === 固定時段訂車 ===
    // 預約訂車
    {
        path: "/book_period_cab",
        asyncComponent: () => import("@/pages/BookPeriodCab"),
    },
    // 選擇時段
    {
        path: "/book_period_select_time_range",
        asyncComponent: () => import("@/pages/BookPeriodSelectTimeRange"),
    },
    // 預約定期結果
    {
        path: "/book_period_result",
        asyncComponent: () => import("@/pages/BookPeriodResult"),
    },


    // === 臨時訂車 ===
    // 預約訂車
    {
        path: "/book_casual_cab",
        asyncComponent: () => import("@/pages/BookCasualCab"),
    },
    // 選擇時段
    {
        path: "/book_casual_select_time_range",
        asyncComponent: () => import("@/pages/BookCasualSelectTimeRange"),
    },
    // 預約定期結果
    {
        path: "/book_casual_result",
        asyncComponent: () => import("@/pages/BookCasualResult"),
    },


    // 車隊地圖追縱
    {
        path: "/fleet_on_map",
        asyncComponent: () => import("@/pages/FleetOnMap"),
    },
    // 搭乘紀錄
    {
        path: "/ride_rate",
        asyncComponent: () => import("@/pages/RideRate"),
    },
    // 搭乘紀錄(顯示頁)
    {
        path: "/ride_detailed",
        asyncComponent: () => import("@/pages/RideDetailed"),
    },
    // 地圖定位與搜尋
    {
        path: "/get_address_by_map",
        asyncComponent: () => import("@/pages/GetAddressByMap"),
    },

    // 聯絡司機
    {
        path: "/contact_driver",
        asyncComponent: () => import("@/pages/ContactDriver"),
    },
    // 選擇常用訊息
    {
        path: "/select_common_msg",
        asyncComponent: () => import("@/pages/SelectCommonMsg"),
    },
    
    // ==== 會員 ====
    // 會員
    {
        path: "/member_center",
        asyncComponent: () => import("@/pages/MemberCenter"),
    },
    // 會員設定
    {
        path: "/member_profile",
        asyncComponent: () => import("@/pages/MemberProfile"),
    },
    // 會員手機修改
    {
        path: "/member_mobile_edit",
        asyncComponent: () => import("@/pages/MemberMobileEdit"),
    },
    // 會員密碼修改
    {
        path: "/member_change_pwd",
        asyncComponent: () => import("@/pages/MemberChangePwd"),
    },
    // 我的優惠券
    {
        path: "/coupon_list",
        asyncComponent: () => import("@/pages/CouponList"),
    },
    // 我的優惠券 (詳細)
    {
        path: "/coupon_detailed",
        asyncComponent: () => import("@/pages/CouponDetailed"),
    },
    // 違規紀錄
    {
        path: "/violation_record",
        asyncComponent: () => import("@/pages/ViolationRecord"),
    },
    // 訂單
    {
        path: "/booking_record",
        asyncComponent: () => import("@/pages/BookingRecord"),
    },
    // 訂單
    // {
    //     path: "/booking_record2",
    //     asyncComponent: () => import("@/pages/BookingRecord2"),
    // },
    // 訂單
    // {
    //     path: "/booking_record3",
    //     asyncComponent: () => import("@/pages/BookingRecord3"),
    // },
    // 通知
    {
        path: "/notification",
        asyncComponent: () => import("@/pages/Notification"),
    },
    // 包車
    {
        path: "/charted_car_fleet",
        asyncComponent: () => import("@/pages/ChartedCarFleet"),
    },

    // 常用地址管理
    {
        path: "/common_address",
        asyncComponent: () => import("@/pages/CommonAddress"),
    },
    // 罐頭訊息管理
    {
        path: "/common_msg",
        asyncComponent: () => import("@/pages/CommonMsg"),
    },

    // webview
    {
        path: "/common_webview",
        asyncComponent: () => import("@/pages/CommonWebView"),
    },

    // test
    // {
    //     path: "/test",
    //     asyncComponent: () => import("@/pages/test"),
    // },

    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
