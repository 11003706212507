import { Order } from "@baseService";
import { computed, reactive } from "vue";

export default {
    namespaced: true,
    state: () => ({
        // 未完成列表
        unFinishedList: [],
        // 已完成列表
        finishedList: [],
    }),
    getters: {
        getData(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }

            return result
        },
    },
    actions: {
        init({commit}, data) {
            commit('clearData');
        },
    },
    mutations: {
        setData(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        pushFinishedList(state, data){
            data.forEach((item) => {
                state.finishedList.push(item)
            })
        },
        pushUnFinishedList(state, data){
            data.forEach((item) => {
                state.unFinishedList.push(item)
            })
        },
        updateRecordRating(state, data){

            if ( data.orderNo === undefined || data.orderNo === null || data.orderNo === '' ) return;

            state.finishedList.forEach(function(record){
                if ( record.OrderNo === data.orderNo ) {
                    if ( data.PassengerByRating ) {
                        record.PassengerByRating = data.PassengerByRating
                    }
                }
            });

        },
        clearUnFinishedData(state){
            state.unFinishedList = [];
        },
        clearFinishedData(state){
            state.finishedList = [];
        },
        clearData(state, data){
            for ( let key in state){
                state[key] = ''
            }

            state.unFinishedList = [];
            state.finishedList = [];
        },
    },
}

