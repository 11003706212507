import { baseGet, basePost, Cart } from "@/js/services/baseService";
import { computed, reactive } from "vue";

const dataList = [
    // 登入token
    'onlinePreOrder',
    'mobileStore',
    'selectedCoupon'
]

export default {
    namespaced: true,
    state: () => ({
        onlinePreOrder: {
            num: 0,
        },
        mobileStore: {
            num: 0,
        },
        // 選擇的優惠券
        selectedCoupon:{
            id: '',
            name: '',
            value: 0,
        },
        // 選擇的取貨門市
        pickupStore:{
            id: '',
            name: '',
            address: '',
        },
        // 選擇的行動超市
        mobileStore:{
            id: '',
            name: '',
            address: '',
        }
    }),
    getters: {
        // 詳細
        getCartNum(state, getters){
            return reactive({
                onlinePreOrder: computed(() => state.onlinePreOrder),
                mobileStore: computed(() => state.mobileStore),
            })
        },
        getSelectedCoupon(state, getters){
            return state.selectedCoupon
        },
        getPickupStore(state, getters) {
            return state.pickupStore
        },
        getMobileStore(state, getters) {
            return state.pickupStore
        }
    },
    actions: {
        updateLatest({commit}, data) {
            Cart.cartNum({}, 'dev').then((res) => {
                const { data, status } = res;
                const resData = data['res_data'];
                if (status === 200 && data["res_code"] === 1) {

                    commit('updateCartNum', {
                        onlinePreOrder:{
                            num: resData.online_preorder
                        },
                        mobileStore: {
                            num: resData.mobile_store
                        },
                    });

                }
            })
        },
        init({ commit, dispatch}){
            dispatch('updateLatest');
        },
    },
    mutations: {
        updateCartNum(state, data){
            console.log('update===',)
            dataList.forEach(name => {
                if ( data[name] !== undefined && data[name].num !== undefined) {
                    state[name].num = data[name].num;
                }
            });
        },
        setSelectedCoupon(state, data){
            state.selectedCoupon.id = data.id;
            state.selectedCoupon.name = data.name;
            state.selectedCoupon.value = data.value;
        },
        setPickupStore(state, data) {
            state.pickupStore.id = data.id;
            state.pickupStore.name = data.name;
            state.pickupStore.address = data.address;
        },
        setMobileStore(state, data) {
            state.pickupStore.id = data.id;
            state.pickupStore.name = data.name;
            state.pickupStore.address = data.address;
        }
    },
}

