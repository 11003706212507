export default {
    dialog: {
        buttonOk: '確定',
        buttonCancel: '取消',
    },
    photoBrowser:{
        bannerPhotoBrowser:'關閉'
    },
    newFabrics: "新面料",
    news: '最新消息',
    chatRoom: '洽詢車',
    archive: '封存',
    labdipStatus: 'Labdip狀態',
    fabricname: '織物名稱',
    'fabric#': '織物編號',
    color: '顏色',
    receivedDate: '收貨日期',
    leadTime: '交貨日期',
    confirmDate: '確認日期',
    programSchedule: '處理時程表',
    rdStatus: 'R&D 狀態',
    localeSwitch: '語系切換',
    changePwd: '變更密碼',
    logout: '登出',
    signUp: '註冊',
    notification: '通知',
    orderStatus: "訂單狀態",
}