<template>
    <f7-button
        :type="type"
        @click="href ? m_direct_page(href) : '';"
        :class="[
            {
                'sty-pill': pill ? true : null,
                'sty-login-register': loginRegister ? true : null,
                'sty-lg': lg ? true : null,
                'sty-text-opacity': textOpacity ? true : null,
                'shadow': shadow ? true : null,
            }
        ]"
        :style="{
            '--f7-button-height' : h ? h : null,
            '--button-border-wdith' : borderWidth ? borderWidth : null,
        }"
    >
        <div class="btn-inner">
            <slot />
        </div>
    </f7-button>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        type: {
            type: String,
            default: 'button'
        },

        href:String,

        // 高度
        h:{
            type: [String],
            default: '',
        },
        // 高度 /

        // pill 樣式
        pill: Boolean,
        // pill 樣式 /

        // login register頁用
        loginRegister: Boolean,

        // 陰影
        shadow: Boolean,

        // 透明
        textOpacity: Boolean,

        // borderWidth
        borderWidth: String,

        // 大小
        lg: Boolean,

        // block 、 inline
        inline: Boolean,

    },
    setup(props, context){

        return {
            m_direct_page
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

 // btn
.button{
    font-size: 16px;
    --button-border-wdith: 1px;

    &.shadow{
        box-shadow: 0 5px 8px #00000026;
    }

    &.sty-pill{
        border-radius: calc( var(--f7-button-height) / 2);
    }

    &.active-state {
        opacity: .7;
    }

    &.btn-radius-10px{
        border-radius: 10px;
    }

    &.btn-radius-pill{
        border-radius: 20px;
    }

    &.btn-lt-space{
        letter-spacing: 0.1em;
    }

    &.sty-login-register{
        font-size: calc( 20 / 14 * 1rem);
        @include letter-spacing-center(calc( 5 / 20 * 1em));
        font-weight: bold;
    }

    &.sty-lg{
        --f7-button-height: 42px;
        font-size: calc(20 / 14 * 1rem);
        @include letter-spacing-center(calc( 5 / 20 * 1em));
        font-weight: bold;
    }

    &.sty-text-opacity {
        .btn-inner{
            opacity: 0.8;
        }
    }

    @include mobile-down{
        font-size: 15px;
        &.size-h-40px{
            height: 35px;
        }
        &.size-h-45px{
            height: 40px;
        }
    }
}

.ios, .md{
    .btn-inner{
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
    }

    .btn-pill{
        border-radius: 22px;
    }
    
    .btn-theme{
        background: $color-primary;
        color:#fff;
    }
    .btn-sec{
        background: $color-sec;
        color:#fff;
    }
    
    .btn-gray{
        background:#D8D8D8;
        color: #fff;
    } 
    
    .btn-blue{
        background: #0498D8;
        color:#fff;
    }

    .btn-yellow{
        background: #FFD218;
        border: 1px solid #DBAD16;
        color: #666666;
    }

    .btn-gt-theme{
        background: linear-gradient(-270deg, #9DB2DB, #1B70AA);
        color:#fff;
    }
    .btn-gt-blue-light{
        background: linear-gradient(-93deg, #0498D8 48%, #A1E3FF);
        color:#fff;
    }
    
    .btn-outline-theme{
        color: $color-primary;
        background: none;
        border:1px solid $color-primary;
        &.active-state{
            background: $color-primary;
            color: #fff;
        }
    }
    .btn-outline-sec{
        color: $color-sec;
        background: none;
        border:var(--button-border-wdith) solid $color-sec;
        &.active-state{
            background: $color-sec;
            color: #fff;
        }
    }
    .btn-outline-light{
        color: $color-font;
        background: none;
        border:var(--button-border-wdith) solid #CDC990;
        &.active-state{
            background: #CDC990;
            color: #fff;
        }
    }
    .btn-outline-light2{
        color: #CDC990;
        background: none;
        border:var(--button-border-wdith) solid #CDC990;
        &.active-state{
            background: #CDC990;
            color: #fff;
        }
    }
    .btn-outline-gray{
        color: #898989;
        background: none;
        border:var(--button-border-wdith) solid #CCCCCC;
        &.active-state{
            background: #CCCCCC;
            color: #fff;
        }
    }

    .btn-gray-bordered{
        border: var(--button-border-wdith) solid #D8D8D8;
        background: #F8F8F8;
        color: #939393;
    }
}

// btn /

</style>
