<template>
    <div class="title-line-diivder" >
        <BaseDivider dotted />
        <div class="divider-title">
            <slot />
        </div>
        <BaseDivider dotted />
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
import BaseDivider from "@components/BaseDivider";
export default {
    components:{
        BaseDivider
    },
    props: {
    },
    setup(props, {emit}){
        
        return {

        }
    }
};
</script>

<style lang="scss">
@import "@design";

.title-line-diivder{
    color: #ADADAD;
    font-size: 1rem;
    @include letter-spacing-center(0.05em);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .divider-title{
        flex-shrink: 0;
        margin: 0 11px;
    }
    .base-divider{
        flex-grow: 1;
    }
}

</style>



