<template>
    <div 
        class="address-card"
        :class="{
            'sty-no-shadow': noShadow ? noShadow : null
        }"
    >
        <div class="card-date">{{date}}</div>
        <div class="card-prepend">
            <i class="icon-star"></i>
        </div>
        <div class="card-content">
            <slot />
        </div>
        <div class="card-append">
            <slot name="append" />
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        date: String,
        noShadow: Boolean
    },
    setup(props, context){

        return {
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/design";

.address-card-list{
    filter: drop-shadow(0px 5px 26px #00000008);
}

.address-card{
    background: #fff;
    box-shadow: 0px 5px 26px #00000008;
    padding: 18px 16px;
    position:relative;
    border-radius: 16px;
    margin-bottom: calc(12 / 14 * 1rem);
    display: flex;
    align-items: center;

    &.sty-no-shadow{
        box-shadow: none;
    }

    .card-prepend{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        flex-shrink: 0;
        background: #EAECED;
        @include flex(center, center);
        color: #fff;
        font-size: calc( 18.52 / 14 * 1rem);
        margin-right: calc(11 / 14 * 1rem);
    }
    .card-date{
        font-size: calc( 12 / 14 * 1rem);
        font-size: calc(9 / 12);
        transform-origin: right top;
        color: rgba(#000, 0.5);
        position: absolute;
        top: 2.5px;
        right: 16.7px;
    }
    .card-append{
        margin-left: auto;
        padding-left: 10px;
        @include flex(center, center);
    }
}

</style>
