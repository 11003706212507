<template>
    <f7-app v-bind="f7params">
        <!-- Left resizable Panel with Reveal effect -->
        <f7-panel left cover backdrop resizable>
        <f7-view>
            <f7-page>
                <div class="page-inner">
                    <f7-block class="panel-top-block" >
                        <f7-link class="text-left" panel-close>
                            <i class="icon-delete"></i>
                        </f7-link>
                    </f7-block>
                </div>

                <div class="test-div"></div>
            </f7-page>
        </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" url="/">
            <f7-toolbar bottom class="toolbar-menu">
                <div class="col-item" :class="{'is-active': currentPath == '/'}" >
                    <f7-link  @click="setNoBack(); m_refresh_page('/')" class="link menu-link" >
                        <!-- <i class="icon-house"></i> -->
                        <div class="menu-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Group_17003" data-name="Group 17003" transform="translate(0 0)">
                                <path id="Path_12208" data-name="Path 12208" d="M0,0H4V7H0Z" transform="translate(10 14)" fill="#"/>
                                <path id="Path_11943" data-name="Path 11943" d="M20.42,10.18,12.71,2.3A1,1,0,0,0,11.3,2.294L11.29,2.3,3.58,10.19A2,2,0,0,0,3,11.62V20a2,2,0,0,0,1.89,2H8V13a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v9h3.11A2,2,0,0,0,21,20V11.62A2.069,2.069,0,0,0,20.42,10.18Z" transform="translate(0 0)" fill="#"/>
                            </g>
                            </svg>
                        </div>
                        <div class="txt">
                            首頁
                        </div>
                    </f7-link>
                </div>
                <div class="col-item" :class="{'is-active': currentPath == '/booking_record'}" >
                    <f7-link @click="m_refresh_page('/booking_record')" class="link menu-link"  >
                        <!-- <i class="icon-bars"></i> -->
                        <div class="menu-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g id="list">
                                    <rect id="Rectangle_17395" data-name="Rectangle 17395" width="24" height="24" transform="translate(24 24) rotate(180)" fill="#" opacity="0"/>
                                    <circle id="Ellipse_1333" data-name="Ellipse 1333" cx="1" cy="1" r="1" transform="translate(3 6)" fill="#"/>
                                    <circle id="Ellipse_1334" data-name="Ellipse 1334" cx="1" cy="1" r="1" transform="translate(3 11)" fill="#"/>
                                    <circle id="Ellipse_1335" data-name="Ellipse 1335" cx="1" cy="1" r="1" transform="translate(3 16)" fill="#"/>
                                    <rect id="Rectangle_17396" data-name="Rectangle 17396" width="14" height="2" rx="0.94" transform="translate(7 11)" fill="#"/>
                                    <rect id="Rectangle_17397" data-name="Rectangle 17397" width="14" height="2" rx="0.94" transform="translate(7 16)" fill="#"/>
                                    <rect id="Rectangle_17398" data-name="Rectangle 17398" width="14" height="2" rx="0.94" transform="translate(7 6)" fill="#"/>
                                </g>
                            </svg>
                        </div>
                        <div class="txt">
                            訂單
                        </div>
                    </f7-link>
                </div>
                <div v-if="false" class="col-item" :class="{'is-active': currentPath == '/booking_record'}" >
                    <f7-link @click="m_refresh_page('/booking_record2')" class="link menu-link"  >
                        <!-- <i class="icon-bars"></i> -->
                        <div class="menu-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g id="list">
                                    <rect id="Rectangle_17395" data-name="Rectangle 17395" width="24" height="24" transform="translate(24 24) rotate(180)" fill="#" opacity="0"/>
                                    <circle id="Ellipse_1333" data-name="Ellipse 1333" cx="1" cy="1" r="1" transform="translate(3 6)" fill="#"/>
                                    <circle id="Ellipse_1334" data-name="Ellipse 1334" cx="1" cy="1" r="1" transform="translate(3 11)" fill="#"/>
                                    <circle id="Ellipse_1335" data-name="Ellipse 1335" cx="1" cy="1" r="1" transform="translate(3 16)" fill="#"/>
                                    <rect id="Rectangle_17396" data-name="Rectangle 17396" width="14" height="2" rx="0.94" transform="translate(7 11)" fill="#"/>
                                    <rect id="Rectangle_17397" data-name="Rectangle 17397" width="14" height="2" rx="0.94" transform="translate(7 16)" fill="#"/>
                                    <rect id="Rectangle_17398" data-name="Rectangle 17398" width="14" height="2" rx="0.94" transform="translate(7 6)" fill="#"/>
                                </g>
                            </svg>
                        </div>
                        <div class="txt">
                            訂單
                        </div>
                    </f7-link>
                </div>
                <div v-if="false" class="col-item" :class="{'is-active': currentPath == '/booking_record'}" >
                    <f7-link @click="m_refresh_page('/booking_record3')" class="link menu-link"  >
                        <!-- <i class="icon-bars"></i> -->
                        <div class="menu-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g id="list">
                                    <rect id="Rectangle_17395" data-name="Rectangle 17395" width="24" height="24" transform="translate(24 24) rotate(180)" fill="#" opacity="0"/>
                                    <circle id="Ellipse_1333" data-name="Ellipse 1333" cx="1" cy="1" r="1" transform="translate(3 6)" fill="#"/>
                                    <circle id="Ellipse_1334" data-name="Ellipse 1334" cx="1" cy="1" r="1" transform="translate(3 11)" fill="#"/>
                                    <circle id="Ellipse_1335" data-name="Ellipse 1335" cx="1" cy="1" r="1" transform="translate(3 16)" fill="#"/>
                                    <rect id="Rectangle_17396" data-name="Rectangle 17396" width="14" height="2" rx="0.94" transform="translate(7 11)" fill="#"/>
                                    <rect id="Rectangle_17397" data-name="Rectangle 17397" width="14" height="2" rx="0.94" transform="translate(7 16)" fill="#"/>
                                    <rect id="Rectangle_17398" data-name="Rectangle 17398" width="14" height="2" rx="0.94" transform="translate(7 6)" fill="#"/>
                                </g>
                            </svg>
                        </div>
                        <div class="txt">
                            訂單
                        </div>
                    </f7-link>
                </div>
                <div class="col-item" :class="{'is-active': currentPath == '/notification'}" >
                    <a @click="m_refresh_page('/notification')" class="link menu-link" >
                        <!-- <i class="icon-bell2"></i> -->
                        <div class="menu-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g id="bell">
                                    <rect id="Rectangle_17394" data-name="Rectangle 17394" width="24" height="24" fill="#939393" opacity="0"/>
                                    <path id="Path_11947" data-name="Path 11947" d="M20.52,15.21l-1.8-1.81V8.94A6.86,6.86,0,0,0,12.9,2.06,6.74,6.74,0,0,0,5.28,8.73V13.4l-1.8,1.81A1.64,1.64,0,0,0,4.64,18H8v.34A3.84,3.84,0,0,0,12,22a3.84,3.84,0,0,0,4-3.66V18h3.36a1.64,1.64,0,0,0,1.16-2.79ZM14,18.34A1.88,1.88,0,0,1,12,20a1.88,1.88,0,0,1-2-1.66V18h4Z" fill="#"/>
                                </g>
                            </svg>
                            <NumBadge :num="notifyData.unread.Total" />
                        </div>
                        <div class="txt">
                            通知
                        </div>
                    </a>
                </div>
                <div class="col-item" :class="{'is-active': currentPath == '/member_center'}">
                    <a @click="m_refresh_page('/member_center')" class="link menu-link"  >
                        <!-- <i class="icon-man"></i> -->
                        <div class="menu-icon">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="person">
                                <path id="Path_11945" data-name="Path 11945" d="M12,11A4,4,0,1,0,8,7a4,4,0,0,0,4,4Z" fill="#"/>
                                <path id="Path_11946" data-name="Path 11946" d="M18,21a1,1,0,0,0,1-1A7,7,0,1,0,5,20a1,1,0,0,0,1,1Z" fill="#"/>
                            </g>
                            </svg>
                        </div>
                        <div class="txt">
                            個人
                        </div>
                    </a>
                </div>
            </f7-toolbar>
        </f7-view>
    </f7-app>

    <AppGlobalNotifyPopup
        @init="setAppGlobalNotifyPopup($event)"
    >
    </AppGlobalNotifyPopup>


    <DialogPopup2 class="reach-popup" id="reach-target-popup" width="320px" >
        <div class="dialog-popup-inner">
            <div class="dialog-popup-title">
                抵達目的地
            </div>
            <BaseSpacer h="24px" />
            <div class="dialog-popup-desc text-align-center">
                <div>
                親愛的顧客您好！
                </div>
                <div>
                謝謝您的搭乘
                </div>
            </div>
            <BaseSpacer h="27px" />
            <BaseButton
                pill
                class="btn-theme dialog-popup-btn"
                @click="gotIt()"
            >
                確定
            </BaseButton>
        </div>
    </DialogPopup2>

    <HandicappedStatusPopup @init="cData.HandicappedStatusPopup = $event"/>

    <!-- 裁切圖片 -->
    <div class="popup popup-tablet-fullscreen popup-crop_picture">
        <div class="view">
            <div class="page">
                <div class="navbar text-color-white border-color-gray">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner">
                        <div class="left">
                            <f7-link class="" popup-close>
                                <i class="icon-angle-left"></i>
                            </f7-link>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <div class="page-content-inner">
                    <div class="page-inner">
                        <div class="crop_picture_block">
                            <input type="file" id="crop_picture_file" accept="image/jpg,image/jpeg,image/png"
                                style="display: none;" />
                            <div class="crop_picture_wrap" id="crop_picture_select_trigger">
                                <div class="form-group" id="crop_picture_select_group">
                                    <div class="common_preview" id="crop_picture_preview"></div>
                                </div>
                                <button type="button" id="crop_picture_select_btn" class="bg-theme">
                                    <f7-icon f7="camera_fill"></f7-icon>
                                </button>
                            </div>
                            <div class="explain-wrap">
                                <h3 class="crop_picture_title text-theme">建議尺寸：<span id="crop_picture_pic_size"></span></h3>
                                <small class="crop_picture_tip ">可視範圍即為預覽效果<br>移動位置以拖曳方式操作</small>
                            </div>
                            <div class="crop_picture-btn-wrap">
                                <div class="row">
                                    <div class="col-50">
                                        <button type="button" id="crop_picture_upload_btn" class="col button btn-gt-theme size-h-45px">上傳圖片</button>
                                    </div>
                                    <div class="col-50">
                                        <button data-deg="90" type="button" id="crop_picture_rotate_btn" class="col button btn-gt-theme size-h-45px">
                                            <i class="btn-before fa fa-repeat btn-icon" aria-hidden="true"></i>
                                            旋轉圖片
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="v-spacer-30px"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 裁切圖片(end) -->

    <!-- 樣板 sty-modal -->
    <div class="popup popup-tablet-fullscreen popup-sty-modal" id="popup-sty-modal-default">
        <div class="view">
            <div class="page">
                <div class="navbar">
                    <div class="navbar-inner">
                        <div class="left">
                        </div>
                        <div class="title"></div>
                        <div class="right">
                            <a class="link popup-close popup-close-auto">
                                <f7-icon f7="multiply"></f7-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="page-content popup-modal-centered">
                    <div class="page-inner">
                        <div class="popup-modal-content no-bg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 樣板 sty-modal (END) -->

</template>

<script>
import { ref, reactive, onMounted, watchEffect, watch, nextTick } from "vue";
import { f7ready } from 'framework7-vue/bundle';
import { Order, Account, Config, Notify, Contact } from "@baseService";
import routes from "@/js/routes.js";
import { setNoBack, clearNoBack, checkNoBack, MukiMap,  checkGps, getExecutingOrderDetail} from '@functions';
import { useStore } from 'vuex';
import store from "@/store/store.js";
import { useI18n } from "vue-i18n";
import { MQTT_LOGIN_CONFIG } from "@/js/const.js";
import NumBadge from "@projectComponents/NumBadge";
import DialogPopup2 from "@projectComponents/DialogPopup2";
import AppGlobalNotifyPopup from "@projectComponents/AppGlobalNotifyPopup";
import UCheckbox from "@components/UCheckbox";
import HandicappedStatusPopup from "@/popup/HandicappedStatusPopup";
// import route1 from "@/js/data/route1";
// import route2 from "@/js/data/route2";

export default {
    components:{
        NumBadge,
        DialogPopup2,
        AppGlobalNotifyPopup,
        UCheckbox,
        HandicappedStatusPopup
    },
    setup() {
        // 除錯用
        const store = useStore();
        
        // store Init
        store.dispatch('auth/init');
        store.dispatch('executingOrder/init');

        const isLogined = store.getters['auth/isLogined'];
        const notifyData = store.getters['notify/getData'];
        const commonData = store.getters['getData'];
        const bookingData = store.getters['booking/getBookingData'];
        const executingOrder = store.getters['executingOrder/getData'];

        const cData = reactive({
            HandicappedStatusPopup: '',
            UserInfoPs: '',
        })

        // ==== 全站司機狀態監聽 ====

        let driverMqttOn = false;
        let mqttClient;
        let mqttTopic;
        let currentActiveDialog;
        // 暫存訂單id 、no 作為訂單結束後評分使用
        let executingOrderNo;
        let executingOrderId;
        // flag
        let isAlertReached = false;

        // 訂單結束
        function orderDone(){
            // Mqtt是 On 的情況下才執行
            if ( driverMqttOn ) {
                driverMqttOn = false;
                // 結束連線
                mqttClient.unsubscribe(mqttTopic)
                console.log('MQTT Unsubscribe')
                // 清除資料
                store.commit('executingOrder/clearData')
                isAlertReached = false;
            }

            // 清除輪詢
            // if ( interValGetExecutingOrderDetail.interval ) {
            //     clearInterval(interValGetExecutingOrderDetail.interval);
            // }
        }

        /**
         * 啟動司機 Mqtt 連線
         * 目前 mqtt 只用來更新司機位置
         */
        function DriverStatusMqtt(){
            
            if ( driverMqttOn ) return;

            if ( 
                executingOrder.HasExecutingOrder.DriverId === '' || 
                executingOrder.HasExecutingOrder.DriverId === undefined ||
                executingOrder.HasExecutingOrder.DriverId === null
            ) {
                console.log('HasExecutingOrder 未正確取得 DriverId');
                return
            }
            

            mqttTopic = mukiConst.mqtt.TRACK_SEND + executingOrder.HasExecutingOrder.DriverId;

            // 建立連線架構
            if ( !mqttClient ) {
                mqttClient = mqtt.connect(mukiConst.mqtt.URL, { ...MQTT_LOGIN_CONFIG });

                mqttClient.on('connect', () => {
                    console.log('Driver mqtt connect')
                });

                mqttClient.on('disconnect', () => {
                    console.log('Driver mqtt disconnect')
                });

                mqttClient.on('close', () => {
                    console.log('Driver mqtt close')
                });
        
                mqttClient.on('message', function (topic, message) {
                    // message is Buffer
                    let msg = JSON.parse(message.toString());

                    store.commit('executingOrder/setData', {
                        mqttPrev: {
                            gLat: executingOrder.mqtt.gLat,
                            gLng: executingOrder.mqtt.gLng,
                            gAngle: executingOrder.mqtt.gAngle,
                            CStatus: executingOrder.mqtt.CStatus
                        }
                    });

                    // console.log('DR', msg, )

                    store.commit('executingOrder/setData', {
                        mqtt: {
                            gLat: msg.GLat,
                            gLng: msg.GLng,
                            gAngle: msg.GAngle,
                            CStatus: parseInt(msg.CStatus)
                        }
                    });

                    let newMqttArr = executingOrder.mqttArr ? executingOrder.mqttArr.slice() : []
                    newMqttArr.push({
                        gLat: executingOrder.mqtt.gLat,
                        gLng: executingOrder.mqtt.gLng,
                        gAngle: executingOrder.mqtt.gAngle,
                        CStatus: executingOrder.mqtt.CStatus
                    });

                    store.commit('executingOrder/setData', {
                        mqttArr: newMqttArr
                    });

                    // console.log('driver msg', msg, msg.CStatus)
                })

                /*
                    2022/4/1 : mqtt不再負責司機位置以外工作

                    watch(()=>  executingOrder.mqtt.CStatus , ()=>{
                        // 使用者抵達目的地
                        if ( executingOrder.mqtt.CStatus === mukiConst.driverStatus.PAYING ) {
                            console.log('ORDER DONE', executingOrder.mqtt.CStatus , mukiConst.driverStatus.PAYING)

                            // 2022/3/18 郁豪 : 將orderDone 只留在 HasExecutingOrder 處理
                        }
                    }, { deep: true })
                */
            }


            // 訂閱
            mqttClient.subscribe(mqttTopic , function (err) {
                if (!err) {
                    console.log('MQTT 訂閱成功')
                    driverMqttOn = true
                }
            });

        }

        // 取得當前訂單狀態 (原 Cstatus )
        function interValGetExecutingOrderDetail(orderNo){

            if ( interValGetExecutingOrderDetail.interval ) {
                clearInterval(interValGetExecutingOrderDetail.interval)
            }

            function action(){
                Order.OrderRecordDetail({
                    OrderNo: orderNo
                }).then(res => {
                    ajax200Res(
                        res,
                        (res, data, msg)=>{
                            // 成功
                            if ( data ) {
                                store.commit('executingOrder/setData', {
                                    OrderRecordDetail: data,
                                    flowStatus: data.FlowStatus
                                })
                            }
                        },
                        (res, data, msg)=>{
                            // 例外失敗
                            if ( msg ) {
                                f7.dialog.alert(msg, "訊息提示", () => {
                                });
                            }
                        }
                    )
                })
            }

            action();

            interValGetExecutingOrderDetail.interval =  setInterval(()=> {
                action();
            }, 10000 /* 輪詢，10秒一次 */)
        }

        // 取得當前訂單狀態 (原 Cstatus )
        // function getExecutingOrderDetail(orderNo){

        //     function action(){
        //         Order.OrderRecordDetail({
        //             OrderNo: orderNo
        //         }).then(res => {
        //             ajax200Res(
        //                 res,
        //                 (res, data, msg)=>{
        //                     console.log('data.FlowStatus', data.FlowStatus)
        //                     // 成功
        //                     if ( data ) {
        //                         store.commit('executingOrder/setData', {
        //                             OrderRecordDetail: data,
        //                             flowStatus: data.FlowStatus
        //                         })
        //                     }
        //                 },
        //                 (res, data, msg)=>{
        //                     // 例外失敗
        //                     if ( msg ) {
        //                         f7.dialog.alert(msg, "訊息提示", () => {
        //                         });
        //                     }
        //                 }
        //             )
        //         })
        //     }

        //     action();
        // }

        function HasExecutingOrder(){
            return new Promise((resolve, reject)=>{
                Order.HasExecutingOrder().then((res)=>{
                    ajax200Res(
                        res,
                        (res, data, msg)=>{
                            // 成功
    
                            executingOrderNo = data.OrderNo
                            executingOrderId = data.OrderId
    
                            store.commit('executingOrder/setData', {
                                HasExecutingOrder: data
                            })
    
                            var ps1 = Contact.DriverInfo({
                                driverId: data.DriverId
                            }).then(res=>{
                                ajax200Res(
                                    res,
                                    (res, data, msg)=>{
                                        // 成功
                                        store.commit('executingOrder/setData', {
                                            DriverInfo: data.Data
                                        })
                                    },
                                    (res, data, msg)=>{
                                        // 例外失敗
                                        if ( msg ) {
                                            f7.dialog.alert(msg, "訊息提示", () => {
                                            });
                                        }
                                    }
                                )
                            }).catch(err => {
                                if ( err.response ) {
                                    const { status , data } = err.response;
                                    f7.dialog.alert(data.Message, "訊息提示", () => {
                                    });
                                }
                            })
                            
                            // 取得當前訂單後，拿到driver id，開始追蹤
                            DriverStatusMqtt();
    
                            // 取得當前執行訂單詳細資料
                            // interValGetExecutingOrderDetail(data.OrderNo);
                            var ps2 = getExecutingOrderDetail(data.OrderNo);
    
                            var ps3 = Order.DriverTotalScore({
                                driverId: data.DriverId
                            }).then(res=>{
                                ajax200Res(
                                    res,
                                    (res, data, msg)=>{
                                        // 成功
                                        store.commit('executingOrder/setData', {
                                            DriverTotalScore: data
                                        })
                                    },
                                    (res, data, msg)=>{
                                        // 例外失敗
                                        if ( msg ) {
                                            f7.dialog.alert(msg, "訊息提示", () => {
                                            });
                                        }
                                    }
                                )
                            })

                            resolve({
                                DriverInfo: ps1,
                                getExecutingOrderDetail: ps2,
                                DriverTotalScore: ps3,
                            })
                        },
                        (res, data, msg)=>{
                            // 例外失敗
                            if ( msg ) {
                                f7.dialog.alert(msg, "訊息提示", () => {
                                });
                            }

                            resolve()
                        },
                        ()=>{
                            // 無回傳值
                            orderDone()
                            resolve()
                        }
                    )
                }).catch(()=>{
                    resolve()
                })
            })
        }

        function gotIt(){
            f7.popup.close('#reach-target-popup')
        }

        function alertReached(orderNo, orderId){
            f7.popup.open('#reach-target-popup');

            $('#reach-target-popup').one('popup:close', ()=>{
                console.log('ride rate')
                f7.view.main.router.navigate('/ride_rate', {
                    props: {
                        orderNo: orderNo,
                        orderId: orderId,
                    }
                })
            })
        }

        // 有登入執行
        if ( isLogined ) {

            // 初進APP 執行一次 檢查訂單
            HasExecutingOrder();

            cData.UserInfoPs = store.dispatch('auth/getLatest')

            // 未讀訊息
            store.dispatch('notify/init')
        }


        onMounted(()=>{
            
            // 身障提示燈箱
            if ( cData.UserInfoPs.then ) {
                cData.UserInfoPs.then(()=>{
                    if ( cData.HandicappedStatusPopup ){
                        if ( cData.HandicappedStatusPopup.checkShow ) {
                            cData.HandicappedStatusPopup.checkShow()
                        }
                    }
                })
            }

            // APP 由背景程式回到使用中狀態
            $('#app').on('muki:enterForeground', function(e){
                // 每次由背景回到APP後，檢查有無進行中訂單
                HasExecutingOrder();
            })

            // APP 推播
            $('#app').on('muki:pushNotification', function(e){
                
                // 沒有登入，就不執行接收到推播的後續動作
                if ( !store.getters['auth/isLogined'] ) {
                    return;
                }
                
                const data = e.originalEvent.detail;
                const { NotifyType, PopupWindow, PopupWindowMessage, PopupWindowTitle } = data;

                console.log('muki:pushNotification', data)
                
                const uniPopupParam = {
                    closePopupOnBtnClick: true,
                    Title: data.PopupWindowTitle || "訊息提示",
                    Message: data.PopupWindowMessage,
                    OrderId: data.OrderId,
                    OrderNo: data.OrderNo,
                    NotifyActionType: data.NotifyActionType,
                    AppRedirectPage: data.AppRedirectPage,
                    WebViewUrl: data.WebViewUrl,
                    WebRedirectUrl: data.WebRedirectUrl,
                }

                // 如果是當前執行訂單，儲存資料
                if ( executingOrder.HasExecutingOrder ) {
                    if ( executingOrder.HasExecutingOrder.OrderNo === data.OrderNo ) {
                        store.commit('executingOrder/setData', {
                            notifyType: NotifyType
                        })
                    }
                }

                if ( NotifyType == 204 ) {
                    // 司機已抵達
                    
                    if ( PopupWindow  === 'true' ||  PopupWindow  === true ) {
                        appGloballNotifyPopup.open(uniPopupParam);
                    };

                    // 2022/7/18 客戶: 202、204、205直接導到地圖頁
                    if ( f7.view.main.router.url !== '/fleet_on_map' ) {
                        // 先取得最新資料再進入頁面
                        HasExecutingOrder().then(promiseObj=>{
                            if ( promiseObj && promiseObj.getExecutingOrderDetail ) {
                                promiseObj.getExecutingOrderDetail.then(()=>{
                                    f7.view.main.router.navigate('/fleet_on_map');
                                })
                            }
                        })
                    }

                } else if ( NotifyType == 207 ) {

                    // 任務司機結束
                    // 頁面不是評價頁時才跳轉
                    if ( !commonData.isAlertReached && f7.view.main.router.url !== '/ride_rate') {
                        
                        store.commit('setData', {
                            isAlertReached: true
                        });

                        // 跳轉評價頁
                        f7.view.main.router.navigate('/ride_rate', {
                            props: {
                                orderNo: data.OrderNo,
                                orderId: data.OrderId,
                            }
                        });

                    }

                } else if ( NotifyType == 202 ) {
                    // 司機前往中

                    if ( PopupWindow  === 'true' ||  PopupWindow  === true ) {
                        appGloballNotifyPopup.open(uniPopupParam);
                    };

                    // 2022/7/18 客戶: 202、204、205直接導到地圖頁
                    if ( f7.view.main.router.url !== '/fleet_on_map' ) {
                        // 先取得最新資料再進入頁面
                        HasExecutingOrder().then(promiseObj=>{
                            if ( promiseObj && promiseObj.getExecutingOrderDetail ) {
                                promiseObj.getExecutingOrderDetail.then(()=>{
                                    f7.view.main.router.navigate('/fleet_on_map');
                                })
                            }
                        })
                    } else {
                        // 觸發訂單檢查
                        HasExecutingOrder();
                    }


                }  else if ( NotifyType == 501 ) {
                    // 系統重複登入通知

                    appGloballNotifyPopup.open({
                        Title: "訊息提示",
                        Message: data.PopupWindowMessage,
                        showConfirmBtn: true,
                        closePopupOnBtnClick: true,
                        onClose(){
                            // 登出
                            store.dispatch("auth/logoutForce")
                        }
                    });

                } else {

                    // 其他訊息
                    if ( data.NotifyActionType == mukiConst.notifyActionType.WEBVIEW ) {

                        // webview - 點了推播後直接跳轉
                        if ( data.WebViewUrl ) {
                            f7.view.main.router.navigate('/common_webview',{
                                props:{
                                    pageTitle: data.PopupWindowTitle || '',
                                    src: data.WebViewUrl
                                }
                            });
                        }

                    } else {

                        // 其餘訊息
                        if ( PopupWindow  === 'true' ||  PopupWindow  === true ) {
                            appGloballNotifyPopup.open(uniPopupParam);
                        };

                        if ( NotifyType == 205 ){
                            // 2022/7/18 客戶: 202、204、205直接導到地圖頁
                            if ( f7.view.main.router.url !== '/fleet_on_map' ) {
                                // 先取得最新資料再進入頁面
                                HasExecutingOrder().then(promiseObj=>{
                                    if ( promiseObj && promiseObj.getExecutingOrderDetail ) {
                                        promiseObj.getExecutingOrderDetail.then(()=>{
                                            f7.view.main.router.navigate('/fleet_on_map');
                                        })
                                    }
                                })
                            }
                        }
                    }
                }

            })

            
            $$(document).on('page:beforein', function(e, page){
                if ( $(page.el).is('.p-home') && isLogined) {
                    // 回到首頁後，重設booking 資料
                    store.dispatch('booking/init');

                    // 3/14 客戶: 改由必要時機時才檢查：
                    // - 進入首頁
                    // - 收到司機執行推播
                    HasExecutingOrder();

                    // 身障提示燈箱
                    if ( cData.HandicappedStatusPopup ){
                        if ( cData.HandicappedStatusPopup.checkShow ) {
                            cData.HandicappedStatusPopup.checkShow()
                        }
                    }
                    
                }
            });

        })

        // ==== 全站司機狀態監聽 / ====


        // ==== 測試用 ==== 


        // 測試資料
        function runTest(){
            store.commit('executingOrder/setData', {
                OrderRecordDetail: {
                    OrderStartAddress: '臺中國際機場',
                    OrderStartLat: 24.236780000000003,
                    OrderStartLng: 120.59366000000001,

                    OrderTargetAddress: '朝陽科技大學',
                    OrderTargetLat: 24.068430000000003,
                    OrderTargetLng: 120.71508000000001,
                }
            })

            store.commit('executingOrder/setData', {
                DriverInfo: '王先生'
            })

            store.commit('executingOrder/setData', {
                DriverTotalScore: {
                    RatingCount: 5,
                    OrderCount: 100,
                }
            })

            var data = route2;
            var nowRIdx = 0
            var intv = setInterval(()=> {

                if ( nowRIdx >=data.length /*data.length*/ ) {
                    clearInterval(intv);
                }

                let nowR = data[nowRIdx];

                if ( nowR ) {
                    let cStatus = ''

                    if ( nowRIdx <= 29 ) {
                        cStatus = mukiConst.driverStatus.PROCEED
                    } else if ( nowRIdx > 29 && nowRIdx <= 333 ) {
                        cStatus = mukiConst.driverStatus.ARRIVALS
                    } else {
                        cStatus = mukiConst.driverStatus.PAYING
                    }

                    store.commit('executingOrder/setData', {
                        mqttPrev: {
                            gLat: executingOrder.mqtt.gLat,
                            gLng: executingOrder.mqtt.gLng,
                            gAngle: executingOrder.mqtt.gAngle,
                            CStatus: executingOrder.mqtt.CStatus
                        }
                    });

                    let angle = nowR.Angle ? nowR.Angle : 
                    executingOrder.mqttPrev.GLat ? MukiMap.getRotation(new google.maps.LatLng(executingOrder.mqttPrev.GLat, executingOrder.mqttPrev.GLng), new google.maps.LatLng(nowR.lat, nowR.lng)) : 0

                    store.commit('executingOrder/setData', {
                        mqtt: {
                            gLat: nowR.gLat,
                            gLng: nowR.gLng,
                            gAngle: angle,
                            CStatus: cStatus
                        }
                    });

                    nowRIdx++;
                }
            }, 500)
        }
        // runTest()

        function runTestMqtt(){
            store.commit('executingOrder/setData', {
                OrderRecordDetail: {
                    OrderStartAddress: '臺中國際機場',
                    OrderStartLat: 24.236780000000003,
                    OrderStartLong: 120.59366000000001,

                    OrderTargetAddress: '朝陽科技大學',
                    OrderTargetLat: 24.068430000000003,
                    OrderTargetLong: 120.71508000000001,
                }
            });

            store.commit('executingOrder/setData', {
                DriverInfo: '王先生'
            })

            store.commit('executingOrder/setData', {
                DriverTotalScore: {
                    RatingCount: 5,
                    OrderCount: 100,
                }
            })

            let mqttClient = mqtt.connect(mukiConst.mqtt.URL);
    
            mqttClient.on('connect', () => {
            });

            mqttClient.on('end', () => {
                console.log('connect end')
            });

            mqttClient.on('message', function (topic, message) {
                // message is Buffer
                let msg = JSON.parse(message.toString());

                let nowR = msg;
                console.log('msg',msg)

                if ( nowR ) {
                    let cStatus = ''

                    store.commit('executingOrder/setData', {
                        mqttPrev: {
                            gLat: executingOrder.mqtt.GLat,
                            gLng: executingOrder.mqtt.GLng,
                            gAngle: executingOrder.mqtt.GAngle,
                            CStatus: executingOrder.mqtt.CStatus
                        }
                    });

                    let angle = nowR.Angle ? nowR.Angle : 
                    executingOrder.mqttPrev.GLat ? MukiMap.getRotation(new google.maps.LatLng(executingOrder.mqttPrev.GLat, executingOrder.mqttPrev.GLng), new google.maps.LatLng(nowR.lat, nowR.lng)) : 0

                    store.commit('executingOrder/setData', {
                        mqtt: {
                            gLat: nowR.gLat,
                            gLng: nowR.gLng,
                            gAngle: nowR.gAngle,
                            CStatus: nowR.CStatus
                        }
                    });

                }
                
            })

            // === 測試用
            // === 測試用
            // mqttClient.subscribe(mukiConst.mqtt.TRACK_SEND + 'test/1234', function (err) {
            //     if (!err) {
            //         console.log('MQTT 訂閱成功')
            //     }
            // }); 

            mqttClient.subscribe('test/1234', function (err) {
                if (!err) {
                    console.log('MQTT 訂閱成功')
                }
            }); 

        }

        // runTest()
        // runTestMqtt()
        // 測試資料 /

        if ( process.env.NODE_ENV === 'development' ) {
            window.store = store
        }

        // function debuger(){
        //     if ( (location.hostname === '192.168.0.170' || location.hostname === 'itaxi-develop.muki001.com') && store.state.auth.UserName === '0922233311') {
        //         let mqttTopic = mukiConst.mqtt.CONTACT_SEND + 'mukiDebug';
        //         let mqttClient = mqtt.connect(mukiConst.mqtt.URL);
                
        //         mqttClient.on('message', function (topic, message) {
        //             // message is Buffer
        //             console.log('msg', eval(message.toString()))
        //         })
    
        //         mqttClient.subscribe(mqttTopic , function (err) {
        //             if (!err) {
        //                 console.log('MQTT 訂閱成功 2')
        //             }
        //         });
        //     }
        // }

        // debuger();
        
        // ==== 測試用 / ==== 


        // ==== APP共用 ==== 


        function setAppGlobalNotifyPopup(instance){
            window.appGloballNotifyPopup = instance;
        }

        // 手機原生虛擬返回鍵綁定
        function indexBackAction(){
            console.log('indexBackAction')
            muki_back_action(2, function(){
                console.log('indexBackAction success')
                if ( $('.page-current').is('[router-back]') ) {
                    f7.view.main.router.back();
                } else {
                    m_back_page()
                }
            })
        }
        function inpageBackAction(){
            console.log('inpageBackAction')
            muki_back_action(1, function(){
                console.log('inpageBackAction success')
                if ( $('.page-current').is('[router-back]') ) {
                    f7.view.main.router.back();
                } else {
                    m_back_page()
                }
            })
        }

        // 手機原生鍵盤事件綁定
        // registeSoftKeyBoardCloseCallback(()=>{
        //     $('#app').trigger('muki:softKeyboard:close')
        //     $('.page-current').trigger('muki:softKeyboard:close')
        // })

        onMounted(()=>{

            // 初始化
            clearNoBack();

            f7ready(()=>{

                // Option 1. Using one 'page:init' handler for all pages
                $$(document).on('page:beforein', function (e) {
                    // 虛擬反回
                    if (f7.view.current.router.currentRoute.path == '/'){
                        indexBackAction();
                    } else {
                        inpageBackAction();
                    }
                })

                // Option 1. Using one 'page:init' handler for all pages
                $$(document).on('page:afterin', function (e) {
                    // 檢查noBack
                    checkNoBack()
                })

            })
        })
        indexBackAction()

        // 取得全域 locale 實體
        const { t, locale } = useI18n()
        locale.value =  store.state.locale || mukiConst.locale['TW']

        // Framework7 Parameters
        const f7params = {
            name: "trueway", // App name
            theme: "auto", // Automatic theme detection
            // App store
            store: store,
            // App routes
            routes: routes,
            touch: {
                tapHold: true, //enable tap hold events,
                // Framework7 Material Theme comes with great an dynamic implementation of Material Touch Ripple effect.
                // It is enabled by default for MD theme.
                // However, you may disable it by passing ouch.mdTouchRipple:false
                mdTouchRipple: false,
            },
            navbar: {
                iosCenterTitle: true,
                mdCenterTitle: true,
                auroraCenterTitle: true,
            },
            view: {
                // 打開 broweerHistory 在需要back page時可能會有問題
                // 故僅用於開發
                // browserHistory: process.env.APP_ENV === 'development' ? true:false,
                browserHistory: false,
                xhrCache: false,
            },
            // sheet
            sheet: {
                backdrop: true,
            },
            // picker
            picker: {
                renderToolbar: function () {
                    return '<div class="toolbar">' +
                    '<div class="toolbar-inner">' +
                    '<div class="left">' +
                    '</div>' +
                    '<div class="right">' +
                    '<a href="#" class="link sheet-close popover-close reset">完成</a>' +
                    '</div>' +
                    '</div>' +
                    '</div>'
                }
            },
            calendar:{
                locale: 'zh-TW',
                dateFormat:{
                    month: 'long',
                    day: 'numeric'
                }
            },
            photoBrowser:{
                theme: 'dark',
                popupCloseLinkText: t('photoBrowser.bannerPhotoBrowser')
            },
            dialog:{
                buttonOk: t('dialog.buttonOk'),
                buttonCancel: t('dialog.buttonCancel'),
            }
        };

        // 自動計算基礎px
        const computeRatioOfBaseSize = () => {
            const initSize = 14;
            const htmlEl = document.querySelector("html");
            const windowWidth =
                htmlEl.clientWidth > 768 ? 768 : htmlEl.clientWidth;
            let baseSize = (Number(windowWidth) / 375) * initSize;

            if ( baseSize > 20) baseSize = 20;
            if ( baseSize < 14) baseSize = 14;

            document.documentElement.style.setProperty('--f7-font-size', baseSize + 'px');
            document.documentElement.style.setProperty('--rem', baseSize);
            htmlEl.style.fontSize = baseSize + "px";
        };

        function updateViewSizeAttr(){
            document.documentElement.style.setProperty('--view-width', window.innerWidth + 'px');
        }

        function cssEnv(){
            var sfeExtraTop = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--f7-safe-area-top"));
            sfeExtraTop = sfeExtraTop || 0;

            // if ( sfeExtraTop > 8 ) {
            //     document.documentElement.style.setProperty('--navbar-extra-safe-top', 8 + 'px');
            // } else {
            // }
            
            // navbar 內容是垂直置中。假設多了10px高，昨會需要多把內容往下推5px。以置中
            document.documentElement.style.setProperty('--navbar-extra-safe-top', (sfeExtraTop * 0.5) + 'px');
        }

        // 滑動偵測
        const ScrollDetect = function(){
            $$(document).on('page:afterin', function (e, page) {
                $('body').removeClass('is-scrolling')

                $('.view-main .page-content').on('scroll', (e)=>{
                    if ( e.target.scrollTop > 20) {
                    $('body').addClass('is-scrolling')
                    } else {
                    $('body').removeClass('is-scrolling')
                    }
                })
            });

            $('.view-main .page-content').on('scroll', (e)=>{
                if ( e.target.scrollTop > 20) {
                $('body').addClass('is-scrolling')
                } else {
                $('body').removeClass('is-scrolling')
                }
            })
        }

        // segmented tab
        const SegmentedTab = {
            setActive: function(tabLink){
                // f7 內建 不會移動 tab inner，導致選到的 tab link有可能會部分被擋住，故採用自行移動方式
                var that = this;
                var tabLink = $(tabLink)

                if ( !tabLink.length ) return;

                var toolbarInner = tabLink.closest('.toolbar-inner');
                var toolbarInnerW = toolbarInner[0].clientWidth
                var currentScrollLeft = toolbarInner[0].scrollLeft;
                var tabLinkPos = tabLink.position();
                var diff = (tabLink[0].offsetLeft + tabLink[0].clientWidth) - (currentScrollLeft + toolbarInnerW);
                var target = currentScrollLeft;

                if ( tabLinkPos.left < 0 ) {
                    // btn 太左邊
                    target = currentScrollLeft + tabLinkPos.left
                } else if ( diff > 0 ) {
                    // btn 太右邊
                    target = currentScrollLeft + diff;
                }

                toolbarInner.animate({
                    scrollLeft: target
                }, 300)

                that.updateSegmented(tabLink)
            },
            updateSegmented: function(tabLink, animate) {
                // f7 內建 移動 segement 的位置會有誤差，故改採自行修改位置的方式
                var tabLink = $(tabLink)
                

                if (!tabLink.length) return;

                var toolbarInner = tabLink.closest('.toolbar-inner');
                var tabLinkW = tabLink.outerWidth();
                var segEl = toolbarInner.find('.tab-link-bg-hilighter');

                if ( animate === false) {
                    segEl.css('transition', 'none')   
                }

                segEl.css('width', tabLinkW);
                segEl.css('left', tabLink.position().left + parseFloat(tabLink.css('margin-left')) + toolbarInner[0].scrollLeft)

                if ( animate === false) {
                    segEl.css('transition', '')   
                }
            },
            init: function(){
                var that = this;

                $('body').on('click', '.tab-link', function(e){
                    that.setActive(e.currentTarget)
                })

                $$(document).on('page:beforein', function (e, page) {
                    $('.muki-tabbar .tab-link-active').each(function(idx, el){
                        that.updateSegmented($(el), false)
                    })
                })
            }
        }


        // 切換語系
        const switchLanguage = (e) => {
            if ( e.target.checked ) {
                // 中文
                store.commit('updateLocale',  mukiConst.locale.TW )
            } else {
                // 英文
                store.commit('updateLocale',  mukiConst.locale.EN )
            }
        }

        // currentPath
        const currentPath = ref(null);

        onMounted(() => {


            // 告訴原生程式 app js已經準備就緒
            appReady();

            computeRatioOfBaseSize();
            updateViewSizeAttr();

            $('body').on('muki:softKeyboard:open', '.page-current', (e, data)=> {
                $('html').addClass('is-softkeyboard-open')
                document.documentElement.style.setProperty('--softkeyboard-height', data.keyboardHeight + 'px');
            })

            $('body').on('muki:softKeyboard:close', '.page-current', ()=> {
                $('html').removeClass('is-softkeyboard-open')
            })

            f7ready(()=>{
                nextTick(()=>{
                    setTimeout(()=>{
                        // css env 剛進頁面尚無法取得
                        cssEnv()
                    }, 2000)
                })
            })

            window.addEventListener("resize", computeRatioOfBaseSize);
            window.addEventListener("resize", updateViewSizeAttr);

            ScrollDetect();

            SegmentedTab.init();

            // ==== PAGE BEFORE IN 頁面事件 ====
            $$(document).on('page:beforein', function (e, page) {

                if ( f7.view.current ) {
                    var toolbarPath = f7.view.current.router.currentRoute.path;
                    currentPath.value = toolbarPath
                }
            })

            $$(document).on('page:afterin', function (e, page) {
                

                /**
                 * 
                 * 2022/4/7 :
                 * 
                 * 白雞午安，愛接送目前有個問題想麻煩你幫我看看:
                 * 連續點開頁面、app內視窗後，window.JS會偶發變成空的，導致後續的原聲呼叫會報錯
                 * 
                 * 2022/4/8 白雞 :
                 * 
                 * 這個 window.JS 為空的問題
                 * 目前還是沒辦法完全解決
                 * 不過觸發的機率似乎有些降低?
                 * 
                 * 不確定到底哪個環節出現問題
                 * 好像快速操作 + 多開WebView 的情況比較容易出現
                 * 有時候怎麼測就測不到
                 * 有時候就會跳出來 =~=
                 * 
                 * 正常操作情況應該不太容易遇到此問題
                 * 在解決此問題之前
                 * 
                 * 若是偵測到 window.JS 為空
                 * 看是否可以寫一個重整頁面的操作
                 * 或許就能恢復正常了
                 * 
                 */
                // 確認為APP環境
                if ( _deviceType() === 1 || _deviceType() === 2 ) {

                    if ( page.view.router.currentRoute.path == '/member_center' ) {

                        if ( window.JS && Object.keys(window.JS).length ) {
                            
                            mukiStore.removeItem('windowJSRefresh', ()=> {
                            })

                        } else {
                            
                            mukiStore.getItem('windowJSRefresh', (err, value) => {
                                if ( !value ) {
                                    // 目前只有 member_center 有出現偶發 window.JS 為空的狀況，故防呆
                                    console.log('====== window.JS 已空 =====', page.view.router.currentRoute.path)

                                    // 避免無窮迴圈
                                    mukiStore.setItem('windowJSRefresh', true ,()=> {
                                        m_refresh_page('/member_center')
                                    })
                                }
                            })
                        }

                    }

                }

                
            })

            setTimeout(()=>{
                console.log('環境變數 safe-area-top :', getComputedStyle(document.documentElement).getPropertyValue("--f7-safe-area-top"))
                console.log('自訂環境變數 --android-safe-area-inset-top :', getComputedStyle(document.documentElement).getPropertyValue("--android-safe-area-inset-top"))
            }, 3000)
        });

        // ==== APP共用 (END) ==== 

        return {
            mukiConst,
            cData,
            store,
            f7params,
            setNoBack,
            m_refresh_page,
            m_direct_page,
            currentPath,
            notifyData,
            gotIt,
            alertReached,
            executingOrder,
            setAppGlobalNotifyPopup
        };
    },
};
</script>

<style lang="scss">
@import "@design";

// html, body{
//     max-height: 667px; height: 667px;
// }

.ios, .md{
    .popup-cart{
        &.popup-sty-modal{
            --popup-width: 345px;
        }
    }
}

.cart-info-card{
    background: #fff;
    border-radius: 15px;
    border: 2px solid $color-primary;
    margin-bottom: 20px;
    overflow: hidden;

    &.sty-2{
        border-color: $color-sec;
        .card-main{
            background: $color-sec;
        }
    }

    .card-main{
        @include flex(center,center);
        background: $color-primary;
        padding: 20px;
        .card-title{
            font-size: 22px;
            letter-spacing: 0.1em;
            line-height: 1.3636;
            color:#fff;
            margin-right: 1.3636em;
            font-weight: bold;
        }
    }
    .card-foot{
        padding: 20px;
        text-align: center;
        .button{
            font-weight: bold;
        }
    }
}

.popup-showcase{
    .showcase-box{
        margin-bottom: 20px;
    }
}

</style>
