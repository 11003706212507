<template>
    <div
        class="popup popup-tablet-fullscreen sty-modal sty-dialog2" id=""
        :style="{
            '--popup-width': width ? width : null
        }"
    >
        <div class="view">
            <div class="page">
                <div class="navbar">
                    <div class="navbar-inner">
                        <div class="left">
                        </div>
                        <div class="title"></div>
                        <div class="right">
                        </div>
                    </div>
                </div>
                <div class="page-content popup-modal-centered">
                    <div class="page-inner">
                        <div class="popup-modal-content">
                            <div class="popup-dialog-head" v-if="$slots.head">
                                <slot name="head">
                                </slot>
                            </div>
                            <slot name="default">
                            </slot>
                            <div class="popup-grid-foot" v-if="$slots.foot">
                                <slot name="foot">
                                    <f7-row>
                                        <f7-col width="100">
                                            <BaseButton
                                                pill
                                                class="btn-theme dialog-popup-btn"
                                            >
                                                按鈕文字
                                            </BaseButton>
                                        </f7-col>
                                    </f7-row>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        noHead: Boolean,
        width: [String],
    },
    setup(props, {emit, slots}){
        
        return {
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.popup.sty-modal.sty-dialog2{
    --popup-width: 340px;

    @include media-up(375){
        --popup-width: #{rwd-val(375px, 340px, 768px, 555px)};
    }
    @include media-up(768){
        --popup-width: 555px;
    }

    .dialog-popup-inner{
        padding: 20px;
    }

    .dialog-popup-title{
        font-size: calc(20 / 14 * 1rem);
        letter-spacing: 0.1em;
        color: var(--color-theme);
        margin-bottom: 1em;
        text-align: center;
        font-weight: bold;
    }

    .dialog-popup-desc{
        font-size: calc(18 / 14 * 1rem);
        color: #666666;
        line-height: calc(30 / 18);
        @include letter-spacing-center(0.05em);
        margin-bottom: 1rem;
    }

    .dialog-popup-btn{
        --f7-button-height: 42px;
        font-size: calc(20 / 14 * 1rem);
        @include letter-spacing-center(calc(5 / 20 * 1em));
        font-weight: bold;
        font-family: $ff-msjh;
    }
}


</style>



