const CONST_BASE = {
    APP_VERSION: '2.6.1',
    CLIENT_ID: 'GovPassengerAppTwo',
    CLIENT_SECRET: 'TMS@GovPassengerApp',
    PUSH_KEY_NUMBER: 'dXxvXvC8SVU:APA91bHQUb68C8q_l8kTN_Qoj0O1RVUsCKA4DXc4tBnIW9bUL_kUSaNaBPViUzmi3wmPRmSU0mYs7EV4WcUpalryDefMwT8pe4R8RGTF8zSe8u8FViiiDN7rqx0BTCChd7_icqZPj12G',
    GCP_API_KEY_ANDROID: 'AIzaSyAhaz7SZjX6vOkbEpGa45dVQBY11hejhMM',
    GCP_API_KEY_IOS: 'AIzaSyDC28FfjRH5qzCgZ9Udaa4W8ytO_aUJjIc',
    GCP_API_KEY_TEST: 'AIzaSyDFMcucvSpjcTq7jrXSFmJmX7rxD-ZWg9A',
    GCP_API_KEY_ANDROID_TEST: 'AIzaSyAcjzaVwKIIFjQTHjVolp5hD-Hhue4-fGk',
    GCP_API_KEY_IOS_TEST: 'AIzaSyC8gjNJG1q5lxah6pL2oGIUWA45EpxzQ-s',

    // 地圖最後fallback預設地址 - 台北市
    map:{
        fallbackCenter:{
            LAT: 25.09108,
            LNG: 121.5598
        }
    },
    
    // 身障資格
    HANDICAPPED: 2,

    notifyActionType:{
        NO_ACTION: 0,
        APP_PAGE: 1,
        WEBVIEW: 2,
        BROWSER: 3,
    },
    // 推播類別
    notifyType:{
        // 前往
        PROCEED: '202',
        // 到達
        ARRIVALS: '204',
        // 上車
        GOTON: '205',
        // 下車
        GOTOFF: '206',
        // 完成結帳
        PAYING: '207',
    },
    // 訂單(Order)進度
    flowStatus: {
        // 建立
        CREATE: 0,
        // 前往
        PROCEED: 1,
        // 到達
        ARRIVALS: 2,
        // 上車
        GOTON: 3,
        // 下車
        GOTOFF: 4,
        // 已結帳
        PAYING: 5,
    },
    driverStatus: {
        // 空車
        EMPTY: 0,
        // 載客
        CARRY_PASSANGERS: 1,
        // 前往
        PROCEED: 2,
        // 到達
        ARRIVALS: 8,
        // 付款
        PAYING: 6,
    },
    // 訂單來源為app
    bookingFrom: {
        APP: 0
    },
    handicappedVertifyStatus: {
        // 未填寫
        UNFILLED: 0,
        // 驗證中
        UNDER_REVIEW: 1,
        // 是
        YES: 2,
        // 否
        NO: 3,
    },
    // 付款類型
    payType: {
        // 現在金
        CASH: 0,
        // 愛心卡
        LOVE_CARD: 4,
    },
    addressType:{
        // 家
        HOME: 0,
        // 公司
        COMPANY: 1,
        // 常用
        COMMON: 3
    },
    // 語系
    locale:{
        TW: 'tw',
        EN: 'en'
    },
    // 性別
    gender: {
        FEMALE: 'F',
        MALE: 'M',
        EMPTY: "O"
    },
    conditions: [
        {
            TypeId: 0,
            TypeName: "有輪椅",
            Note: ""
        },
        {
            TypeId: 1,
            TypeName: "輪椅較大",
            Note: "高度100公分, 寬度70公分"
        }
    ],
    // 發票
    invoiceType: {
        // 2聯
        DUPLICATE_UNIFORM_INVOICE: 1,
        // 3聯
        TRIPLICATE_UNIFORM_INVOICE: 2,
        // 捐贈
        DONATE: 3,
    },
    // 付款方式
    paymentMethod: {
        // 刷卡
        CARD: 0,
        // LINE PAY
        LINE_PAY: 1,
        // 匯款
        REMIT: 2,
        // 點數
        POINT: 3,
    },
    // 優惠券
    coupon:{
        category: {
            // 未使用
            UNUSED: 0,
            // 已使用
            USED: 1,
            // 已過期
            EXPIRED: 2,
        },
        applyTo:{
            // 門市/ APP使用
            STORE_AND_APP: 0,
            // 指定商品適用
            SPECIFIC_ITEM: 1
        },
        // 是否適用於購物車內商品
        canUseCheckout:{
            NO: 0,
            YES: 1,
        }
    },
    // 地點
    location:{
        // 上車地點
        START: 0,
        // 下車地點
        END: 1,
    },


    // 驗證
    csrToken:{
        INVALID: -99,
    },
    token:{
        INVALID: -98,
    },
}

const CONST_PROD = {
    BASE_URL: 'https://itaxi.iot.gov.tw/PassengerApi/api/',
    webview: {
        BASE_URL: 'https://itaxi.iot.gov.tw/client/'
    }, 
    INDEX_URL: 'https://itaxi.iot.gov.tw/PassengerApi/client/',
    // mqtt
    mqtt: {
        URL: 'wss://itaxi.iot.gov.tw:1884/mqtt',
        TRACK_SEND: 'SAMS/Track/Send/',
        CONTACT_READ: 'SAMS/Contact/Read/',
        CONTACT_SEND: 'SAMS/Contact/Send/',
        DISPATCH_SEND: 'SAMS/Dispatch/Send/',
        DISPATCH_RECEIVE: 'SAMS/Dispatch/Receive/',
    },
}

const CONST_DEV = {
    BASE_URL: 'http://60.199.131.69:8016/api/',
    webview: {
        BASE_URL: 'http://60.199.131.69:8021/'
    },
    INDEX_URL: '/',
    // mqtt
    mqtt: {
        URL: 'ws://60.199.131.69:8099/mqtt',
        TRACK_SEND: 'AirPort/Track/Send/',
        CONTACT_READ: 'AirPort/Contact/Read/',
        CONTACT_SEND: 'AirPort/Contact/Send/',
        DISPATCH_SEND: 'AirPort/Dispatch/Send/',
        DISPATCH_RECEIVE: 'AirPort/Dispatch/Receive/',
    }, 
}

const MQTT_PROD = {
    username: "sams",
    password: "admin@sams",
}

const MQTT_DEV = {
    username: "airportapp",
    password: "admin@airport",
}

const CONST_MERGED_PROD = $.extend(true, {}, CONST_BASE, CONST_PROD);
const CONST_MERGED_DEV = $.extend(true, {}, CONST_BASE, CONST_DEV);

let CONST;
let MQTT_LOGIN_CONFIG


if ( location.host === 'itaxi.iot.gov.tw' ) {
    // 正式站，指定為正式參數
    console.log('當前使用正式環境參數');

    CONST = CONST_MERGED_PROD;
    MQTT_LOGIN_CONFIG = MQTT_PROD;

    if ( _deviceType() === 1 ) {
        CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID
    } else if ( _deviceType() === 2 ) {
        CONST.GCP_API_KEY = CONST.GCP_API_KEY_IOS
    } else {
        console.log('無法判斷為 IOS 或 Android');
        CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID
    }

} else {

    // TODO 更正式站時，把 後者的 CONST_MERGED_DEV 改成 CONST_MERGED_PROD
    CONST = process.env.NODE_ENV == 'development' ? CONST_MERGED_DEV :  CONST_MERGED_DEV;

    if ( process.env.APP_ENV == 'development' ) {

        // 開發環境
        // CONST.GCP_API_KEY = CONST.GCP_API_KEY_TEST
        if ( _deviceType() === 1 ) {
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID_TEST
        } else if ( _deviceType() === 2 ) {
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_IOS_TEST
        } else {
            console.log('無法判斷為 IOS 或 Android');
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID_TEST
        }
        MQTT_LOGIN_CONFIG = MQTT_DEV
        
    } else {

        // 正式環境
        // if ( _deviceType() === 1 ) {
        //     CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID
        // } else if ( _deviceType() === 2 ) {
        //     CONST.GCP_API_KEY = CONST.GCP_API_KEY_IOS
        // } else {
        //     console.log('無法判斷為 IOS 或 Android');
        //     CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID
        // }
        // MQTT_LOGIN_CONFIG = MQTT_PROD

        // CONST.GCP_API_KEY = CONST.GCP_API_KEY_TEST
        if ( _deviceType() === 1 ) {
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID_TEST
        } else if ( _deviceType() === 2 ) {
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_IOS_TEST
        } else {
            console.log('無法判斷為 IOS 或 Android');
            CONST.GCP_API_KEY = CONST.GCP_API_KEY_ANDROID_TEST
        }
        MQTT_LOGIN_CONFIG = MQTT_DEV
    }
}

console.log('CONST.APP_ENV', process.env.APP_ENV)

export {
    CONST, 
    MQTT_LOGIN_CONFIG
};