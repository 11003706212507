

<template>
    <div 
        ref="refRoot"
        class="map-panel map-page-content" 
        :style="{
            '--map-height' : h ? h : null
        }"
    >
        <div class="map"></div>
        <div class="map-custom-control my-location" v-show="customBtnMyLocation" @click="onMyLocation()" style="position:absolute;">
            <i class="icon-gps_fixed"></i>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { checkResources, checkGps } from '@functions'
export default {
    props:{
        setting: Object,
        noAutoCenter: Boolean,
        // 高度
        h: [String],

        // custom control
        customBtnMyLocation: Boolean
        // custom control /
    },
    setup(props, {emit}){
        var map;
        const refRoot = ref(null);

        const init = async ()=>{

            await checkResources(()=> typeof google !== 'undefined' , 'https://maps.googleapis.com/maps/api/js?key='+mukiConst.GCP_API_KEY+'&region=TW&language=zh-TW&libraries=geometry,places')
            await checkResources(()=> typeof GoogleMap !== 'undefined' , 'assets/js/libs/muki_map.js');

            var setting = setting || {};

            var $mapGoogle = {};
            var config = {
                element: '.map',
                zoom: 14,
                disableDefaultUI : true,
                // 禁址使用者點及地圖上的地名後，跳出google map 內建 popup
                clickableIcons: false,
                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                        {
                            "visibility": "off"
                        }
                        ]
                    }
                ],
            };

            var newConfig = $.extend(true, {}, config, props.setting);
            var mapEl = document.querySelector(newConfig.element);
            map = new google.maps.Map(mapEl, newConfig);
            
            // google map
            google.maps.event.addListenerOnce(map ,"idle", ()=> {
                // 沒有設定中心，預設當前位置
                if ( !newConfig.center && !props.noAutoCenter) {
                    myLocation()
                } else {

                    if ( newConfig && newConfig.center ) {
                        if ( 
                            newConfig.center.lat !== undefined &&
                            newConfig.center.lat !== '' &&
                            newConfig.center.lng !== undefined &&
                            newConfig.center.lng !== ''
                        ) {
                            map.setCenter({
                                lat: newConfig.center.lat,
                                lng: newConfig.center.lng,
                            })
                        }
                    }
                }
                emit('init', {
                    $mapGoogle,
                    map,
                })
            })
        }

        function getLocation(){
            _get_location((res)=>{
                let lat = res.latitude;
                let lng = res.longitude;


                lat = lat ? parseFloat(lat) : '';
                lng = lng ? parseFloat(lng) : '';

                if ( lat && lng ) {
                    
                    map.panTo({lat: lat, lng: lng});
                    map.setZoom(14)
                    emit('myLocation', {
                        lat: lat,
                        lng: lng
                    })
                    
                } else {
                    // 沒有取得，最後使用預設值
                    const finallFallBackLatLng = {
                        lat: mukiConst.map.fallbackCenter.LAT,
                        lng: mukiConst.map.fallbackCenter.LNG,
                    }

                    map.setCenter(finallFallBackLatLng);
                    getAddress(geocoder, finallFallBackLatLng)
                    saveLatLng(finallFallBackLatLng)
                }
            })
        }

        function myLocation(){
            checkGps().then(()=>{
                console.log('取得GPS確認成功 3')
                getLocation()
            }).catch(()=>{
                console.log('GPS失敗')
                getLocation()
            })
        }

        // 我的位置
        function onMyLocation(){
            myLocation()
        }


        onMounted(()=>{
            // 初進頁面生成畫面
            init();


        });

        return {
            onMyLocation,
            refRoot
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.map-panel{
    --map-height: 100vh;
    .map{
        height: var(--map-height)!important;
    }

    .map-custom-control{
        &.my-location{
            width: 40px;
            height: 40px;
            background: #fff;

            position: absolute;
            right: 10px;
            bottom: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom));
            @include flex(center, center);
            box-shadow: rgba(#000, 0.3) 0px 1px 4px -1px;
            z-index: 5;
            i {
                font-size: 22px;
            }
        }
    }
}


</style>
