import axios from "axios";
// import { f7 } from "framework7-vue/cjs/shared/f7";
import { f7, f7ready } from "framework7-vue";
import store from '../../store/store';
import { CONST as mukiConst } from "@/js/const.js";

// 驗證
const token = localStorage.getItem('Token');
axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const refTable = {

}

const apiBase = axios.create({
    baseURL: process.env.NODE_ENV == 'development' ? "/api/" : mukiConst.BASE_URL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
    },
    transformResponse: [
        function(res){
            if ( res ) {
                return JSON.parse(res)
            } else {
                return res
            }
        }
    ],
});


// apiBase.interceptors.request.use(function (config) {
//     const url = config.url;

//     refTable[url] = performance.now();

//     return config;
// })

// apiBase.interceptors.response.use(function (config) {
//     const url = config.config.url;
    
//     console.log(`==== response Time ${url}===`,  performance.now() - refTable[url],);

//     refTable[url] = '';

//     return config;
// })


const setApiMode = (apiBase, mode) => {
    if ( mode === 'dev' ) {
        apiBase.defaults.baseURL = "/mockWebApi/";
    }
    return apiBase;
}

function baseGet(url, formInfo, mode) {
    var param = formInfo ? `?${new URLSearchParams(formInfo).toString()}` : '';
    return setApiMode(apiBase, mode).get(url + param, {cancelToken: source.token})
}

function basePost(url, formInfo, mode) {
    return setApiMode(apiBase, mode).post(url, formInfo, {cancelToken: source.token})
}

function basePut(url, formInfo, mode) {
    return setApiMode(apiBase, mode).put(url, formInfo, {cancelToken: source.token})
}

function basePatch(url, formInfo, mode) {
    return setApiMode(apiBase, mode).patch(url, formInfo, {cancelToken: source.token})
}

function baseDel(url, formInfo, mode) {
    return setApiMode(apiBase, mode).delete(url, {data: formInfo}, {cancelToken: source.token})
}

// 加入收藏
function addToCollection(formInfo, mode) {
    var promise = {}
    var url = '/api_product/add_product_to_collect';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 加入收藏
function removeFromCollection(formInfo, mode) {
    var promise = {}
    var url = '/api_product/remove_product_from_collect';

    // formInfo = {
    //     "product_id": 1,
    //     "product_spec_id": 2
    // }

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 行動超市加入收藏
function mobileStoreAddToCollection(formInfo, mode){
    var promise = {}
    var url = '/api_member/add_mobile_store_to_collect';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 行動超市移出收藏
function mobileStoreRemoveFromCollection(formInfo, mode) {
    var promise = {}
    var url = '/api_member/remove_mobile_store_from_collect';

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 加入購物車
function addToCart(formInfo, mode) {
    var promise = {}
    var url = '/shopping_cart/cart_items_process';

    var info = {
        "product_id": null,
        "product_spec_id": null,
        "amount": 1,
        "action_type": 'change_addition',
    }
    var formInfo = Object.assign({}, info, formInfo);

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 修改購物車數量
function alterCart(formInfo, mode) {
    var promise = {}
    var url = '/shopping_cart/cart_items_process';
    var info = {
        "product_id": null,
        "product_spec_id": null,
        "amount": null,
        "action_type": 'change_addition',
    }
    var formInfo = Object.assign({}, info, formInfo);

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 移出購物車
function removeFromCart(formInfo, mode) {
    var promise = {}
    var url = '/api_product/remove_product_from_cart';

    var info = {
        "product_id": null,
        "product_spec_id": null,
    }
    var formInfo = Object.assign({}, info, formInfo);

    promise = setApiMode(apiBase, mode).post(url, formInfo)

    return promise.then((res) => {
        const { data, status } = res;
        const resData = data['res_data']

        if (status === 200 && data["res_code"] === 1) {
            return res;
        } else {
            f7.dialog.alert(data["res_content"], "訊息提示");
        }

        return res;
    })
}

// 信件
const Mail = {
    detailed(formInfo, mode){
        return basePost('/api_mail/mail_detailed', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    read(formInfo, mode) {
        return basePost('/api_mail/mail_read', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    delete(formInfo, mode) {
        return basePost('/api_mail/mail_delete', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}

// 產品
const Product = {
    // 行動超市
    mobileStore:{
        // 產品分類
        category(formInfo, mode) {
            return basePost('/api_product/mobile_store_product_category', formInfo, mode).then(res => {
                const { data, status } = res;
                const resData = data['res_data'];
                if (status === 200 && data["res_code"] === 1) {
                } else {
                    f7.dialog.alert(data["res_content"], "訊息提示", () => {
                    });
                };
                return res;
            })
        },
    },
    // 線上預購
    onlinePreorder:{
        // 產品分類
        category(formInfo, mode) {
            return basePost('/api_product/online_preorder_product_category', formInfo, mode).then(res => {
                const { data, status } = res;
                const resData = data['res_data'];
                if (status === 200 && data["res_code"] === 1) {
                } else {
                    f7.dialog.alert(data["res_content"], "訊息提示", () => {
                    });
                };
                return res;
            })
        },
        // 列表
        list(formInfo, mode) {
            return basePost('/api_product/online_preorder_product_list', formInfo, mode).then(res => {
                const { data, status } = res;
                const resData = data['res_data'];
                if (status === 200 && data["res_code"] === 1) {
                } else {
                    f7.dialog.alert(data["res_content"], "訊息提示", () => {
                    });
                };
                return res;
            })
        },
    },
    // 分類
    category(formInfo, mode) {
        return basePost('/api_product/product_category', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 列表
    list(formInfo, mode) {
        return basePost('/api_product/product_list', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 詳細
    detailed(formInfo, mode) {
        return basePost('/api_product/product_detailed', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 加入收藏
    addToCollection(formInfo, mode) {
        return basePost('/api_product/add_product_to_collect', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 取消收藏
    removeFromCollection(formInfo, mode) {
        return basePost('/api_product/remove_product_from_collect', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 加入購物車
    addToCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
            "amount": 1,
            "action_type": 'change_addition',
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/shopping_cart/cart_items_process', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 修改購物車數量
    AlterCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
            "amount": null,
            "action_type": 'change_rewrite',
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/shopping_cart/cart_items_process', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 移出購物車
    removeFromCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/shopping_cart/remove_product_from_cart', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}


// 購物車
const Cart = {
    // 購物車產品數量
    cartNum(formInfo, mode) {
        return basePost('/shopping_cart/current_cart_num', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}

// 生活提案
const Proposal = {
    // 分類
    category(formInfo, mode) {
        return basePost('/api_proposal/proposal_category', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 列表
    list(formInfo, mode) {
        return basePost('/api_proposal/proposal_list', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 詳細
    detailed(formInfo, mode) {
        return basePost('/api_proposal/proposal_detailed', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}


// 門市
const Store = {
    // 分類
    category(formInfo, mode) {
        return basePost('/api_store/store_category', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 列表
    list(formInfo, mode) {
        return basePost('/api_store/store_list', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}


// 最新消息
const News = {
    // 列表
    list(formInfo, mode) {
        return basePost('/api_news/news_list', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
    // 列表
    detailed(formInfo, mode) {
        return basePost('/api_news/news_detailed', formInfo, mode).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}


// 會員
const Member = {
    // 列表
    info(formInfo, mode) {
        return basePost('/api_member/info', formInfo, mode).then(res => {
            const { Data, Message, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                f7.dialog.alert(data["res_content"], "訊息提示", () => {
                });
            };
            return res;
        })
    },
}

// 驗證
const Verify = {
    GetPhoneVerifyCode(formInfo, mode) {
        return baseGet('/Verify/v2/GetPhoneVerifyCode', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.UserName
     */
    SMSGetVerifyCode(formInfo, mode) {
        return basePost('/Verify/SMSGetVerifyCode', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.verifyCode
     * @property {string} formInfo.userName
     */
    CheckVerifyCode(formInfo, mode) {
        return baseGet('/Verify/CheckVerifyCode', formInfo, mode).then(res => {
            return res;
        })
    },
}

// 驗證
const Auth = {
    
}

// 帳號
const Account = {
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.UserName
     * @property {string} formInfo.Mima	
     * @property {string} formInfo.KeyNumber - 推播Key
     * @property {string} formInfo.MobileType - 手機類型 (Android給A、IOS給I)
     * @property {string} formInfo.ClientId
     * @property {string} formInfo.ClientSecret
     */
    login(formInfo, mode) {
        return basePost('/Account/Login', formInfo, mode).then(res => {
            return res;
        })
    },
    // 登出
    logout(mode) {
        return basePost('/Account/Logout', {}, mode).then(res => {
            // 對方 api logout 沒有回傳 資料
            // res 為空
            return res;
        })
    },
    SignUp(formInfo, mode) {
        return basePost('/Account/SignUp', formInfo, mode).then(res => {
            return res;
        })
    },
    ForgetPasswordByMobile(formInfo, mode) {
        return basePost('/Account/SignUp', formInfo, mode).then(res => {
            return res;
        })
    },
    UserInfo(formInfo, mode) {
        return baseGet('/Account/UserInfo', {}, mode).then(res => {
            return res;
        })
    },
    HandicappedVerifyStatus(formInfo, mode) {
        return baseGet('/Account/HandicappedVerifyStatus', {}, mode).then(res => {
            return res;
        })
    },
    DeleteAccount(formInfo, mode) {
        return baseDel('/Account/Delete', {}, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.RealName
     * @property {string} formInfo.Birth	
     * @property {string} formInfo.LocalCalls
     * @property {string} formInfo.PhoneCalls
     * @property {string} formInfo.Address1	- 戶籍地址
     * @property {string} formInfo.Address2 - 聯絡地址
     * @property {string} formInfo.Email
     * @property {string} formInfo.Newsletter - 訂閱電子報
     * @property {string} formInfo.SMSletter - 接收SMS簡訊
     * @property {string} formInfo.Recommend - 推薦碼或司機編號
     * @property {string} formInfo.IdentityNumberHash
     * @property {string} formInfo.Gender - 性別(F:女性、M：男性、O：不公開)
     */
    ChangeUserData(formInfo, mode) {
        return basePost('/Account/ChangeUserData', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.NewPhoneNumber
     * @property {string} formInfo.ClientId
     */
    ChangePhoneNumber(formInfo, mode) {
        return basePost('/Account/ChangePhoneNumber', formInfo , mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.VerifyCode
     */
    ChangePhoneNumberRegistCode(formInfo, mode) {
        return basePost('/Account/ChangePhoneNumberRegistCode', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.OldPassword
     * @property {string} formInfo.NewPassword
     */
    ChangePassword(formInfo, mode) {
        return basePost('/Account/ChangePassword', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.NewPassword
     * @property {string} formInfo.ConfirmPassword
     */
    SetPassword(formInfo, mode) {
        return basePost('/Account/SetPassword', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.NewPassword
     * @property {string} formInfo.ConfirmPassword
     * @property {string} formInfo.UserName
     * @property {string} formInfo.VerifyCode
     */
    ChangePasswordWithoutIdentity(formInfo, mode) {
        return basePost('/Account/ChangePasswordWithoutIdentity', formInfo , mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.RefreshToken
     * @property {string} formInfo.ClientId
     * @property {string} formInfo.ClientSecret
     */
    RefreshToken(formInfo, mode) {
        let newFormInfo = $.extend(true, {
            ClientId: mukiConst.CLIENT_ID,
            ClientSecret: mukiConst.CLIENT_SECRET,
        }, formInfo)
        return basePost('/Account/RefreshToken', newFormInfo, mode).then(res => {
            return res;
        })
    },
}

// Order
const Order = {
    HasExecutingOrder(formInfo, mode) {
        return baseGet('/Order/HasExecutingOrder', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.OrderNo
     */
    CancelByOrder(formInfo, mode) {
        return basePost('/Order/CancelByOrder', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.OrderNo
     */
    OrderRecordDetail(formInfo, mode) {
        return basePost('/Order/OrderRecordDetail', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.driverId
     */
    DriverTotalScore(formInfo, mode) {
        return baseGet('/Order/DriverTotalScore', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.lastBookingTime - 最後一筆資料時間(可為null)
     * @property {string} formInfo.takeCount - 取得筆數(不填寫則預設20筆)
     */
    OrderRecordList(formInfo, mode) {
        return baseGet('/Order/v2.0/OrderRecordList', formInfo, mode).then(res => {
            return res;
        })
    },
}

// CallTaxi
const CallTaxi = {
    CallRecord(formInfo, mode) {
        return baseGet('/CallTaxi/Record', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.OrderNo
     * @property {number} formInfo.Evaluation
     * @property {string} formInfo.Remark
     */
    SetEvaluation(formInfo, mode) {
        return basePost('/CallTaxi/SetEvaluation', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.messageId 
     */
    CallRecordTrace(formInfo, mode) {
        return baseGet('/CallTaxi/CallRecordTrace', formInfo, mode).then(res => {
            return res;
        })
    },
}

// Config
const Config = {
    GetAddressBooks(formInfo, mode) {
        return baseGet('/Config/GetAddressBooks', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.listId
     */
    SetAddressBooks(formInfo, mode) {
        return basePost('/Config/SetAddressBooks', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.AddressType
     * @property {number} formInfo.Lat 
     * @property {number} formInfo.Lng 
     * @property {string} formInfo.AdditionMessage 
     */
    RemoveAddressBook(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            // 郁豪 : 都預設帶常用
            AddressType: mukiConst.addressType.COMMON,
        }, formInfo)
        return baseDel(`/Config/RemoveAddressBook?${new URLSearchParams(newFormInfo).toString()}`, {}, mode).then(res => {
            return res;
        })
    },
    Ad(formInfo, mode) {
        return baseGet('/Config/Ad', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.AddressType
     * @property {string} formInfo.Address
     * @property {number} formInfo.Lat
     * @property {number} formInfo.Lng
     * @property {string} formInfo.AdditionMessage
     */
    CreateOrUpdateAddressBooks(formInfo, mode) {
        return basePost('/Config/CreateOrUpdateAddressBooks', formInfo, mode).then(res => {
            return res;
        })
    },
}

// Notify
const Notify = {
    Unread(formInfo, mode) {
        return baseGet('/Notify/Unread', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.notifyGroupType
     * @property {string} formInfo.lastNotifyTime
     * @property {number} formInfo.limitSize
     */
    GetList(formInfo, mode) {
        return baseGet('/Notify/GetList', formInfo, mode).then(res => {
            if ( res ) {
                const { data } = res;
                const { Data } = data;
                if ( Data && Data.forEach ) {
                    Data.forEach((obj) => {
                        if ( obj.Data ) {
                            try {
                                obj.Data = JSON.parse(obj.Data)
                            }catch(err){
                                console.log('包含不合法的JSON')
                            }
                        }
                    })
                }
            }
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.notifyGroupType
     */
    Read(formInfo, mode) {
        return basePut(`/Notify/Read/${formInfo.notifyGroupType}`, {}, mode).then(res => {
            return res;
        })
    },
}

// Booking
const Booking = {
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.StartAddress
     * @property {string} formInfo.TargetAddress 
     * @property {string} formInfo.BookingTime 
     * @property {string} formInfo.BookingStartLat 
     * @property {string} formInfo.BookingStartLong 
     * @property {string} formInfo.BookingTargetLat 
     * @property {string} formInfo.BookingTargetLong 
     * @property {string} formInfo.Distance - 公尺
     * @property {string} formInfo.EstimatedTime - 秒
     * @property {string} formInfo.ClientId 
     */
    GetEstimateFare(formInfo, mode) {
        return basePost('/Booking/GetEstimateFare', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.StartAddress
     * @property {string} formInfo.TargetAddress 
     * @property {string} formInfo.BookingTime 
     */
    GetDailyServiceInfomation(formInfo, mode) {
        return basePost('/Booking/GetDailyServiceInfomation', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.StartAddress
     * @property {string} formInfo.TargetAddress 
     * @property {string} formInfo.BookingTime 
     * @property {number} formInfo.BookingStartLat 
     * @property {number} formInfo.BookingStartLong 
     * @property {number} formInfo.BookingTargetLat 
     * @property {number} formInfo.BookingTargetLong 
     * @property {number} formInfo.Distance 
     * @property {number} formInfo.EstimatedTime 
     * @property {number} formInfo.ClientId 
     */
    GetReserveTrips(formInfo, mode) {
        return basePost('/Booking/GetReserveTrips', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {Object} formInfo.GoBoundInputModel
     * @property {string} formInfo.GoBoundInputModel.BookingDate 
     * @property {string} formInfo.GoBoundInputModel.StartAddress 
     * @property {string} formInfo.GoBoundInputModel.BookingStartLat 
     * @property {string} formInfo.GoBoundInputModel.BookingStartLong 
     * @property {string} formInfo.GoBoundInputModel.TargetAddress 
     * @property {string} formInfo.GoBoundInputModel.BookingTargetLat 
     * @property {string} formInfo.GoBoundInputModel.BookingTargetLong 
     * @property {string} formInfo.GoBoundInputModel.AdditionMessage 
     * @property {string} formInfo.GoBoundInputModel.ShiftTaxiId 
     * @property {string} formInfo.GoBoundInputModel.CompanyId 
     * @property {string} formInfo.GoBoundInputModel.VehicleModelId 
     * @property {string} formInfo.GoBoundInputModel.Distance 
     * @property {string} formInfo.GoBoundInputModel.EstimatedTime 
     * @property {string} formInfo.GoBoundInputModel.PassengerCount 
     * @property {string} formInfo.GoBoundInputModel.BookingFrom 
     * @property {string} formInfo.GoBoundInputModel.LowestEstimatedFare 
     * @property {string} formInfo.GoBoundInputModel.HighestEstimatedFare 
     * @property {string} formInfo.GoBoundInputModel.ServiceType 
     * @property {string} formInfo.GoBoundInputModel.PayType 
     * @property {string} formInfo.GoBoundInputModel.CardNumber 
     * @property {string} formInfo.GoBoundInputModel.TargetAdditionMessage 
     * @property {string} formInfo.GoBoundInputModel.BookingDate 
     * @property {Object[]} formInfo.GoBoundInputModel.Condictions 
     * @property {number} formInfo.GoBoundInputModel.Condictions[].TypeId
     * @property {string} formInfo.GoBoundInputModel.Condictions[].TypeName
     * @property {string} formInfo.GoBoundInputModel.Condictions[].Note
     */
    CreateBookingQueue(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            GoBoundInputModel:{
                // 郁豪 : 舊的鎖趟ID (目前未使用可不需填入，或是給0)
                ShiftTaxiId: 0,
                // 郁豪 : 卡號，以前其他付款類型使用 (已無使用功能，給空字串即可)
                CardNumber: '',
            },
            // 郁豪 : App 目前沒有 BackBoundInputModel。放空null
            BackBoundInputModel: null
        }, formInfo)
        return basePost('/Booking/CreateBookingQueue', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {Object} formInfo.GoBoundInputModel
     * @property {string} formInfo.GoBoundInputModel.BookingDate 
     * @property {string} formInfo.GoBoundInputModel.StartAddress 
     * @property {number} formInfo.GoBoundInputModel.BookingStartLat
     * @property {number} formInfo.GoBoundInputModel.BookingStartLong 
     * @property {string} formInfo.GoBoundInputModel.TargetAddress 
     * @property {number} formInfo.GoBoundInputModel.BookingTargetLat
     * @property {number} formInfo.GoBoundInputModel.BookingTargetLong
     * @property {string} formInfo.GoBoundInputModel.AdditionMessage 
     * @property {number} formInfo.GoBoundInputModel.ShiftTaxiId 
     * @property {number} formInfo.GoBoundInputModel.CompanyId 
     * @property {number} formInfo.GoBoundInputModel.VehicleModelId 
     * @property {number} formInfo.GoBoundInputModel.Distance 
     * @property {number} formInfo.GoBoundInputModel.EstimatedTime 
     * @property {number} formInfo.GoBoundInputModel.PassengerCount 
     * @property {number} formInfo.GoBoundInputModel.BookingFrom 
     * @property {number} formInfo.GoBoundInputModel.LowestEstimatedFare 
     * @property {number} formInfo.GoBoundInputModel.HighestEstimatedFare 
     * @property {number} formInfo.GoBoundInputModel.ServiceType
     * @property {number} formInfo.GoBoundInputModel.PayType 
     * @property {string} formInfo.GoBoundInputModel.CardNumber 
     * @property {string} formInfo.GoBoundInputModel.TargetAdditionMessage 
     * @property {Object[]} formInfo.GoBoundInputModel.Condictions 
     * @property {number} formInfo.GoBoundInputModel.Condictions[].TypeId
     * @property {string} formInfo.GoBoundInputModel.Condictions[].TypeName
     * @property {string} formInfo.GoBoundInputModel.Condictions[].Note
     */
    MakeBooking(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            GoBoundInputModel:{
                // 郁豪 : 舊的鎖趟ID (目前未使用可不需填入，或是給0)
                ShiftTaxiId: 0,
                // 郁豪 : 卡號，以前其他付款類型使用 (已無使用功能，給空字串即可)
                CardNumber: '',
            },
            // 郁豪 : App 目前沒有 BackBoundInputModel。放空null
            BackBoundInputModel: null
        }, formInfo)
        return basePost('/Booking/v3/MakeBooking', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.ShiftTaxiId
     * @property {number} formInfo.CompanyId
     * @property {number} formInfo.VehicleModelId
     * @property {string} formInfo.ShiftTime
     */
    LockReserveTrip(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            // 郁豪 : 舊的鎖趟ID (目前未使用可不需填入，或是給0)
            ShiftTaxiId: 0,
        }, formInfo)
        return basePost('/Booking/LockReserveTrip', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.ShiftTaxiId
     * @property {number} formInfo.CompanyId
     * @property {number} formInfo.VehicleModelId
     * @property {string} formInfo.ShiftTime
     */
    UnLockReserveTrip(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            // 郁豪 : 舊的鎖趟ID (目前未使用可不需填入，或是給0)
            ShiftTaxiId: 0,
        }, formInfo)
        return basePost('/Booking/UnLockReserveTrip', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.BookingStartLat
     * @property {number} formInfo.BookingStartLong
     * @property {number} formInfo.BookingTargetLat
     * @property {number} formInfo.BookingTargetLong
     * @property {number} formInfo.ClientId
     */
    IntervalBookingCompanyList(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            ClientId: mukiConst.CLIENT_ID,
        }, formInfo)
        return basePost('/Booking/IntervalBookingCompanyList', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string[]} formInfo.BookingDates
     * @property {string} formInfo.BookingDates[0]
     * @property {string} formInfo.BookingDates[1]
     * @property {string} formInfo.StartAddress
     * @property {number} formInfo.BookingStartLat
     * @property {number} formInfo.BookingStartLong
     * @property {string} formInfo.TargetAddress
     * @property {number} formInfo.BookingTargetLat
     * @property {number} formInfo.BookingTargetLong
     * @property {string} formInfo.AdditionMessage
     * @property {number} formInfo.CompanyId
     * @property {number} formInfo.PassengerCount
     * @property {number} formInfo.BookingFrom
     * @property {number} formInfo.ServiceType
     * @property {number} formInfo.PayType
     * @property {number} formInfo.VehicleModelId
     * @property {string} formInfo.TargetAdditionMessage
     * @property {object[]} formInfo.Conditions
     * @property {object[]} formInfo.Conditions.TypeId
     * @property {object[]} formInfo.Conditions.TypeName
     * @property {object[]} formInfo.Conditions.Note
     * @property {boolean} formInfo.IsWillingToWait
     */
    CreateByInterval(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            ClientId: mukiConst.CLIENT_ID,
            // 2022/3/7 客戶 : 改為傳入 IntervalBookingCompanyList API 回傳的 ServiceType
            // 郁豪 : 客戶說此API固定帶0
            // ServiceType: 0 ,
        }, formInfo)
        return basePost('/Booking/CreateByInterval', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.StartAddress
     * @property {string} formInfo.TargetAddress 
     * @property {string} formInfo.BookingTime 
     * @property {number} formInfo.BookingStartLat 
     * @property {number} formInfo.BookingStartLong 
     * @property {number} formInfo.BookingTargetLat 
     * @property {number} formInfo.BookingTargetLong 
     * @property {number} formInfo.Distance 
     * @property {number} formInfo.EstimatedTime 
     * @property {number} formInfo.ClientId 
     */
    GetInstantBookingCompannyList(formInfo, mode) {
        return basePost('/Booking/GetInstantBookingCompannyList', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.BookingDate
     * @property {string} formInfo.StartAddress
     * @property {string} formInfo.BookingStartLat
     * @property {string} formInfo.BookingStartLong
     * @property {string} formInfo.TargetAddress
     * @property {string} formInfo.BookingTargetLat
     * @property {string} formInfo.BookingTargetLong
     * @property {string} formInfo.AdditionMessage
     * @property {string} formInfo.ShiftTaxiId
     * @property {string} formInfo.CompanyId
     * @property {string} formInfo.VehicleModelId
     * @property {string} formInfo.Distance
     * @property {string} formInfo.EstimatedTime
     * @property {string} formInfo.PassengerCount
     * @property {string} formInfo.BookingFrom
     * @property {string} formInfo.LowestEstimatedFare
     * @property {string} formInfo.HighestEstimatedFare
     * @property {string} formInfo.ServiceType
     * @property {string} formInfo.PayType
     * @property {string} formInfo.CardNumber
     * @property {string} formInfo.TargetAdditionMessage
     * @property {Object[]} formInfo.Condictions
     * @property {number} formInfo.Condictions.TypeId
     * @property {string} formInfo.Condictions.TypeName
     * @property {string} formInfo.Condictions.Note
     * @property {string} formInfo.CouponId
     */
    CreateInstantBookingQueue(formInfo, mode) {
        var newFormInfo = $.extend(true, {
            // 郁豪 : 舊的鎖趟ID (目前未使用可不需填入，或是給0)
            ShiftTaxiId: 0,
            // 郁豪 : 卡號，以前其他付款類型使用 (已無使用功能，給空字串即可)
            CardNumber: '',
             // 郁豪 : 客戶說此API固定帶0
            ServiceType: 0
        }, formInfo)
        return basePost('/Booking/CreateInstantBookingQueue', newFormInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.BookingId
     */
    CancelBooking(formInfo, mode) {
        return basePost('/Booking/CancelBooking', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.lastBookingTime - 最後一筆資料時間(可為null)
     * @property {number} formInfo.takeCount - 取得筆數(不填寫則預設20筆)
     */
    BookingList(formInfo, mode) {
        return baseGet('/Booking/v2.0/BookingList', formInfo, mode).then(res => {
            return res;
        })
    },
}

// Coupon
const Coupon = {
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.BookingDate - 預約時間（放趟列表時間）
     * @property {number} formInfo.BookingStartLat 
     * @property {number} formInfo.BookingStartLong 
     * @property {number} formInfo.BookingTargetLat 
     * @property {number} formInfo.BookingTargetLong 
     * @property {number} formInfo.CompanyId 
     * @property {number} formInfo.VehicleModelId 
     * @property {number} formInfo.Distance 
     * @property {number} formInfo.EstimatedTime 
     * @property {number} formInfo.PassengerCount 
     * @property {number} formInfo.BookingFrom 
     * @property {number} formInfo.LowestEstimatedFare 
     * @property {number} formInfo.HighestEstimatedFare 
     * @property {number} formInfo.ServiceType 
     * @property {number} formInfo.PayType 
     * @property {Objct} formInfo.PayType 
     * @property {number} formInfo.PayType.TypeId 
     * @property {string} formInfo.PayType.TypeName 
     * @property {string} formInfo.PayType.Note 
     * @property {Object[]} formInfo.PayType.Condictions
     * @property {number} formInfo.PayType.Condictions[].TypeId
     * @property {string} formInfo.PayType.Condictions[].TypeName
     * @property {string} formInfo.PayType.Condictions[].Note
     */
    ListByCondition(formInfo, mode) {
        return basePost('/Coupon/ListByCondition', formInfo, mode).then(res => {
            return res;
        })
    },
    CouponList(formInfo, mode) {
        return baseGet('/Coupon/CouponList', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.activeCode
     */
    RecieveCoupon(formInfo, mode) {
        return basePost('/Coupon/RecieveCoupon', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.couponId
     */
    CouponInfo(formInfo, mode) {
        return baseGet('/Coupon/CouponInfo', formInfo, mode).then(res => {
            return res;
        })
    },
}

// CharteredCar
const CharteredCar = {
    ServiceAreaList(formInfo, mode) {
        return baseGet('/CharteredCar/ServiceAreaList', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.areaId 
     */
    ListByServiceArea(formInfo, mode) {
        return baseGet('/CharteredCar/ListByServiceArea', formInfo, mode).then(res => {
            return res;
        })
    },
}

// Contact
const Contact = {
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.orderId
     */
    Unread(formInfo, mode) {
        return basePost('/Contact/Unread', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.driverId 
     */
    DriverInfo(formInfo, mode) {
        return baseGet('/Contact/DriverInfo', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.orderId 
     * @property {string} formInfo.dateTime - 該時間點以前訊息
     */
    GetContactRecord(formInfo, mode) {
        return basePost('/Contact/GetContactRecord?' + new URLSearchParams(formInfo).toString(), {}, mode).then(res => {
            return res;
        })
    },
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.readtime - 讀取時間
     */
    ReadMessage(formInfo, mode) {
        return basePost('/Contact/ReadMessege?' + new URLSearchParams(formInfo).toString(), {}, mode).then(res => {
            return res;
        })
    },
}

// UserPoint
const UserPoint = {
    /**
     * @param {Object} formInfo
     * @property {string} formInfo.PageSize
     * @property {string} formInfo.CurrentPageNumber
     */
    UserPointRecord(formInfo, mode) {
        return baseGet('/UserPoint/UserPointRecord', formInfo, mode).then(res => {
            return res;
        })
    },
    EffectiveTotalPoints(formInfo, mode) {
        return baseGet('/UserPoint/EffectiveTotalPoints', formInfo, mode).then(res => {
            return res;
        })
    },
    GetResetDate(formInfo, mode) {
        return baseGet('/UserPoint/GetResetDate', formInfo, mode).then(res => {
            return res;
        })
    },
}

// 錯誤訊息不處理清單
const ErrorMsgDisableHandleArr = [
    '/Account/Login'
]

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })
    failedQueue = [];
}
apiBase.interceptors.response.use(function (config) {
    return config;
}, function (error) {
    // Do something with request error

    if ( ErrorMsgDisableHandleArr.indexOf(error.config.url) === -1 ) {
        if (error.response) {
            // server responded status code falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
    
                    if (error.config.url == '/Account/RefreshToken') {
                        // refreshToke已過期
                        // 不在這裡 alert
                        // 直接由 Account.RefreshToken 的 catch 去處理訊息顯示
                    } else {
                        if ( error.response.data && error.response.data.Message ) {
                            f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                            });
                        } else {
                            f7.dialog.alert(`${error.response.status}: 資料來源不存在`, "訊息提示", () => {
                            });
                        }
                    }
    
                    break;
                case 401:
                    // 發生未授權請求
                    // 當不是 refresh token 作業發生 401 才需要更新 access token 並重發
                    // 如果是就略過此刷新 access token 作業，直接不處理(因為 catch 已經攔截處理更新失敗的情況了)
                    if (error.config.url !== '/Account/RefreshToken') {
                        // 原始 request 資訊
                        const originalRequest = error.config
                        const refreshToken = localStorage.getItem('RefreshToken');
    
                        if (isRefreshing) {
                            // 多個同時進行中請求在新token尚未更新時的處理
                            return new Promise(function(resolve, reject) {
                                failedQueue.push({resolve, reject})
                            }).then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return axios(originalRequest);
                            }).catch(err => {
                                return Promise.reject(err);
                            })
                        }
                        
                        if ( !refreshToken ) {
                            console.log('未取得 RefreshToken ')
                            return
                        }
    
                        originalRequest._retry = true;
                        isRefreshing = true;
                        
                        // 依據 refresh_token 刷新 access_token 並重發 request
                        return Account.RefreshToken({
                            RefreshToken: refreshToken
                        }).then(res=>{
                            // [更新 access_token 成功]
                            if ( res ) {
                                const { data, status } = res;
                                if ( status === 200 && data.Data ) {
                                    let token = data.Data.Token
                                    store.commit('auth/updateMemberData', data.Data )
                                    
                                    // 刷新原始 request 的 access_token
                                    originalRequest.headers.Authorization = 'Bearer ' + token;
    
                                    // 指有在成功更新 token 後，才能isRefreshing = false;
                                    isRefreshing = false;
    
                                    // 重新執行其他失敗的請求
                                    processQueue(null, token);
    
                                    // 重送 request (with new access_token)
                                    return axios(originalRequest)
                                }
                            }
                        }).catch((err) => {
                            processQueue(err, null);
                            f7.dialog.alert(`憑證已過期，請重新登入`, "訊息提示", () => {
                                store.dispatch('auth/logoutForce')
                            });
                            // return Promise.reject(error)
                        }).finally(() => {  });
                    } 
    
                break
    
                case 404:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: 資料來源不存在`, "訊息提示", () => {
                        });
                    }
                break
    
                case 500:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.status}: 內部系統發生錯誤`, "訊息提示", () => {
                        });
                    }
                break
    
                default:
                    if ( error.response.data && error.response.data.Message ) {
                        f7.dialog.alert(error.response.data.Message, "訊息提示", () => {
                        });
                    } else {
                        f7.dialog.alert(`${error.response.data.Message}`, "訊息提示", () => {
                        });
                    }
                break
            }
    
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.code === 'ECONNABORTED' && error.message && error.message.indexOf('timeout') !== -1) {
                // request time out will be here
                f7.dialog.alert('網路連線逾時，請點「確認」鍵後繼續使用。', "訊息提示", () => {
                });
            } else {
                // shutdonw api server
                f7.dialog.alert('網路連線不穩定，請稍候再試', "訊息提示", () => {
                });
            }
        }
    } 

    return Promise.reject(error);
});


export {
    baseGet,
    basePost,
    basePut,
    basePatch,
    baseDel,

    // 驗證
    Verify,

    // 驗證
    Auth,

    // 帳號
    Account,

    // Order
    Order,

    // CallTaxi
    CallTaxi,

    // Config
    Config,

    // Notify
    Notify,

    // Booking
    Booking,

    // CharteredCar
    CharteredCar,

    // Contact
    Contact,

    // UserPoint
    UserPoint,

    // 最愛
    addToCollection,
    removeFromCollection,

    // 行動超市
    mobileStoreAddToCollection,
    mobileStoreRemoveFromCollection,

    // 購物車
    Cart,
    addToCart,
    alterCart,
    removeFromCart,

    // 信件
    Mail,

    // 產品
    Product,

    // 生活提案
    Proposal,

    // 優惠券
    Coupon,

    // 門市
    Store,

    // 最新消息
    News,

    // 會員
    Member,
};
