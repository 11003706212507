<template>
  <div class="md-checkbox" :class="[checkClasses]">
    <div class="md-checkbox-container" @click.stop="toggleCheck">
      <md-ripple md-centered  :md-disabled="disabled">
        <input :id="id" type="checkbox" v-bind="attrs" :indeterminate.prop="indeterminate">
      </md-ripple>
    </div>

    <label :for="id" class="md-checkbox-label" v-if="$slots.default" @click.prevent="toggleCheck">
      <slot />
    </label>
  </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
import { Checkbox } from '@functions';
export default {
  components: {
  },
  props: {
    model: [String, Boolean, Object, Number, Array],
    value: {
      type: [String, Boolean, Object, Number],
    },
    name: [String, Number],
    required: Boolean,
    disabled: Boolean,
    indeterminate: Boolean,
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  data: () => ({
    rippleActive: false
  }),
  computed: {
    attrs () {
      const attrs = {
        id: this.id,
        name: this.name,
        disabled: this.disabled,
        required: this.required,
        'true-value': this.trueValue,
        'false-value': this.falseValue
      }

      if (this.$options.propsData.hasOwnProperty('value')) {
        if (this.value === null || typeof this.value !== 'object') {
          attrs.value = (this.value === null || this.value === undefined) ? '' : String(this.value)
        }
      }

      return attrs
    },
    isSelected () {
      if (this.isModelArray) {
        return this.model.includes(this.value)
      }

      if (this.hasValue) {
        return this.model === this.value
      }

      return this.model === this.trueValue
    },
    isModelArray () {
      return Array.isArray(this.model)
    },
    checkClasses () {
      return {
        'md-checked': this.isSelected,
        'md-disabled': this.disabled,
        'md-required': this.required,
        'md-indeterminate': this.indeterminate
      }
    },
    hasValue () {
      return this.$options.propsData.hasOwnProperty('value')
    }
  },
  methods: {
    removeItemFromModel (newModel) {
      const index = newModel.indexOf(this.value)

      if (index !== -1) {
        newModel.splice(index, 1)
      }
    },
    handleArrayCheckbox () {
      const newModel = this.model

      if (!this.isSelected) {
        newModel.push(this.value)
      } else {
        this.removeItemFromModel(newModel)
      }

      this.$emit('change', newModel)
    },
    handleSingleSelectCheckbox () {
      this.$emit('change', this.isSelected ? null : this.value)
    },
    handleSimpleCheckbox () {
      this.$emit('change', this.isSelected ? this.falseValue : this.trueValue)
    },
    toggleCheck () {
      if (!this.disabled) {
        this.rippleActive = true

        if (this.isModelArray) {
          this.handleArrayCheckbox()
        } else if (this.hasValue) {
          this.handleSingleSelectCheckbox()
        } else {
          this.handleSimpleCheckbox()
        }
      }
    }
  }
}
</script>

<style lang="scss">
  $md-checkbox-size: 20px;
  $md-checkbox-touch-size: 48px;
  .md-checkbox {
    width: auto;
    margin: 16px 16px 16px 0;
    display: inline-flex;
    position: relative;
    &:not(.md-disabled) {
      cursor: pointer;
      .md-checkbox-label {
        cursor: pointer;
      }
    }
    .md-checkbox-container {
      width: $md-checkbox-size;
      min-width: $md-checkbox-size;
      height: $md-checkbox-size;
      position: relative;
      border-radius: 2px;
      border: 2px solid transparent;
      &:focus {
        outline: none;
      }
      &:before,
      &:after {
        position: absolute;
        content: " ";
      }
      &:before {
        width: $md-checkbox-touch-size;
        height: $md-checkbox-touch-size;
        top: 50%;
        left: 50%;
        z-index: 11;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      &:after {
        width: 6px;
        height: 13px;
        top: 0;
        left: 5px;
        z-index: 12;
        border: 2px solid transparent;
        border-top: 0;
        border-left: 0;
        opacity: 0;
        transform: rotate(45deg) scale3D(.15, .15, 1);
      }
      .md-ripple {
        width: $md-checkbox-touch-size !important;
        height: $md-checkbox-touch-size !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      input {
        position: absolute;
        left: -999em;
      }
    }
    .md-checkbox-label {
      height: $md-checkbox-size;
      padding-left: 16px;
      position: relative;
      line-height: $md-checkbox-size;
    }
  }
  .md-checkbox.md-indeterminate {
    .md-checkbox-container {
      &:after {
        width: 12px;
        height: 2px;
        top: 50%;
        left: 50%;
        z-index: 7;
        border-style: solid;
        border-width: 0 0 2px;
        opacity: 0;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
  .md-checkbox.md-checked {
    .md-checkbox-container {
      &:after {
        opacity: 1;
        transform: rotate(45deg) scale3D(1, 1, 1);
      }
    }
  }
  .md-checkbox.md-disabled.md-checked {
    .md-checkbox-container {
      border-color: transparent !important;
    }
  }
  .md-checkbox.md-required {
    label:after {
      position: absolute;
      top: 2px;
      right: 0;
      transform: translateX(calc(100% + 2px));
      content: "*";
      line-height: 1em;
      vertical-align: top;
    }
  }
</style>
