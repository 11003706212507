<template>
    <label
        :class="[
            rootClassName,
            inline ? 'custom-control-inline' : null,
            size ? `size-${size}px` : '',
            isLight ? 'sty-light' : '',
            isInactiveGray ? 'sty-inactive-gray' : '',
            isRadioCheckIcon ? 'sty-radio-check-icon' : '',
            isSeeThrough ? 'sty-see-through' : '',
        ]"
        v-show="isShow"
    >
        <input
            ref="refInput"
            :value="value"
            @click="$emit('inputClick')"
            @change="$emit('update:modelValue', computModelValForUpdate($event));"
            :checked="isChecked"
            :type="type"
            :rules="rules"
            :name="name"
        />
        <span :class="[iconWrapClassName]">
            <f7-icon
                :icon="icon"
                :f7="(type == 'checkbox' || isRadioCheckIcon) ? 'checkmark_alt' : null"
                :material="material"
                :ios="ios"
                :md="md"
                :aurora="aurora"
            ></f7-icon>
        </span>
        <span :class="textClassName">
            <slot></slot>
        </span>
    </label>
</template>

<script>
import { ComponentTool } from '@functions'
import { useField } from "vee-validate";
import { computed, ref, toRef, onMounted } from "vue";

export default {
    components: {
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [String, Number, Array],
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        sty: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        size:{
            default: 20,
        },
        name: {
            type: String,
            default: '',
        },
        rules: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        // 允許Radio 取消選取
        radioAllowCancel: Boolean,
        inline: Boolean,
        f7: {
            type: String,
            default: '',
        },
        material: {
            type: String,
            default: '',
        },
        ios: {
            type: String,
            default: '',
        },
        md: {
            type: String,
            default: '',
        },
        aurora: {
            type: String,
            default: '',
        },
        isShow: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, {emit}) {
        const { type, modelValue, value, name, radioAllowCancel } = props;
        const rootClassName = computed(() => `base-${type}`);
        const iconWrapClassName = computed(() => `icon-${type}-wrap`);
        const textClassName = computed(() => `${type}-text`);
        const thisModelValue = computed(()=> String(props.modelValue))

        const refInput = ref(null);

        let isChecked = computed(()=>{

            if (thisModelValue.value == undefined || thisModelValue.value == null) return

            if( props.type == 'radio') {
                return String(thisModelValue.value) == String(value)
            } else {
                return thisModelValue.value.indexOf(value) > -1 ||
                thisModelValue.value.indexOf(String(value)) > -1
            }
        });

        // 上一次是否為勾選
        // const prevChecked = isChecked.value;

        // 決定要返回給 update:modelValue的值
        let computModelValForUpdate = (e) =>{
            var isChecked = e.target.checked;
            var thisVal = e.target.value

            if( props.type == 'radio') {

                return thisVal

            } else {
                // checkebox
                if (isChecked) {

                    if ( thisModelValue.value.indexOf(thisVal) > -1) {
                        return thisModelValue.value
                    } else {
                        return [...thisModelValue.value, thisVal]
                    }

                } else {
                    var newArr = [...thisModelValue.value]
                    var idx = newArr.indexOf(thisVal);

                    if ( idx > -1) {
                        newArr.splice(idx, 1)
                        return newArr
                    } else {
                        return newArr;
                    }
                }
            }
        }

        const radioAllowCancelCheck = (event) =>{
            // if ( refInput.value.checked ) {
            //     if( props.type == 'radio') {
            //         if ( isChecked = refInput.value.checked ) {
            //             emit('update:modelValue', '')
            //         }
            //     }
            // }
        }

        onMounted(()=>{
        })

        // validate
        const {
            errorMessage: thisErrMsg,
            name: thisName,
            value: thisValue,
            meta,
            errors,
            resetField,
            validate,
            handleChange,
            handleBlur,
            setValidationState,
            checked: thisChecked,
        } = useField(name);

        const computeStyArr = ComponentTool.computeStyArr(toRef(props, 'sty'))
        const isLight = ComponentTool.isSty(computeStyArr, 'light')
        const isInactiveGray = ComponentTool.isSty(computeStyArr, 'inactive-gray')
        const isRadioCheckIcon = ComponentTool.isSty(computeStyArr, 'radio-check-icon')
        const isSeeThrough = ComponentTool.isSty(computeStyArr, 'see-through')


        return {
            refInput,

            rootClassName,
            iconWrapClassName,
            textClassName ,

            isChecked,

            thisErrMsg,

            radioAllowCancelCheck,
            computModelValForUpdate,

            isLight,
            isInactiveGray,
            isRadioCheckIcon,
            isSeeThrough
        };
    },
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";

.base-radio,
.base-checkbox {
    --custom-control-active-color: #{$color-primary};
    --custom-control-inactive-color: #EEF5CC;

    display: flex;
    align-items: center;

    &.sty-light{
        --custom-control-active-color: #{$color-sec};
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 18px;
            height: 18px;
            border-width: 1px;
            border-color: $color-sec;
        }
    }

    &.sty-inactive-gray{
        --custom-control-inactive-color: #C5C5C5;
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 18px;
            height: 18px;
            border-width: 1px;
        }
    }

    &.sty-radio-check-icon{
        .icon-radio-wrap {
            i {
                width: auto;
                height: auto;
                background: none;
            }
        }
    }

    &.sty-see-through{
        .icon-checkbox-wrap{
            background: none;
        }
    }

    &.size-24px{
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 20px;
            height: 20px;
        }
    }

    &.custom-control-inline{
        display: inline-flex;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    input {
        display: none;

        &:checked {
            ~ .icon-radio-wrap, ~ .icon-checkbox-wrap {
                border-color: var(--custom-control-active-color);
                background-color: var(--custom-control-active-color);
                .icon {
                    opacity: 1;
                    color:#fff;
                }

                ~ .radio-text {
                    color: $color-title;
                }
            }

            ~ .checkbox-text{
                color:var(--custom-control-active-color);
            }
        }
    }

    .icon-radio-wrap,
    .icon-checkbox-wrap {
        display: inline-block;
        flex-shrink: 0;
        @include size(16px);
        margin-right: 10px;
        border: 2px solid var(--custom-control-inactive-color);
        border-radius: 50px;
        position: relative;
        transition: all 0.3s ease;
        background: #FFFFFF;
    }

    .icon-radio-wrap {
        border-radius: 50%;
        i {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }

    .icon-checkbox-wrap {
        border-radius: 5px;
    }

    .icon {
        @include pos-center;
        font-size: 15px;
        font-weight: bold;
        opacity: 0;
        transition: all 0.3s ease;
    }

    .radio-text,
    .checkbox-text {
        color: $color-font;
    }
}
</style>
