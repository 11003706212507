<template>
    <div 
        class="base-list"
        :style="{
            '--base-list-v-gap': vgap ? vgap : null
        }"
    >
        <slot />
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{

    },
    props: {
        vgap: ''
    },
    setup(props, {emit}){

        return {
            
        }
    }
};
</script>

<style lang="scss">
@import "@design";

.base-list{
    --base-list-v-gap: 15px;
    .list-item{
        margin-bottom: 15px;
    }
}

</style>


