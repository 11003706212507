import { Order } from "@baseService";
import { computed, reactive } from "vue";

export default {
    namespaced: true,
    state: () => ({
        // 司機
        DriverInfo: '',
        DriverTotalScore: {},

        // ==== mqtt ====
        // 前一次的 mqtt
        mqttArr: [],
        mqttPrev: {
        },
        mqtt: {
            "gLat": '',
            "gLng": '',
            "gAngle": '',
            "CStatus": '',
        },
        // ==== mqtt ====

        // ==== 執行中訂單 ====
        HasExecutingOrder: {},
        // ==== 執行中訂單/ ====

        // 定當詳細 (在此為當前的訂單)
        OrderRecordDetail: {},

        // 訂單(Order)進度
        flowStatus: '',

        // 當前訂單相關的推播的notifyType
        notifyType: ''
    }),
    getters: {
        getData(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }

            return result
        },
    },
    actions: {
        init({commit}, data) {
            commit('clearData');
        },
    },
    mutations: {
        setData(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        setLocation(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        clearData(state, data){
            for ( let key in state){
                state[key] = ''
            }
        },
    },
}

