import { computed, reactive } from "vue";

export default {
    namespaced: true,
    state: () => ({
    }),
    getters: {
        getData(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }

            return result
        },
    },
    actions: {
        init({commit, dispatch}, data) {
        },
    },
    mutations: {
        setData(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        clearData(state, data){
            for ( let key in state){
                state[key] = ''
            }
        },
    },
}

