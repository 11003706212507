<template>
  <label 
    class="u-checkbox"
    :class="{
      'u-checkbox_inline': inline,
      'u-checkbox_checked': checked,
    }"
  >
    <div class="u-checkbox__hidden">
      <slot />
    </div>
    <div class="u-checkbox__icon">
      <i class="icon-yes"></i>
    </div>
    <div class="u-checkbox__text">
      <slot name="label">
        {{ text }}
      </slot>
    </div>
  </label>
</template>

<script>
/**
 * 純UI checkbox
 */

import { ref, reactive, computed, watch, watchEffect } from 'vue';

export default {
  props:{
    inline: Boolean,
    checked: Boolean,
    text: String,
  },
  setup(props, context) {
    
    const data = {
    }

    return {
      data
    };
  },
};
</script>

<style lang="scss">
@import "@design";

.u-checkbox{
  --u-checkbox-text-padding-left: 10px;
  display: flex;
  align-items: center;

  &[readonly]{
    cursor: default;
  }

  &:hover:not(.u-checkbox_checked):not([readonly]){
    .u-checkbox__icon{
      background: #A4A4A4;
    }
  }

  &__hidden{
    display: none;
  }

  &__icon{
    width: 20px;
    height: 20px;
    @include flex(center, center);
    background: #A4A4A4;
    border-radius: 50%;
    transition: .3s;
    i {
      color:#fff;
      font-size: 14px;
      opacity: 1;
    }
  }

  &__text{
    padding-left: var(--u-checkbox-text-padding-left);
  }

  &_inline{
    display: inline-flex;
    margin-right: 20px;
    margin-bottom: 15px;
  }

  &_checked{
    .u-checkbox__icon{
      background:#fd8908;
      color: #fff;
      i {
        opacity: 1;
      }
    }
  }
}

</style>
