<template>
    <div 
        class="nav-status-badge"
        :class="{
            'sty-ongoing': ongoing ? true : null,
            'sty-complete': complete ? true : null,
        }"
    >
        <div class="badge">
            <slot />
            <template v-if="ongoing" >
                執行中
            </template>
            <template v-if="complete" >
                執行結束
            </template>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    props:{
        // 執行中
        ongoing: Boolean,
        // 執行結束
        complete: Boolean,
    },
    setup(props, context){

        // const cData = reactive({
        //     HasExecutingOrde: ''
        // })

        // Order.HasExecutingOrder().then((res)=>{
        //     if ( res ){
        //         const { data, status } = res;
        //         if ( data && status === 200 ) {
        //             cData.HasExecutingOrde = data
        //         }
        //     }
        // });

        return {
            // cData
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.nav-status-badge{
    
    &.sty-ongoing{
        .badge{
            background: $color-primary;
        }
    }

    &.sty-complete{
        .badge{
            background: $color-yellow;
        }
    }

    .badge{
        border-radius: 22px;
        font-size: 1rem;
        letter-spacing: calc( 2.8 / 14 * 1em);
        padding: 3px;
        min-width: 90px;
        font-weight: 500;
        height: auto;
        line-height: 1.43;
        box-shadow: $shadow-light;
        border: 1px solid #FFFFFF;
    }
}

</style>
