<template>
    <f7-button
        class="btn-sec"
        :class="classes"

        type="button"
        :disabled="captchaBtnDisable"
        @click="getCaptcha"
    >
        <span v-if="counterNum == 0">
            {{btnText}}
        </span>
        <span v-else>重發 ({{counterNum}})</span>
    </f7-button>
</template>

<script>
import { ref } from "vue";

import { basePost } from "@/js/services/baseService";
import { Counter } from '@functions'

export default {
    props:{
        num: {
            type: Number,
            default: 0
        },
        classes:{
            type: String,
            default: null
        },
        mobile:{
            type: String,
            default: null
        },
        veeValidator:{
            type: Function,
            default: null
        },
        btnText:{
            type: String,
            default: '取得驗證碼'
        },
    },
    setup(props, {emit}){

        const veeValidator = props.veeValidator;
        const captchaBtnDisable = ref(false);

        // counter
        const counterNum = ref(0);

        const getCaptcha = async() => {

            captchaBtnDisable.value = true;

            if ( 'function' !== typeof veeValidator ) return;

            let inputValid = await veeValidator().then(res => {
                return res.valid
            })

            if ( inputValid ) {

                const data = {
                    mobile: props.mobile
                };

                basePost('/api_common/get_vcc', data, 'dev').then(function(res){
                    const { data, status } = res;
                    if (status === 200 && data["res_code"] === 1) {

                        f7.dialog.alert(data["res_content"], "訊息提示", () => {

                            Counter({
                                seconds: 60,
                                onUpdateStatus(seconds){
                                    counterNum.value = seconds
                                },
                                onCounterEnd(){
                                    captchaBtnDisable.value = false;
                                }
                            }).start()

                        });

                        emit('sent');

                    } else {
                        f7.dialog.alert(data["res_content"], "訊息提示");
                        captchaBtnDisable.value = false;

                        emit('fail');
                    }
                }).catch(function(err){
                    emit('fail');
                })

            } else {
                captchaBtnDisable.value = false;
            }
        }

        return {
            captchaBtnDisable,
            counterNum,

            getCaptcha
        }
    }
};
</script>

<style lang="scss">
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

</style>
