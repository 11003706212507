
import axios from "axios";

function checkAppVersion(){
    return new Promise((resolve, reject)=>{

        axios.get('https://geturl.muki001.com/itaxi-version')
        .then(function (response) {
            // handle success
            const { data } = response;
            resolve(data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            resolve(null)
        })

    })
}

export {
    checkAppVersion
}