<template>
    <div 
        class="route-info"
        :class="{
            'sty-horizontal': horizontal,
            'sty-horizontal2': horizontal2,
            'sty-bold': bold,
            'sty-title-spacing': titleSpacing,
            'sty-small-side-txt': smallSideTxt,
            'sty-dark-postfix': darkPostfix,
            'sty-dark-prefix': darkPrefix,
            'sty-dark-subdes': darkSubdes,
            'sty-md-subdes': mdSubdes,
            'sty-small-fz': smallFz
        }"
        :style="{
            '--font-size': fz ? fz : null,
            '--route-info-postfix-color': postfixColor ? postfixColor : null,
        }"
    >
        <slot>
            <div class="info-title" v-if="title">
                <div class="info-title-txt">
                    {{title}}
                </div>
            </div>
            <div class="info-content" v-if="preFix || content || content === 0">
                <span v-if="preFix" class="info-prefix">{{preFix}}</span>
                <div class="content-txt">
                    {{content}}
                </div>
                <span v-if="postFix" class="info-postfix">{{postFix}}</span>
            </div>
            <div 
                class="info-sub-des" 
                :class="{
                    'pull-item-left': false && String(subDes).indexOf('（') == 0
                }"
                v-if="subDes"
            >
                {{subDes}}
            </div>
        </slot>
    </div>
</template>

<script>
import { computed, ref , onMounted, reactive } from "vue";
export default {
    components:{
    },
    props: {
        title: [String],
        content: [String, Number],
        subDes: [String, Number],

        // fontsize
        fz: [String],

        // preFix
        preFix: [String, Number],
        // postFix
        postFix: [String, Number],

        // font weight
        bold: Boolean,

        // 水平 layout  
        horizontal: Boolean,
        // 水平樣式2
        horizontal2: Boolean,

        // 客製
        titleSpacing: Boolean,
        darkPostfix: Boolean,
        darkPrefix: Boolean,
        darkSubdes: Boolean,
        mdSubdes: Boolean,
        smallFz: Boolean,
        smallSideTxt: Boolean,
        postfixColor: String,
        // 客製/
    },
    setup(props, {emit}){
        
        return {

        }
    }
};
</script>

<style lang="scss" >
@import "@design";

.route-info-grid{
    .bs-row{
        align-items: flex-start;
    }
}

.route-info{
    --font-size: calc(32 / 14 * 1rem);
    --route-info-postfix-color: #ADADAD;

    display: inline-block;
    font-weight: 500;
    line-height: 1.2;
    font-size: var(--font-size);

    &.sty-small-fz{
        --font-size: calc(20 / 14 * 1rem);
    }

    &.sty-bold{
        .info-content{
            font-weight: bold;
        }
    }

    &.sty-small-side-txt{
        .info-title{
            .info-title-txt{
                font-size: calc(12 / 14 * 1rem);
            }
        }
        .info-postfix, .info-prefix{
            font-size: calc(12 / 14 * 1rem);
        }
    }

    &.sty-horizontal{
        display: flex;
        font-size: calc( 28 / 14 * 1rem);
        .info-title{
            margin-top: 0.4em;
            margin-bottom: 0;
        }
        .info-content{
            font-weight: 500;
        }
    }

    &.sty-horizontal2{
        display: flex;
        font-size: calc( 16 / 14 * 1rem);
        .info-title{
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0.4em;
        }
        .info-content{
            font-weight: 500;
            .content-txt{
                font-family: inherit;
            }
        }
    }

    &.sty-title-spacing{
        .info-title{
            letter-spacing: 0.2em;
        }
    }

    &.sty-dark-prefix{
        .info-prefix{
            color: #000000;
        }
    }


    &.sty-dark-postfix{
        .info-postfix{
            color: #000000;
        }
    }

    &.sty-dark-subdes{
        .info-sub-des{
            color: #000000;
        }
    }

    &.sty-md-subdes{
        .info-sub-des{
            font-size: 1rem;
        }
    }

    .info-title{
        color: #333;
        margin-bottom: calc(7 / 14 * 1rem);
        font-weight: normal;
        .info-title-txt{
            font-size: calc(18 / 14 * 1rem);
        }
    }

    .info-content{
        color: var(--color-theme);
        font-size: 1em;
        display: flex;
        align-items: baseline;
        .content-txt{
            line-height: 1;
            font-family: $ff-roboto;
            margin-right: 6px;
        }
    }
    .info-prefix{
        color: #ADADAD;
        font-size: 1rem;
        line-height: 1;
        font-weight: normal;
        margin-right: 6px;
        white-space: nowrap;
    }
    .info-postfix{
        color: var(--route-info-postfix-color);
        font-size: 1rem;
        line-height: 1;
        font-weight: normal;
        white-space: nowrap;
    }

    .info-sub-des{
        color: #ADADAD;
        font-size: 1rem;
        letter-spacing: 0.05em;
        font-weight: normal;
        letter-spacing: 0.05em;
        text-indent: 0.05em;
        margin-top: 6px;
        &.pull-item-left{
            margin-left: -0.6em;
        }
    }

    // 其他
    .row{
        width: 100%;
    }
    // 其他 /
}

</style>



