export default{
    dialog: {
        buttonOk: 'OK',
        buttonCancel: 'Cancel',
    },
    photoBrowser:{
        bannerPhotoBrowser:'Close'
    },
    newFabrics: "NEW FABRICS",
    news: 'NEWS',
    chatRoom: 'Chat Room',
    archive: 'Archive',
    labdipStatus: 'Labdip Status',
    fabricname: 'Fabric name',
    'fabric#': 'Fabric #',
    color: 'Color',
    receivedDate: 'Received date',
    leadTime: 'Lead time',
    confirmDate: 'Confirm date',
    programSchedule: 'PROGRAM SCHEDULLE',
    rdStatus: 'R&D STATUS',
    localeSwitch: 'Language switch',
    changePwd: 'Change Password',
    logout: 'Logout',
    signUp: 'SIGN UP',
    notification: 'NOTIFICATION',
    orderStatus: "Order Status",
}